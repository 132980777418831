const typePrefix = "TEXT_";

const Types = {
   testType: typePrefix + "TEST_TYPE",
};

export const actions = {
   testAction: () => (dispatch) => {
      dispatch({ type: Types.testType, payload: "My test text" });
   },
};

const initialState = {};

export const reducer = (state: any = initialState, action: any) => {
   switch (action.type) {
      case Types.testType:
         return { ...state, text: action.payload };

      default:
         return state;
   }
};
