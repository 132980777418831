import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProfileSidebar from "components/profileSidebar/sidebar";
import MyActivity from "components/profileSidebar/activity";
import ReviewDataAccess from "dataAccess/reviewDataAccess";
import Rating from "react-rating";
import { Helmet } from "react-helmet";

const UserReviewProductsAll=()=> {

   const [userReviewProducts, setUserReviewProducts] = useState(undefined);

   useEffect(()=>{
      ReviewDataAccess.getUserReviewProductsAll()
         .then((data)=>{
            setUserReviewProducts(data);
         })
         .catch((error)=>{

         })
   },[]);

   if(userReviewProducts==undefined){
      return <p>No Product available in your review list</p>
   }
   return (
      <div className="user-profile-top">
         <Helmet>
            <title> Shopstick | Reviews </title>
            <meta name="description" content="My Shopstick Product Reviews"/>
         </Helmet>
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col md={3} sm={12} className="sideNav">
                  <ProfileSidebar />
               </Col>
               <Col md={9} sm={12} className="sideNav">
                  <MyActivity />
                  <div className="sideNav-container sideNav-container-bottom">
                     <p className="bottom-header">Reviews</p>
                     <hr className="solid"></hr>


                     { userReviewProducts.map((product, index)=>(
                      <Row className="reviews" key={index}>
                        <Col xl={2} lg={2} md={2} sm={3} >
                           <div className="image-section">
                              <div className="review-p-image">
                                 <img
                                    src={product.product.image1}
                                    alt="shopStick"
                                 />
                              </div>
                           </div>
                        </Col>
                        <Col xl={10} lg={10} md={10} sm={9} style={{float:"left"}}>
                           <p className="review-title">{product.product.name}</p>
                           <p className="review-text">{product.description}</p>
                           <div style={{ color: "#F9C732", fontSize:"10px" }}>
                              <Rating
                                 emptySymbol="fa fa-star-o fa-2x"
                                 fullSymbol="fa fa-star fa-2x"
                                 fractions={1}
                                 readonly={true}
                                 initialRating={product.rating}
                              />
                              <span style={{fontSize:"20px", color:"#40bfff", paddingLeft:"8px" }}>{product.rating}</span>
                           </div>
                        </Col>
                        <hr className="simple"></hr>
                      </Row>
                     ))}



                  </div>
               </Col>
            </Row>
         </div>
      </div>
   );
};

export default UserReviewProductsAll;
