import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { actions as cartActions } from "@redux/cartRedux";
import { actions as favoriteActions } from "@redux/favoriteRedux";
import cartServices from "services/cartServices";
import favoriteServices from "services/favouriteServices";
import { isUserLoggedIn } from "services/userServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating";
import { toast } from "react-toastify";
import ProductDataAccess from "dataAccess/productDataAccess";

const ProductBox = (props) => {
   const productItem = props.productItem;
   const dispatch = useDispatch();

   const requestToStock = (productId) => {
      ProductDataAccess.ProductRequestToStock(productId)
      .then((data)=>{
          toast.success(data);
      })
      .catch((error)=>{
          toast.error("Login first and send request again");
      });
   }

   return (
      <div className="product-card-container">
         <div className="product-card">
            <div className="card-inner">
               <div className="card-left">
                  <Link to={"/product-details/" + productItem.slug}  className="product-image-big">
                     {" "}
                     <img className="product-image" src={productItem.image1} alt="shopstick" />{" "}
                  </Link>
               </div>

               <div className="card-right">
                  {favoriteServices.isProductOnFavorite(productItem.id, props.favorite.favorite) ? (
                     <span
                        onClick={() => {
                           dispatch(
                              favoriteActions.removeProductFromFavorite(productItem.id, isUserLoggedIn(props.user.user))
                           );
                        }}
                        className="favorite-icon"
                     >
                        <img className="like" src={process.env.PUBLIC_URL + "/images/likered.png"} alt="shopStick" />
                     </span>
                  ) : (
                     <span
                        onClick={() => {
                           dispatch(
                              favoriteActions.addProductToFavorite(productItem.id, isUserLoggedIn(props.user.user))
                           );
                        }}
                        className="favorite-icon"
                     >
                        <img className="like" src={process.env.PUBLIC_URL + "/images/like.png"} alt="shopStick" />
                     </span>
                  )}
                  <div className="rating-content">
                     {productItem.avg_rating[0] != undefined && productItem.avg_rating[0].rating > 0 ? (
                        <span style={{ marginRight: "5px", fontSize:"16px", color:"#F9C732", }}>
                           <Rating
                              fullSymbol="fa fa-star"
                              emptySymbol="fa fa-star-o"
                              readonly = {true}
                              initialRating={productItem.avg_rating[0].rating}
                           />
                        </span>
                     ) : (
                        <span style={{ color: "#787c87" }}> not rated </span>
                     )}
                  </div>
                  <span>
                     <p className="card-product-name">{productItem.name}</p>
                  </span>
                  <span className="price-and-cart-btn">
                     <p className="product-price"> 
                        {productItem.special_price == 0 ? productItem.price : productItem.special_price } tk &nbsp; 
                        {productItem.special_price != 0 && <del className="product-before-price"> {productItem.price} tk </del> }
                     </p>

                     { productItem.quantity === 0 ? (
                        <div>
                              <p className="request-to-stock-button" onClick={()=>{ requestToStock(productItem.id) }}>
                                 <span className="request-to-stock-btn-text">Request to Stock</span>
                              </p>
                              <span className="out-of-stock"> Out Of Stock </span>
                        </div>
                     ) : (
                        cartServices.isProductOnCart(productItem.id, props.cart) ? (
                           <p className="cart-btn-after-click">
                              <span className="after-click">
                                 <span
                                    className="cart-minus-sign"
                                    onClick={() => {
                                       props.dispatch(
                                          cartActions.decreaseProductQuantity(
                                             productItem.id,
                                             cartServices.getCartQuantity(productItem.id, props.cart),
                                             isUserLoggedIn(props.user.user)
                                          )
                                       );
                                    }}
                                 >
                                    <FontAwesomeIcon icon="minus" />
                                 </span>
                                 &nbsp;&nbsp;&nbsp;&nbsp;
                                 <span>{cartServices.getCartQuantity(productItem.id, props.cart)}</span>
                                 &nbsp;&nbsp;&nbsp;&nbsp;
                                 <span
                                    className="cart-plus-sign"
                                    onClick={() => {
                                       cartServices.getCartQuantity(productItem.id, props.cart) === productItem.quantity ? (
                                          toast.error("stock out")
                                       ) : (
                                          dispatch(
                                             cartActions.increaseProductQuantity(
                                                productItem.id,
                                                cartServices.getCartQuantity(productItem.id, props.cart),
                                                isUserLoggedIn(props.user.user)
                                             )
                                          )
                                       )
                                    }}
                                 >
                                    <FontAwesomeIcon icon="plus" />
                                 </span>
                              </span>
                           </p>
                        ) : (
                           <p
                              className="cart-button"
                              onClick={() => {
                                 dispatch(cartActions.addProductToCart(productItem.id, isUserLoggedIn(props.user.user)));
                              }}
                           >
                              <span className="before-click">
                                 <span className="cart">
                                    <img
                                       className="cart-image"
                                       src={process.env.PUBLIC_URL + "/images/cart-blue.png"}
                                       alt="shopStick"
                                    />
                                 </span>
                                 <span className="cart responsive-width">Add to Cart</span>
                              </span>
                           </p>
                        )
                     )}
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = ({ product, cart, favorite, user }) => {
   return { product, cart: cart.cart, favorite, user };
};
export default connect(mapStateToProps)(ProductBox);
