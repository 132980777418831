import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OfferDataAccess from "dataAccess/offerDataAccess";
import { actions as cartActions } from "@redux/cartRedux";
import { actions as favoriteActions } from "@redux/favoriteRedux";
import cartServices from "services/cartServices";
import favoriteServices from "services/favouriteServices";
import { isUserLoggedIn } from "services/userServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating";
import CategoryFilter from "components/form/categoryFilter";
import ShortByFilter from "components/form/shortByFilter";
import NestleFilter from "components/form/nestleFilter";
import PriceRangeFilter from "components/form/priceRangeFilter";
import DateOfArrivalFilter from "components/form/dateOfArrivalFilter";
import SuperSaleProducts from "components/ourOffers/superSale";
import { actions as productActions } from "@redux/productRedux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import ProductDataAccess from "dataAccess/productDataAccess";

const SpecialOffersAll = (props) => {
    const productItem = props.product;
    const dispatch = useDispatch();

    const [selectedCategories, setSelectedCategories] = useState(["4", "7"]);
    const [selectedShortByItem, setSelectedShortByItem] = useState([]);
    const [selectedNestle, setSelectedNestle] = useState(["2"]);
  
    const [searchText, setSearchText] = useState("");
    const search = useCallback(() => {
       if (searchText.length > 0) props.history.push("/search/" + searchText);
    }, [props.history, searchText]);

    const [specialOfferProducts, setSpecialOfferProducts] = useState([]);
    useEffect(() => {
        OfferDataAccess
           .getSpecialOfferProducts()
           .then((data) => {
                setSpecialOfferProducts(data);
           })
           .catch((error) => {});
     }, []);

     const requestToStock = (productId) => {
        ProductDataAccess.ProductRequestToStock(productId)
        .then((data)=>{
            toast.success(data);
        })
        .catch((error)=>{
            toast.error("Login first and send request again");
        });
     }
  
    let categoryValues = [
      { id: "1", name: "Category1" },
      { id: "2", name: "Category2" },
      { id: "3", name: "Category3" },
      { id: "4", name: "Category4" },
      { id: "5", name: "Category5" },
      { id: "6", name: "Category6" },
      { id: "7", name: "Category7" },
   ];
  
   let shortByItemValues = [
      { id: "1", name: "Price(Low to High)" },
      { id: "2", name: "Price(High to Low)" },
      { id: "3", name: "Top reviews" },
      { id: "4", name: "Ascending" },
      { id: "5", name: "Descending" },
      { id: "6", name: "Others" },
   ];
  
   let nestleValues = [
      { id: "1", name: "Modhumoti" },
      { id: "2", name: "Nestle" },
      { id: "3", name: "Basundhora" },
   ];
  
    return (
        <div>
            <Helmet>
                <title> Shopstick | Special Offers </title>
                <meta name="description" content="Shopstick Home page" />
            </Helmet>
            <SuperSaleProducts/>
            
            <div className="regular-offer">
                <Row className="top">
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <p className="top-title"> 
                            Special Offers <br/>
                            <span style={{fontSize:"12px", fontWeight:500, }}> Shop from special offers products at least 180 tk to win NARZO 20 </span>
                        </p>
                    </Col>
                    {/* 
                    <Col xl={8} lg={8} md={12} sm={12} xs={12} className="product-filter-section">
                        <div className="filter-content">
                            <Row>
                                <Col>
                                <DateOfArrivalFilter
                                    label="Date of arrivals"
                                    onClear={() => {}}
                                    onApply={() => {}}
                                />
                                <PriceRangeFilter
                                    label="Price"
                                    onClear={() => {}}
                                    onApply={() => {}}
                                />
                                <NestleFilter
                                    label="Nestle"
                                    values={nestleValues}
                                    selectedValues={selectedNestle}
                                    setSelectedValues={(values) => {
                                        setSelectedNestle(values);
                                    }}
                                    onClear={() => {}}
                                    onApply={() => {}}
                                />
                                <CategoryFilter
                                    label="Category"
                                    values={categoryValues}
                                    selectedValues={selectedCategories}
                                    setSelectedValues={(values) => {
                                        setSelectedCategories(values);
                                    }}
                                    onClear={() => {}}
                                    onApply={() => {}}
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <ShortByFilter
                                    label="Short by"
                                    values={shortByItemValues}
                                    selectedValues={selectedShortByItem}
                                    setSelectedValues={(values) => {
                                        setSelectedShortByItem(values);
                                    }}
                                />
                                <div className="search-at-filter-section">
                                    <div className="search-at-filter">
                                        <input
                                            className="search-input"
                                            value={searchText}
                                            onChange={(event) => {
                                            setSearchText(event.target.value);
                                            }}
                                            type="text"
                                            onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                search();
                                            }
                                            }}
                                            placeholder="Search your needs..."
                                        />
                                        <img
                                            onClick={search}
                                            style={{ height: "28px" }}
                                            alt="search button"
                                            src="/images/search_with_back.png"
                                        />
                                    </div>
                                </div>
                                </Col>
                            </Row>
                        </div>
                    </Col> */}
                </Row>

                <Row>
                    { specialOfferProducts.map((product, index) => (
                        <div className="product-card-container" key={index}>
                        <div className="product-card">
                            <div className="card-inner">
                            <div className="card-left">
                                <Link to={"/product-details/" + product.slug} className="product-image-big">
                                    {" "}
                                    <img className="product-image" src={product.image1} alt="shopstick" />{" "}
                                </Link>
                            </div>

                            <div className="card-right">
                                {favoriteServices.isProductOnFavorite(product.id, props.favorite.favorite) ? (
                                    <span
                                        onClick={() => {
                                        dispatch(
                                            favoriteActions.removeProductFromFavorite(product.id, isUserLoggedIn(props.user.user))
                                        );
                                        }}
                                        className="favorite-icon"
                                    >
                                        <img className="like" src={process.env.PUBLIC_URL + "/images/likered.png"} alt="shopStick" />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => {
                                        dispatch(
                                            favoriteActions.addProductToFavorite(product.id, isUserLoggedIn(props.user.user))
                                        );
                                        }}
                                        className="favorite-icon"
                                    >
                                        <img className="like" src={process.env.PUBLIC_URL + "/images/like.png"} alt="shopStick" />
                                    </span>
                                )}
                                <div className="rating-content" style={{ color: "gold" }}>
                                    {product.avg_rating && product.avg_rating.length > 0 && product.avg_rating[0].rating && (
                                        <span style={{ marginRight: "5px" }}>
                                        <Rating
                                            fullSymbol="fa fa-star"
                                            emptySymbol="fa fa-star-o"
                                            readonly
                                            initialRating={product.avg_rating[0].rating}
                                        />
                                        </span>
                                    )}
                                    <span style={{ color: "#40BFFF" }}>
                                        {product.avg_rating && product.avg_rating.length > 0 && product.avg_rating[0].rating
                                        ? product.avg_rating[0].rating
                                        : "not rated"}
                                    </span>
                                </div>
                                <span>
                                    <p className="card-product-name">{product.name}</p>
                                </span>
                                <span className="price-and-cart-btn">
                                    <p className="product-price"> 
                                        {product.special_price == 0 ? product.price : product.special_price } tk &nbsp; 
                                        {product.special_price != 0 && <del className="product-before-price"> {product.price} tk </del> }
                                    </p>

                                    {
                                        product.quantity === 0 ? (
                                            <div>
                                                <p className="request-to-stock-button" onClick={()=>{ requestToStock(product.id) }}>
                                                    <span className="request-to-stock-btn-text">Request to Stock</span>
                                                </p>
                                                <span className="out-of-stock"> Out Of Stock </span>
                                            </div>
                                        ) : (
                                            cartServices.isProductOnCart(product.id, props.cart) ? (
                                                <p className="cart-btn-after-click">
                                                <span className="after-click">
                                                    <span
                                                        className="cart-minus-sign"
                                                        onClick={() => {
                                                            props.dispatch(
                                                            cartActions.decreaseProductQuantity(
                                                                product.id,
                                                                cartServices.getCartQuantity(product.id, props.cart),
                                                                isUserLoggedIn(props.user.user)
                                                            )
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon="minus" />
                                                    </span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <span>{cartServices.getCartQuantity(product.id, props.cart)}</span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <span
                                                        className="cart-plus-sign"
                                                        onClick={() => {
                                                            cartServices.getCartQuantity(product.id, props.cart) === product.quantity ? (
                                                                toast.error("stock out")
                                                                ) : (
                                                                dispatch(
                                                                    cartActions.increaseProductQuantity(
                                                                        product.id,
                                                                        cartServices.getCartQuantity(product.id, props.cart),
                                                                        isUserLoggedIn(props.user.user)
                                                                    )
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon="plus" />
                                                    </span>
                                                </span>
                                                </p>
                                            ) : (
                                                <p
                                                className="cart-button"
                                                onClick={() => {
                                                    dispatch(cartActions.addProductToCart(product.id, isUserLoggedIn(props.user.user)));
                                                }}
                                                >
                                                    <span className="before-click">
                                                        <span className="cart">
                                                            <img
                                                                className="cart-image"
                                                                src={process.env.PUBLIC_URL + "/images/cart-blue.png"}
                                                                alt="shopStick"
                                                            />
                                                        </span>
                                                        <span className="cart responsive-width">Add to Cart</span>
                                                    </span>
                                                </p>
                                            )
                                        )
                                    }
                                </span>
                            </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </Row>
                {
                    !props.product.productsLoading &&
                    props.product.productsInfo.current_page &&
                    props.product.productsInfo.current_page !== props.product.productsInfo.last_page && (
                    <Row>
                        <Col className="more-product">
                            <Button className="load-more" variant="primary" onClick={() => {
                                dispatch(
                                    productActions.getAllProducts(props.product.productsInfo.current_page + 1)
                                );
                            }}>
                                <span className="btn-text">Load More</span>
                            </Button>
                        </Col>
                    </Row> 
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ product, cart, favorite, user }) => {  return { product, cart: cart.cart, favorite, user }; };
export default connect(mapStateToProps)(SpecialOffersAll);
