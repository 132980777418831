export default {};
export const convertObjectToFormData = (object) => {
   var form_data = new FormData();

   for (var key in object) {
      form_data.append(key, object[key]);
   }
   return form_data;
};

export const generateAddressString = (address) => {
   let addressString = "";
   if (address.name && address.name.length > 0) {
      addressString += address.name + ", ";
   }
   if (address.city && address.city.length > 0) {
      addressString += address.city + ", ";
   }
   if (address.area && address.area.length > 0) {
      addressString += address.area + ", ";
   }
   if (address.phone_number && address.phone_number.length > 0) {
      addressString += address.phone_number;
   }
   return addressString;
};
