import React from "react";
import language from "data/language";
import { connect } from "react-redux";
interface Props {
   firstKey: string;
   secondKey: string;
   language: string;
}
const LanguageText = (props: Props) => {
   return <span>{language[props.firstKey][props.secondKey][props.language]}</span>;
};

const mapStateToProps = ({ user }) => ({ language: user.language });

export default connect(mapStateToProps)(LanguageText);
