import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpecialOffers from 'components/ourOffers/specialOffers'
import ProductList from 'components/ourproducts/productList'
import Categories from 'components/explorecategories/exploreCategory'

export default class OurproductsMain extends React.Component {
  render() {
    return (
      <div>
 <Container
          style={{ maxWidth: "96%" }}
          className="products-main-container"
        >
          <Row>
            <Col sm={12} md={8} lg={8} className="banner">
              <div className="g-leftbanner groceries-banner">
                <Row className='left-banner-row'>
                  <Col className="hero-text">
                    <p className="super-sale" style={{ color: "white" }}>
                      Super Sale
                    </p>
                    <p className="off" style={{ color: "black" }}>
                      90% Off
                    </p>
                    <div className="timer">
                      <div className="timer-block">
                        <span className="timer-digit">
                          <p className="timer-text">27</p>
                        </span>
                      </div>
                      <div className="timer-block">
                        <span className="timer-digit">
                          <p className="timer-text">10</p>
                        </span>
                      </div>
                      <div className="timer-block">
                        <span className="timer-digit">
                          <p className="timer-text">12</p>
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col className="banner-col">
                    <img
                      className="g-banner-image"
                      src={process.env.PUBLIC_URL + "/images/headphone.png"}
                      alt="shopStick"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="sub-banner">
              <div className="g-toprightcard">
                <Row className='top-right-row'>
                  <Col className="hero-text2">
                    <p className="super-sale" style={{ color: "white" }}>
                      Up to
                    </p>
                    <p className="off" style={{ color: "black" }}>
                      70% Off
                    </p>
                  </Col>
                  <Col className="banner-col">
                    <img
                      className="banner-image"
                      src={process.env.PUBLIC_URL + "/images/biscuit.png"}
                      alt="shopStick"
                    />
                  </Col>
                </Row>
              </div>
            
                <div className="g-toprightcard2">
                  <Row>
                    <Col className="hero-text2">
                      <p className="super-sale" style={{ color: "white" }}>
                        Up to
                      </p>
                      <p className="off" style={{ color: "black" }}>
                        70% Off
                      </p>
                    </Col>
                    <Col className="banner-col">
                      <img
                        className="banner-image2"
                        src={process.env.PUBLIC_URL + "/images/shoe.png"}
                        alt="shopStick"
                      />
                    </Col>
                  </Row>
                </div>
            </Col>
          </Row>
        </Container>
        <Container
          className="all-products-container"
          style={{ background: "#ffffff" }}
        >
          <div>
            <Container style={{ maxWidth: "96%" }}>
              <div className="main-products-container">
                <Row className="what-we-have-more">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <p className="more-title">
                      {" "}
                      <Link to="/our-products">Our Products (Groceries)</Link>
                    </p>
                    <p className="our-products-des">
                      ShopStick provides you more than 1000+ products from each
                      category.
                      <br /> You can choose products from here as well as filter
                      also.
                    </p>
                  </Col>
                  <Col>
                    <Button
                      className=" p-main-btn btn-primary top-buttons"
                      variant="primary"
                    >
                      <span className="cart"></span>
                      <span className="cart responsive-width">Categories</span>
                    </Button>
                    <Button
                      className="p-main-btn btn-primary top-buttons"
                      variant="primary"
                    >
                      <span className="cart"></span>
                      <span className="cart responsive-width">
                        Sub-categories
                      </span>
                    </Button>
                    <Link to="/our-products-groceries">
                      <Button
                        className="p-main-btn btn-primary top-buttons"
                        variant="primary"
                      >
                        <span className="cart"></span>
                        <span className="cart responsive-width">Nestle</span>
                        <img
                          className="cross"
                          src={process.env.PUBLIC_URL + "/images/cross.png"}
                          alt="shopStick"
                        />
                      </Button>
                    </Link>
                    <Button
                      className="p-main-btn btn-primary top-buttons"
                      variant="primary"
                    >
                      <span className="cart"></span>
                      <span className="cart responsive-width">Price</span>
                    </Button>
                    <Button
                      className="p-main-btn btn-primary top-buttons"
                      variant="primary"
                    >
                      <span className="cart"></span>
                      <span className="cart responsive-width">
                        Date 0f arrival
                      </span>
                    </Button>
                  </Col>
                </Row>
               <ProductList/>
                  <Row className="seemore">
                    <Col>
                      <Button className="cart-button" variant="primary">
                        <span className="seemore-text">Load more</span>
                      </Button>{" "}
                    </Col>
                  </Row>
              </div>
            </Container>
          </div>
        </Container>
        <Container style={{ maxWidth: '96%' }}>
        <Categories/>
                <Row>
          <Col className="products-row" lg="6">
            <p className="explore-categories-header"><Link to='/our-offers'>Our offers</Link></p>
          </Col>
          <Col>
          <div  className='slider explore-categories-header'>
          <a href="#prev" role="button">
          	<img src="/images/arrowleft.png"  alt="Previous product"/>
          </a>
          &nbsp;
          &nbsp;
         <a href="#next" role="button">
	          <img src="/images/arrowright.png"  alt="Next product"/>
         </a>
         &nbsp;
         </div>
          </Col>
        </Row>
        <SpecialOffers/>
        </Container>   
      </div>
    );
  }
}
