import React from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Button } from "react-bootstrap";
import LanguageText from "components/common/languageText";
import { Link } from "react-router-dom";


const Footer = (props: any) => {
  return (
    <footer className="footer-container">
      <Container>
        <Row>
          <Col sm={6} md={6} lg={3} xl={3}>
            <div>
               <p className="title"> <LanguageText firstKey="footer" secondKey="myProfile" /> </p>
              <div>
                <Link to="/my-profile"> <div className="menu-item">Personal Info</div> </Link>
                <Link to="/change-password"> <div className="menu-item">Change password</div> </Link>
                <Link to="/orders"> <div className="menu-item">Orders</div> </Link>
                <Link to="/reviews"> <div className="menu-item">Reviews</div> </Link>
                <Link to="/coupons"> <div className="menu-item">Coupons</div> </Link>
                <Link to="/address"> <div className="menu-item">Address</div> </Link>
              </div>
            </div>
          </Col>
          <Col sm={6} md={6} lg={3} xl={3}>
            <div className="">
              <p className="title"> <LanguageText firstKey="footer" secondKey="aboutShopstick" /> </p>
              <div>
              <Link to="/what-we-are"> <div className="menu-item">What we are?</div> </Link>
              <Link to="/our-services"> <div className="menu-item">Our services</div> </Link>
              <Link to="/terms-of-services"> <div className="menu-item">Terms of service</div> </Link>
              <Link to="/faq"> <div className="menu-item">Help & FAQ</div> </Link>
              <Link to="/return-and-refund"> <div className="menu-item">Return & Refund</div> </Link>
              </div>
            </div>
          </Col>
          <Col sm={6} md={6} lg={3} xl={3}>
            <div className="">
              <p className="title"> <LanguageText firstKey="footer" secondKey="haveAlook" /> </p>
              <div>
                <Link to="/our-offers"> <div className="menu-item">Our offers</div> </Link>
                <Link to="#"> <div className="menu-item">New arrivals</div> </Link>
                <Link to="#"> <div className="menu-item">Hot deals</div> </Link>
                <Link to="#"> <div className="menu-item">Summer collections</div> </Link>
                <Link to="#"> <div className="menu-item">Eid collections</div> </Link>
              </div>
            </div>
          </Col>
          <Col sm={6} md={6} lg={3} xl={3}>
            <div className="contact-info-container">
              <p className="title"> <LanguageText firstKey="footer" secondKey="contactInfo" /> </p>
              <div>
                <div className="area">
                  <div>
                    <img alt="mail" src="/images/mail.png" />
                    <span className="menu-item-contact">info@shopstick.com.bd</span>
                  </div>
                </div>
                <div className="area">
                  <div>
                    <img alt="mail" src="/images/phone.png" />
                    <span className="menu-item-contact">+88 01923 188 188</span>
                  </div>
                  <div>
                    <span className="menu-item-contact">+88 01923 199 199</span>
                  </div>
                  <div>
                    <span className="menu-item-contact">+88 01923 211 211</span>
                  </div>
                </div>
                <div className="area">
                  <div>
                    <img alt="mail" src="/images/map-pin.png" />
                    <span className="menu-item-contact">Road: Sonadanga Main Road,</span>
                  </div>
                  <div>
                    <span className="menu-item-contact">Sonadanga, Khulna, Bangladesh </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="social-row">
              <Link to="/contact-us"> <Button className="rounded-pill" variant="outline-primary"> Email Us </Button>{" "} </Link> &nbsp;
              <a href="https://www.facebook.com/shopstick.com.bd/" target="_blank"> <img alt="mail" src="/images/fb.png" /> </a> &nbsp;
              <img alt="mail" src="/images/in.png" />
              &nbsp;
              <img alt="mail" src="/images/tw.png" />
              &nbsp;
              <img alt="mail" src="/images/yt.png" />
            </div>
          </Col>
        </Row>
        
      </Container>

          <div className="copyright">
            <div className="container">
              <div className="row copyright-text">
                <div className="col-lg-12">
                  <p className="p-small">
                    Copyright © 2020{" "}
                    <span style={{ color: "white" }}>Shop</span>
                    <span style={{ color: "#40BFFF" }}>Stick</span>. All rights
                    reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
          
    </footer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
