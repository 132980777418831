import React from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "sweetalert/dist/sweetalert.css";
import Footer from "./footer";
import Header from "./header";


const Layout = (props: any) => {
   return (
      <div>
         <Header />
         <div style={{ background: "#F7F8FA", padding: "20px 0px" }}>
            <div style={{ borderRadius: "5px" }}>{props.children}</div>
         </div> 
         <Footer />
         <ToastContainer />
      </div>
   );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
