import { Switch, Route } from "react-router-dom";
import React from "react";
import auth from "hoc/auth";
import Home from "containers/home";
import Login from "containers/account/login";
import ForgotPassword from "containers/account/forgotPassword";
import Logout from "containers/account/logout";
import ContactUs from "containers/contactUs/contactUs";
import Layout from "hoc/layout";
import CreateAccount from "containers/account/createAccount";
import OurProductsByCategory from "containers/products/productsByCategory";
import ProductsBySearch from "containers/products/prouctBySearch";
import ProductDetails from "containers/products/productDetails";
import Groceries from "containers/products/ourProductsgroceries";
import OfferContainer from "containers/ouroffers/offerContainer";
import SpecialOffers from "components/ourOffers/specialOffers";
import SpecialOffersAll from "containers/ouroffers/specialOfferAll";
import RegularOffersAll from "containers/ouroffers/regularOfferAll";
import ShopStickProductsAll from "containers/home/shopStickProductsAll";
import MyProfile from "containers/myProfile/myProfile";
import Orders from "containers/myProfile/orders";
import ChangePassword from "containers/myProfile/changePassword";
import UserReviews from "containers/myProfile/userReviews";
import Favorite from "containers/myProfile/favorite";
import ShoppingCart from "containers/myProfile/shoppingCart";
import Checkout from "containers/myProfile/checkout";
import Coupons from "containers/myProfile/coupons";
import Address from "containers/myProfile/address";
import AddAddress from "containers/myProfile/addAddress";
import PaymentGateway from "containers/payment/paymentGateway";
import AllProducts from "containers/products/allProducts";
import PaymentCancel from "containers/payment/paymentCancel";
import PaymentError from "containers/payment/paymentError";
import FAQ from "components/aboutShopstick/faq";
import WhatWeAre from "components/aboutShopstick/whatWeAre";
import OurServices from "components/aboutShopstick/ourServices";
import TermsOfService from "components/aboutShopstick/termsOfService";
import ReturnAndRefund from "components/aboutShopstick/returnAndRefund";

const Router = () => {
   return (
      <Layout>
         <Switch>
            <Route path="/" exact component={auth(Home, false, true)} />
            <Route path="/login" exact component={auth(Login, true, false)} />
            <Route path="/logout" exact component={auth(Logout, false, true)} />
            <Route path="/forgot-password" exact component={auth(ForgotPassword, true, false)} />
            <Route path="/create-account" exact component={auth(CreateAccount, true, false)} />
            <Route path="/contact-us" exact component={auth(ContactUs, false, true)} />
            <Route path="/our-products/:categoryId" exact component={auth(OurProductsByCategory, false, true)} />
            <Route path="/allProducts" exact component={auth(AllProducts, false, true)} />
            <Route path="/search/:searchText" exact component={auth(ProductsBySearch, false, true)} />
            <Route path="/product-details/:productId" exact component={auth(ProductDetails, false, true)} />
            <Route path="/our-products-groceries" exact component={auth(Groceries, false, true)} />
            <Route path="/our-offers" exact component={auth(OfferContainer, false, true)} />
            <Route path="/special-offers" exact component={auth(SpecialOffers, false, true)} />
            <Route path="/special-offers-all" exact component={auth(SpecialOffersAll, false, true)} />
            <Route path="/regular-offers-all" exact component={auth(RegularOffersAll, false, true)} />
            <Route path="/shopStick-products-all" exact component={auth(ShopStickProductsAll, false, true)} />
            <Route path="/my-profile" exact component={auth(MyProfile, false, false)} />
            <Route path="/orders" exact component={auth(Orders, false, true)} />
            <Route path="/orders/:status" exact component={auth(Orders, false, true)} />
            <Route path="/change-password" exact component={auth(ChangePassword, false, true)} />
            <Route path="/user-reviews-all" exact component={auth(UserReviews, false, true)} />
            <Route path="/favorite" exact component={auth(Favorite, false, true)} />
            <Route path="/shopping-cart" exact component={auth(ShoppingCart, false, true)} />
            <Route path="/checkout" exact component={auth(Checkout, false, false)} />
            <Route path="/coupons" exact component={auth(Coupons, false, true)} />
            <Route path="/address" exact component={auth(Address, false, true)} />
            <Route path="/address-upsert/:addressId" exact component={auth(AddAddress, false, true)} />
            <Route path="/payment-gateway" exact component={auth(PaymentGateway, false, true)} />
            <Route path="/order-cancel" exact component={auth(PaymentCancel, false, true)} />
            <Route path="/order-error" exact component={auth(PaymentError, false, true)} />
            <Route path="/order-failed" exact component={auth(PaymentError, false, true)} />
            <Route path="/faq" exact component={auth(FAQ, false, true)} />
            <Route path="/what-we-are" exact component={auth(WhatWeAre, false, true)} />
            <Route path="/our-services" exact component={auth(OurServices, false, true)} />
            <Route path="/terms-of-services" exact component={auth(TermsOfService, false, true)} />
            <Route path="/return-and-refund" exact component={auth(ReturnAndRefund, false, true)} />
            
         </Switch>
      </Layout>
   );
};
export default Router;
