import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomDatePicker from "./datePicker";
export const InputField = ({ input, label, type, meta: { touched, error, warning } }) => (
   <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control {...input} as="input" type={type} placeholder={label} />
      {touched && error && (
         <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
         </Form.Control.Feedback>
      )}
   </Form.Group>
);
export const InputFieldForAccount = ({ input, label, placeholder, type, meta: { touched, error, warning } }) => (
   <Form.Group as={Row}>
      <Form.Label column sm="2" className="label">
         {label}
      </Form.Label>
      <Col sm="10">
         <Form.Control {...input} as="input" type={type} placeholder={placeholder} className="label" />
         {touched && error && (
            <Form.Control.Feedback style={{ display: "block" }} type="invalid">
               {error}
            </Form.Control.Feedback>
         )}
      </Col>
   </Form.Group>
);
export const DateFieldRenderer = ({ input, meta: { touched, error, warning } }) => (
   <Form.Group as={Row} controlId="formPlaintextPassword" className="label">
      <Form.Label column sm="2">
         Birth date
      </Form.Label>
      <Col sm="10">
         <CustomDatePicker {...input} />
      </Col>
   </Form.Group>
);

export const GenderRenderer = ({ input, meta: { touched, error, warning } }) => (
   <Form.Group as={Row} controlId="formPlaintextPassword" className="label">
      <Form.Label column sm="2">
         Gender
      </Form.Label>
      <div className="mb-3">
         <Form.Check
            checked={input.value === 1}
            onChange={() => {
               input.onChange(input.value === 1 ? "" : 1);
            }}
            id="1"
            inline
            label="Male"
         />
         <Form.Check
            checked={input.value === 2}
            onChange={() => {
               input.onChange(input.value === 2 ? "" : 2);
            }}
            id="2"
            inline
            label="Female"
         />
         <Form.Check
            checked={input.value === 3}
            onChange={() => {
               input.onChange(input.value === 3 ? "" : 3);
            }}
            id="3"
            inline
            label="Other"
         />
      </div>
      {touched && error && (
         <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
         </Form.Control.Feedback>
      )}
   </Form.Group>
);

export const InputFieldWithoutLabel = ({ input, placeholder, type, meta: { touched, error, warning } }) => (
   <Form.Group>
      <Form.Control className="form-design" {...input} type={type} as="input" placeholder={placeholder} />
      {touched && error && (
         <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
         </Form.Control.Feedback>
      )}
   </Form.Group>
);

export const InputFieldWithoutLabelContact = ({ input, placeholder, type, meta: { touched, error, warning } }) => (
   <div>
      <Form.Control className="contact-form" {...input} as="input" placeholder={placeholder} />
      {touched && error && <Form.Control.Feedback style={{ display: "block" }} type="invalid"></Form.Control.Feedback>}
   </div>
);

export const TextAreaField = ({ input, placeholder, label, type, meta: { touched, error, warning } }) => (
   <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control {...input} as="textarea" placeholder={placeholder} />
      {touched && error && (
         <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
         </Form.Control.Feedback>
      )}
   </Form.Group>
);

export const CheckBox = ({ input, label }) => (
   <Form.Group>
      <Form.Check {...input} type="checkbox" checked={input.value} label={label} />
   </Form.Group>
);
