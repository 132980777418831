import React, { useEffect, useRef } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function MenuExpanded({
   selectedIndex,
   hideMenu,
   childMenuList,
}: {
   selectedIndex: number;
   hideMenu: Function;
   childMenuList: Array<any>;
}) {
   const menuRef = useRef(null);
   useEffect(() => {
      const handleClickOutside = (event: Event) => {
         if (menuRef.current && !menuRef.current.contains(event.target)) {
            hideMenu();
         }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [hideMenu]);

   if (selectedIndex === -1) return null;
   return (
      <div className="sidebar-more-items" ref={menuRef}>
         <Card className="sidebar-more-items-card">
            <ListGroup variant="flush">
               {childMenuList.map((item, index) => (
                  <Link to={ "/our-products/" + item.slug } key={index}>
                     <ListGroup.Item className="sidebar-more-menu">{item.name}</ListGroup.Item>
                  </Link>
               ))}
            </ListGroup>
         </Card>
      </div>
   );
}
