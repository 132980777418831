import React, { useEffect, useRef, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import MenuItem from "./menuItem";

export default function Account({ hideMenu, isUserLoggedIn }: { hideMenu: Function; isUserLoggedIn: boolean; }) {
   const menuRef = useRef(null);

   useEffect(() => {
      const handleClickOutside = (event: Event) => {
         if (menuRef.current && !menuRef.current.contains(event.target)) {
            hideMenu();
         }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [hideMenu]);

   return (
      <div className="item-list" ref={menuRef} >

         {!isUserLoggedIn && (
            <div onClick={()=>{hideMenu()}}>
               <MenuItem
                  name="Create Account"
                  imageSrc="images/usericon.png"
                  imageSrcOnHover="images/usericonw.png"
                  alt="icon"
                  to="/create-account"
               />
            </div>
         )}

         {!isUserLoggedIn && (
            <div onClick={()=>{hideMenu()}}>
               <MenuItem
                  name="Log In"
                  imageSrc="images/log-in.png"
                  imageSrcOnHover="images/log-inw.png"
                  alt="icon"
                  to="/login"
               />
            </div>
         )}

         {isUserLoggedIn && (
            <div onClick={()=>{hideMenu()}}>
               <MenuItem
                  name="My profile"
                  imageSrc="images/usericon.png"
                  imageSrcOnHover="images/usericonw.png"
                  alt="icon"
                  to="/my-profile"
               />
            </div>
         )}

         {isUserLoggedIn && (
            <div onClick={()=>{hideMenu()}}>
               <MenuItem
                  name="Log Out"
                  imageSrc="images/log-in.png"
                  imageSrcOnHover="images/log-inw.png"
                  alt="icon"
                  to="/logout"
               />
            </div>
         )}

         <hr />
         <div onClick={()=>{hideMenu()}}>
            <MenuItem
               name="Our Offers"
               imageSrc="images/gift.png"
               imageSrcOnHover="images/giftw.png"
               alt="icon"
               to="/our-offers"
            />
         </div>

         <div onClick={()=>{hideMenu()}}>
            <MenuItem
               name="Contact Us"
               imageSrc="images/mailb.png"
               imageSrcOnHover="images/mailw.png"
               alt="icon"
               to="/contact-us"
            />
         </div>
      </div>
   );
}
