import React from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import ProfileSidebar from "components/profileSidebar/sidebar";
import MyActivity from "components/profileSidebar/activity";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { DateFieldRenderer, GenderRenderer, InputFieldForAccount } from "components/form/inputRenderes";
import { required, email } from "services/validators";
import { toast } from "react-toastify";
import { actions as userActions } from "@redux/userRedux";
import userDataAccess from "dataAccess/userDataAccess";
import { User } from "models/user";
import { Helmet } from "react-helmet";

const MyProfile = (props) => {
   const dispatch = useDispatch();
   const handleFormSubmit = (user) => {
      return userDataAccess
         .updateProfile(user)
         .then((data) => {
            dispatch(userActions.setUser(data));
            toast.success("Updated profile successfully");
         })
         .catch((error: Error) => {
            throw new SubmissionError({ _error: error.message });
         });
   };
   return (
      <div className="user-profile-top">
         <Helmet>
            <title> Shopstick | Profile </title>
            <meta name="description" content="my shopStick profile" />
         </Helmet>
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col md={3} sm={12} className="sideNav">
                  <ProfileSidebar />
               </Col>
               <Col md={9} sm={12} className="sideNav">

                  <MyActivity />
                  
                  <div className="sideNav-container sideNav-container-bottom">
                     <p className="bottom-header">Personal Information</p>
                     <hr className="solid"></hr>
                     <Form className="information" onSubmit={props.handleSubmit((data) => handleFormSubmit(data))}>
                        <Field
                           name="name"
                           type="text"
                           label="Name"
                           component={InputFieldForAccount}
                           placeholder="Name"
                           validate={[required]}
                        />
                        <Field
                           name="phone_number"
                           type="text"
                           label="Phone"
                           component={InputFieldForAccount}
                           placeholder=" "
                           validate={[required]}
                        />
                        <Field
                           name="email"
                           type="text"
                           label="Email"
                           component={InputFieldForAccount}
                           placeholder="example@example.com"
                           validate={[required, email]}
                        />

                        <Field
                           name="dob"
                           type=""
                           label="Birth date"
                           component={DateFieldRenderer}
                           placeholder="Click to select a date"
                        />
                        <Field name="gender" type="" label="Gender" component={GenderRenderer} />
                        {props.error && (
                           <div>
                              <strong style={{ color: "red" }}>{props.error}</strong>
                           </div>
                        )}
                        <Button disabled={props.submitting} type="submit">
                           Save
                        </Button>
                     </Form>
                  </div>
               </Col>
            </Row>
         </div>
      </div>
   );
};
const mapStateToProps = ({ user }) => ({
   initialValues: {
      name: user.user.name,
      phone_number: user.user.phone_number,
      email: user.user.email,
      dob: user.user.dob,
      gender: getGenderFromName(user.user.gender),
   },
});
const MyProfileForm = reduxForm({ form: "MyProfile" })(MyProfile);

export default connect(mapStateToProps)(MyProfileForm);

const getGenderFromName = (gender) => {
   if (gender === "Male") return 1;
   if (gender === "Female") return 2;
   if (gender === "Other") return 3;
   return "";
};
