import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import favoriteDataAccess from "dataAccess/favoriteDataAccess";
import SpinnerLocal from "components/spinner";
import { connect, useDispatch } from "react-redux";
import { actions as favoriteActions } from "@redux/favoriteRedux";
import { actions as cartActions } from "@redux/cartRedux";
import cartServices from "services/cartServices";
import { Favorite } from "models/favorite";
import favoriteServices from "services/favouriteServices";
import { isUserLoggedIn } from "services/userServices";
import { Helmet } from "react-helmet";

const FavoriteProductList = (props) => {
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(favoriteActions.getFavoriteProducts(props.favorite.favorite));
   }, [dispatch, props.favorite.favorite]);
   if (props.favorite.favorite.length !== props.favorite.products.length) {
      return <SpinnerLocal />;
   }
   if (props.favorite.productsError) {
      return <div>Something went wrong</div>;
   }
   const addAllToCart = () => {
      let _data = [];
      props.favorite.favorite.forEach((favoriteItem: Favorite, index: number) => {
         if (!cartServices.isProductOnCart(favoriteItem.id, props.cart.cart)) {
            _data.push({ id: favoriteItem.id, quantity: 1 });
         }
      });
      dispatch(cartActions.addAllProductsToCart(_data, isUserLoggedIn(props.user.user)));
   };
   return (
      <div className="user-profile-top">
         <Helmet>
            <title> Shopstick | Favorite </title>
            <meta name="description" content="My Favorite Products"/>
         </Helmet>
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col className="sideNav">
                  <Row className="favorite-container">
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="first-section">
                        <div>
                           <span className="header"> My Favorite </span>
                        </div>
                     </Col>
                     <hr style={{ width: "100%" }} />
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="second-section">
                        {props.favorite.products && props.favorite.products.length > 0 ? (
                           props.favorite.favorite.map((favorite: Favorite, index) => {
                              const product = favoriteServices.getFavoriteProduct(favorite, props.favorite.products);
                              return (
                                 <Row className="content">
                                    <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                       <Row>
                                          <Col xs="auto">
                                             <img src={product.image1} className="left-img-circle" alt="shopstick" />
                                          </Col>
                                          <Col xs="auto">
                                             <h4 className="name-and-unit">{product.name}</h4>{" "}
                                             <span> {product.unit.name} </span>
                                          </Col>
                                       </Row>
                                    </Col>
                                    <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                       <h4 className="price"> {product.price} TK </h4>
                                    </Col>
                                    <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                       <img src="/images/heartyelo.png" alt="shopstick" className="favorite-icon" />
                                    </Col>
                                    <Col xl={3} lg={3} md={12} sm={12} xs={12} className="favorite-cart-btn">
                                       {cartServices.isProductOnCart(product.id, props.cart.cart) ? (
                                          <span className="after-click">
                                             <span
                                                className="cart-minus-sign"
                                                onClick={() => {
                                                   props.dispatch(
                                                      cartActions.decreaseProductQuantity(
                                                         product.id,
                                                         cartServices.getCartQuantity(product.id, props.cart.cart),
                                                         isUserLoggedIn(props.user.user)
                                                      )
                                                   );
                                                }}
                                             >
                                                -
                                             </span>
                                             &nbsp;&nbsp;&nbsp;&nbsp;
                                             <span>{cartServices.getCartQuantity(product.id, props.cart.cart)}</span>
                                             &nbsp;&nbsp;&nbsp;&nbsp;
                                             <span
                                                className="cart-plus-sign"
                                                onClick={() => {
                                                   dispatch(
                                                      cartActions.increaseProductQuantity(
                                                         product.id,
                                                         cartServices.getCartQuantity(product.id, props.cart.cart),
                                                         isUserLoggedIn(props.user.user)
                                                      )
                                                   );
                                                }}
                                             >
                                                +
                                             </span>
                                          </span>
                                       ) : (
                                          <p
                                             className="cart-button"
                                             onClick={() => {
                                                dispatch(
                                                   cartActions.addProductToCart(
                                                      product.id,
                                                      isUserLoggedIn(props.user.user)
                                                   )
                                                );
                                             }}
                                          >
                                             <span className="before-click">
                                                <span className="cart">
                                                   <img
                                                      className="cart-image"
                                                      src={process.env.PUBLIC_URL + "/images/cart-blue.png"}
                                                      alt="shopStick"
                                                   />
                                                </span>
                                                <span className="cart responsive-width">Add to Cart</span>
                                             </span>
                                          </p>
                                       )}
                                    </Col>
                                 </Row>
                              );
                           })
                        ) : (
                           <div> No products on Favorite List </div>
                        )}
                        <br />
                     </Col>
                     <hr style={{ width: "100%" }} />
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="third-section">
                        <div>
                           <Button
                              variant="outline-primary"
                              className="clear-all-btn"
                              onClick={() => {
                                 dispatch(
                                    favoriteActions.removeAllProductFromFavorite(isUserLoggedIn(props.user.user))
                                 );
                              }}
                           >
                              <span>Clear All</span>
                           </Button>
                           <Button variant="outline-primary" className="add-all-to-cart" onClick={addAllToCart}>
                              <span>
                                 {" "}
                                 <img src="/images/cart-blue.png" alt="shopStick" /> &nbsp; Add All To Cart{" "}
                              </span>
                           </Button>
                        </div>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </div>
      </div>
   );
};
const mapStateToProps = ({ user, cart, favorite }) => ({ user, cart, favorite });
export default connect(mapStateToProps)(FavoriteProductList);
