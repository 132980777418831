import Axios, { AxiosError, AxiosResponse } from "axios";
import { getAuthHeader } from "services/userServices";
import { convertObjectToFormData } from "services/util";
import {
   linkGetProducts,
   linkGetProductDetails,
   linkGetHomePageProducts,
   linkGetProductsByCategory,
   linkGetAllCategory,
   linkGetProductsByIds,
   linkGetProductsBySearch,
   getHomePageShopStickProduct,
   linkProductRequestToStock,
} from "./apiEndpoints";
class productDA {
   //ToDo::Sabuj::Update method with proper parameters
   /**
    * Get list of products
    */
   getProducts = () => {
      return Axios.get(linkGetProducts)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getAllProducts = (page = 1) => {
      return Axios.get(linkGetHomePageProducts + "?page=" + page)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               if (response.data.message === "No data available") {
                  throw new Error("No products available");
               } else {
                  return response.data.data;
               }
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getProductsByCategory = (categoryId: string, page = 1, perPage = 20) => {
      return Axios.get(linkGetProductsByCategory + "/" + categoryId + "/" + perPage + "?page=" + page)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               if (response.data.message === "No data available") {
                  throw new Error("No products available");
               } else {
                  return response.data.data;
               }
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getAllCategory = () => {
      return Axios.get(linkGetAllCategory)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               if (response.data.message === "No data available") {
                  throw new Error("No products available");
               } else {
                  return response.data.data;
               }
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getProductsBySearch = (searchText: string, page = 1, perPage = 20) => {
      return Axios.get(linkGetProductsBySearch + "/" + perPage + "/" + searchText + "?page=" + page)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               if (response.data.message === "No data available") {
                  throw new Error("No products available");
               } else {
                  return response.data.data;
               }
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   /**
    * Gets details about a product
    * @param productId id of the product
    */
   getProductDetails = (productId: string) => {
      return Axios.get(linkGetProductDetails + "/" + productId)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getHomePageProducts = () => {
      return Axios.get(linkGetHomePageProducts)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getSpecificProducts = (ids: Array<string>) => {
      let formData = new FormData();
      ids.forEach((id) => {
         formData.append("ids[]", id);
      });
      return Axios.post(linkGetProductsByIds, formData)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               if (response.data.message === "No Request ID Sent.") {
                  return [];
               } else {
                  return response.data.data.data;
               }
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getHomePageShopStickProduct = () => {
      return Axios.get(getHomePageShopStickProduct)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   ProductRequestToStock = (productID) => {
      const _Data = convertObjectToFormData({product_id:productID});
      return Axios.post(linkProductRequestToStock, _Data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            return response.data.message;
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };


}
export default new productDA();
