import LanguageText from "components/common/languageText";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";

//import "components/ourOffers/node_modules/pure-react-carousel/dist/react-carousel.es.css";

import ProductDataAccess from "dataAccess/productDataAccess";

const ExploreCategories = (props: any) => {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   const [showCategoryIndex, setShowCategoryIndex] = useState(-1);
   const [seeAllBtn, setSeeAllBtn] = useState(false);
   const [allCategory, setAllCategory] = useState([]);
   const [totalProduct, setTotalProduct] = useState();

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   const getVisibleSlides = () => {
      if (windowWidth > 992) {
         return 4;
      } else if (windowWidth > 768) {
         return 3;
      } else if (windowWidth > 576) {
         return 2;
      } else {
         return 1;
      }
   };
   useEffect(() => {
      ProductDataAccess.getAllCategory()
         .then((data) => {
            setAllCategory(data);
            setTotalProduct(data.length);
         })
         .catch((error) => {});
   }, []);
   
   return (
      <div className="explore-categories">
         <CarouselProvider
            naturalSlideWidth={20}
            naturalSlideHeight={125}
            totalSlides={totalProduct}
            visibleSlides={getVisibleSlides()}
            isIntrinsicHeight={true}
         >
            <Row>
               <Col className="products-row" lg="6">
                  <p className="explore-categories-header">
                     {" "}
                     <LanguageText firstKey="page" secondKey="explore_categories" />{" "}
                  </p>
               </Col>
               <Col>
                  <div className="slider explore-categories-header">
                     <ButtonBack style={{ background: "transparent", border: "0px" }}>
                        <img src="/images/arrowleft.png" alt="Previous product" />
                     </ButtonBack>
                     &nbsp;
                     <ButtonNext style={{ background: "transparent", border: "0px" }}>
                        <img src="/images/arrowright.png" alt="Next product" />
                     </ButtonNext>
                     &nbsp;
                  </div>
               </Col>
            </Row>

            <Slider>
               {allCategory.map((category, index) => (
                  <Slide index={index}>
                     <div
                        onMouseEnter={() => {
                           setShowCategoryIndex(index);
                        }}
                        onMouseLeave={() => setShowCategoryIndex(-1)}
                     >
                        {showCategoryIndex === index ? (
                           <Card className="explore-slider">
                              <div className="slider-image">
                                 <img src={category.image} className="target-image" alt="category" />
                              </div>
                              <div
                                 className="button"
                                 onMouseEnter={() => {
                                    setSeeAllBtn(true);
                                 }}
                                 onMouseLeave={() => setSeeAllBtn(false)}
                              >
                                 {seeAllBtn ? (
                                    <button className="see-all-btn">
                                       {" "}
                                       See All &nbsp; &nbsp; <img src="/images/arrow-right-white.png" />
                                    </button>
                                 ) : (
                                    <button className="see-all-btn">
                                       {" "}
                                       See All &nbsp; &nbsp; <img src="/images/arrow-right.png" />
                                    </button>
                                 )}
                              </div>
                           </Card>
                        ) : (
                           <Card className="explore-slider">
                              <img src="/images/health.png" className="card-category-image" alt="category" />
                              <Card.Body>
                                 <Card.Title className="card-title"> {category.name} </Card.Title>
                                 <Card.Text className="card-text">
                                    We have two sub-categories like <strong>fruits, vegetables.</strong>
                                 </Card.Text>
                              </Card.Body>
                           </Card>
                        )}
                     </div>
                  </Slide>
               ))}
            </Slider>
         </CarouselProvider>
      </div>
   );
};
export default ExploreCategories;
