/**
 * Created by Atikur Rahman Sabuj on 30/10/2020.
 *
 * @format
 */

import { Cart } from "models/cart";
import { Product } from "models/product";
class cartServices {
   getCartProduct(cart: Cart, products: Array<Product>) {
      let product: Product = undefined;
      for (let index = 0; index < products.length; index++) {
         if (cart.id.toString() === products[index].id.toString()) {
            product = products[index];
         }
      }
      return product;
   }
   isProductOnCart = (productId: string, cart: Array<Cart>) => {
      for (let index = 0; index < cart.length; index++) {
         if (cart[index].id === productId) return true;
      }
      return false;
   };

   getCartQuantity = (productId: string, cart: Array<Cart>) => {
      for (let index = 0; index < cart.length; index++) {
         if (cart[index].id === productId) return cart[index].quantity;
      }
      return 0;
   };

   getProductTotalPrice = (product: Product, quantity: number) => {
      let totalPrice = 0;
      //totalPrice = parseFloat(product.price) * quantity;
      
      if(parseFloat(product.special_price) == 0.0)
      {
         totalPrice = parseFloat(product.price) * quantity;
         return totalPrice;
      }
      else{
         totalPrice = parseFloat(product.special_price) * quantity;
         return totalPrice;
      }
   };

   getTotalPrice = (products: Array<Product>, cart: Array<Cart>) => {
      cart.sort((a, b)=> (a.id > b.id) ? 1:-1);
      products.sort((a, b) => (a.id > b.id) ? 1:-1);
      
      let totalPrice = 0;
      products.forEach((product, index) => {
         if(cart[index]!==undefined){
            totalPrice += this.getProductTotalPrice(product, cart[index].quantity);
         }
      });
      return totalPrice;
   };

   getTotalWithShippingFee = (total, shippingFee) => {
      return parseInt(total) + parseInt(shippingFee);
   };

   /**
    * Gets user cart from local storage
    */
   getCartFromLS = () => {
      const cartString = localStorage.getItem("cart");
      if (cartString) {
         return JSON.parse(cartString);
      } else {
         return [];
      }
   };
   getCartTotal = (cart: Array<Cart>) => {
      let total = 0;
      cart.forEach((item) => {
         total += item.quantity;
      });
      return total;
   };

   /**
    *  Saves user cart to local storage
    * @param cart cart array
    */
   postCartToLS = (cart = []) => {
      localStorage.setItem("cart", JSON.stringify(cart));
   };
}
export default new cartServices();
