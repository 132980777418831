

import categoryDataAccess from "dataAccess/categoryDataAccess";
import { Dispatch } from "redux";

const typePrefix = "CATEGORY_";

const Types = {
   categoryLoadingStart: typePrefix + "PRODUCTS_LOADING_START",
   categoryLoadingComplete: typePrefix + "PRODUCTS_LOADING_COMPLETE",
   categoryLoadingError: typePrefix + "PRODUCTS_LOADING_ERROR",

   categoryTreeLoadingStart: typePrefix + "CATEGORY_TREE_LOADING_START",
   categoryTreeLoadingComplete: typePrefix + "CATEGORY_TREE_LOADING_COMPLETE",
   categoryTreeLoadingError: typePrefix + "CATEGORY_TREE_LOADING_ERROR",
};

export const actions = {
   getCategory: (categoryId) => (dispatch: Dispatch) => {
      dispatch({ type: Types.categoryLoadingStart });
      categoryDataAccess
         .getCategory(categoryId)
         .then((category) => {
            dispatch({ type: Types.categoryLoadingComplete, payload: category });
         })
         .catch((error) => {
            dispatch({ type: Types.categoryLoadingError, payload: error.message });
         });
   },
   getCategoryTree: () => (dispatch: Dispatch)=>{
      dispatch({ type: Types.categoryTreeLoadingStart });
      categoryDataAccess.getCategoryTreeView()
         .then((data) => {
            data.sort((a, b) => {
               return b.child.length - a.child.length;
            });
            dispatch({ type: Types.categoryTreeLoadingComplete, payload: data });
         })
         .catch((error) => {
            dispatch({ type: Types.categoryTreeLoadingError, payload: error.message });
         });
   },
};

const initialState = {
   category: {},
   categoryLoading: false,
   categoryError: false,
   categoryErrorMessage: "",

   categoryTree: [],
   categoryTreeLoading: false,
   categoryTreeError: false,
   categoryTreeErrorMessage: "",
};

export const reducer = (state: any = initialState, action: any) => {
   switch (action.type) {

      case Types.categoryLoadingStart:
         return { ...state, categoryLoading: true, categoryError: false };

      case Types.categoryLoadingComplete:
         return { ...state, categoryLoading: false, categoryError: false, category: action.payload };

      case Types.categoryLoadingError:
         return { ...state, categoryLoading: false, categoryError: true, categoryErrorMessage: action.payload };
      
      // category tree
      case Types.categoryTreeLoadingStart:
         return { 
            ...state,
            categoryTreeLoading: true,
            categoryTreeError: false 
         };
      case Types.categoryTreeLoadingComplete:
         return { 
            ...state,
            categoryTreeLoading: false,
            categoryTreeError: false,
            categoryTree: [...state.categoryTree, ...action.payload],
         };
      case Types.categoryTreeLoadingError:
         return { 
            ...state,
            categoryTreeLoading: false,
            categoryTreeError: true,
            categoryTreeErrorMessage: action.payload,
         };

      default:
         return state;
   }
};
