import { languages } from "data/language";
import { User } from "models/user";
import { keys } from "common/constants";
export const setLanguage = (language) => {
   localStorage.setItem("language", language);
};

export const getLanguage = () => {
   const language = localStorage.getItem("language");
   if (language === null) {
      return languages.english;
   }
   return language;
};

export const isUserLoggedIn = (user: User) => {
   if (user && user.name) return true;
   return false;
};

export const getAuthHeader = () => {
   return "Bearer " + localStorage.getItem(keys.accessTokenKey);
};
