export const dateToString = (date: string) => {
   const _date = date ? new Date(date) : new Date();
   const day = _date.getDate();
   const month = _date.getMonth() + 1;
   const year = _date.getFullYear();
   const dateString = year + "/" + month + "/" + day;
   return dateString;
};
export const dateToTimeString = (date: string) => {
   const _date = date ? new Date(date) : new Date();
   const hour = _date.getHours();
   const minute = _date.getMinutes();
   return hour + ":" + minute;
};
export const stringToDate = (dateString: string) => {
   if (dateString === null || dateString === undefined || dateString.length < 1) {
      return new Date();
   }
   let date = new Date();
   let [day, month, year] = dateString.split(" ");
   date.setDate(parseInt(day));

   date.setMonth(getMonthIntFromString(month));
   date.setFullYear(parseInt(year));
   return date;
};

export const getMonthIntFromString = (monthString) => {
   switch (monthString) {
      case "Jan":
         return 0;
      case "Feb":
         return 1;
      case "Mar":
         return 2;
      case "Apr":
         return 3;
      case "May":
         return 4;
      case "Jun":
         return 5;
      case "Jul":
         return 6;
      case "Aug":
         return 7;
      case "Sep":
         return 8;
      case "Oct":
         return 9;
      case "Nov":
         return 10;
      case "Dec":
         return 11;

      default:
         return 0;
   }
};
