import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Axios from "axios";
import { error } from "console";

export default function PaymentGateway() {
   let postdata = [{ a: "a" }, { b: "b" }, { c: 123 }];

   const onCompleteOrder = () => {
      Axios.post("http://localhost:3001/payment", {})
         .then((response) => {
            window.location.href = response.data.data;
         })
         .catch((error) => {});
   };
   const payButtonProps = {
      token: "my-token",
      postdata: JSON.stringify(postdata),
      order: "123",
      endpoint: "http://localhost:3001/payment",
   };
   return (
      <div className="user-profile-top">
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col className="sideNav">
                  <Row className="payment-gateway">
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="first-section">
                        <div>
                           <span className="header"> Payment Gateway </span>
                        </div>
                     </Col>
                     <hr style={{ width: "100%" }} />
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="second-section">
                        <div></div>
                     </Col>
                     <button className="your-button-class" id="sslczPayBtn" {...payButtonProps}>
                        Pay Now
                     </button>
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="third-section">
                        <Button
                           variant="primary"
                           onClick={() => {
                              onCompleteOrder();
                           }}
                           className="payment-done"
                        >
                           <span className="btn-text"> Done Payment </span>
                        </Button>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </div>
      </div>
   );
}
