import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import AccountMenu from "components/menus/account";
import LangMenu from "components/menus/lang";
import LanguageText from "components/common/languageText";
import AllProductMenu from "components/menus/allProducts";
import { actions as favoriteActions } from "@redux/favoriteRedux";
import { isUserLoggedIn } from "services/userServices";

const Header = (props: any) => {
   const [showAccountMenu, setShowAccountMenu] = useState(false);
   const [showLangMenu, setShowLangMenu] = useState(false);
   const [showProduct, setShowProduct] = useState(false);
   const [searchText, setSearchText] = useState("");

   const search = useCallback(() => {
      if (searchText.length > 0) props.history.push("/search/" + searchText);
   }, [props.history, searchText]);

   const toggleAllProductDropdown=()=>{
      if(showProduct==false){
         setShowProduct(true);
      }else{
         setShowProduct(false);
      }
   };

   return (
      <header className="header-container">
         <Navbar bg="white" expand="lg">
            <Navbar.Brand>
               <Link className="main-app-name" to="/">
                  <img style={{ height: "36px" }} alt="Grocery" src="/logo.png" />
               </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="all-product-nav-menu" />
            <Navbar.Collapse id="all-product-nav-menu">
               <Nav className="mr-auto">
                  <Nav.Link href="#">
 
                     <div className="all-products-container">
                        <span
                           onClick={toggleAllProductDropdown}
                        >
                           <LanguageText firstKey="header" secondKey="allProducts" />
                           <span className="icon-container">
                              <img
                                 style={{ height: "4px" }}
                                 alt="bottom_arrow"
                                 src="/images/bottom_arrow.png"
                              />
                           </span>
                        </span>
                        
                        <div className="nav-all-products-btn">
                           {showProduct && (
                              <AllProductMenu 
                                 hideMenu={() => {
                                    setShowProduct(false);
                                 }}
                              />
                           )}
                        </div>
                     </div>

                  </Nav.Link>
                  <div
                     style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
                  >
                     <div className="search-container">
                        <input
                           type="text"
                           className="search-input"
                           value={searchText}
                           onChange={(event) => {
                              setSearchText(event.target.value);
                           }}
                           onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                 search();
                              }
                           }}
                           placeholder="Search your needs..."
                        />
                        <img
                           onClick={search}
                           style={{ height: "28px" }}
                           alt="search button"
                           src="/images/search_with_back.png"
                        />
                     </div>
                  </div>
               </Nav>
               <div className="right-button-container">
                  <div className="row clear-margin">
                     <div className="lang_container">
                        <span
                           style={{ position: "relative" }}
                           onClick={() => {
                              setShowLangMenu(!showLangMenu);
                           }}
                        >
                           {" "}
                           <LanguageText firstKey="header" secondKey="englishBengali" />
                           <span className="icon-container">
                              <img style={{ height: "4px" }} alt="bottom_arrow" src="/images/bottom_arrow_black.png" />
                           </span>{" "}
                        </span>
                        {/*
                        {showLangMenu && (
                           <LangMenu
                              hideMenu={() => {
                                 setShowLangMenu(false);
                              }}
                           />
                        )}
                        */}
                     </div>
                     <div className="header_icon_container">
                        <span className="main-icon">
                           <Link to="/Favorite">
                              <img style={{ height: "20px" }} alt="bottom_arrow" src="/images/heart.png" />
                              <span className="total">{props.favorite.favorite.length}</span>
                           </Link>
                        </span>
                     </div>
                     <div className="header_icon_container">
                        <span className="main-icon">
                           <Link to="/shopping-cart">
                              {" "}
                              <img style={{ height: "20px" }} alt="bottom_arrow" src="/images/shopping-cart.png" />
                              <span className="total">{props.cart.cart.length}</span>
                           </Link>
                        </span>
                     </div>
                     <div className="user-container">
                        <span
                           className="icons"
                           onClick={() => {
                              setShowAccountMenu(!showAccountMenu);
                           }}
                        >
                           <img style={{ marginLeft:"15px", marginRight:"15px", }} alt="bottom_arrow" src="/images/menu.png" className="header-menu-sign" />
                           {  
                              isUserLoggedIn(props.user.user) && props.user.user.image !== "" ? (
                                 <img style={{ height:"40px", width:"40px", borderRadius:"25px", margin:"2px" }} alt="user" src={props.user.user.image} />
                              ) : (
                                 <img style={{ height: "30px", borderRadius:"25px", margin:"4px 10px" }} alt="user" src="/images/user.png" />
                              )
                           }
                        </span>
                        {showAccountMenu && (
                           <AccountMenu
                              hideMenu={() => {
                                 setShowAccountMenu(false);
                              }}
                              isUserLoggedIn={isUserLoggedIn(props.user.user)}
                           />
                        )}
                     </div>
                  </div>
               </div>
            
            </Navbar.Collapse>
         </Navbar>
      </header>
   );
};

const mapStateToProps = ({ cart, user, favorite }) => ({ cart, user, favorite });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
