import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { actions } from "@redux/testRedux";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "components/sidebar/sidebar";
import WhatWeHaveMore from "components/aboutShopstick/whatWeHaveMore";
import { actions as productActions } from "@redux/productRedux";
import ProductCard from "components/ourproducts/products";
import Categories from "components/explorecategories/exploreCategory";
import SpecialOffers from "components/ourOffers/specialOffers";
import HomePageSpecialOffers from "components/ourOffers/specialOfferHomePage";
import HomePageRegularOffers from "components/ourOffers/regularOfferHomePage";
import HomePageShopStickStore from "containers/home/homePageShopStickStore";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = (props) => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.testAction());
      dispatch(productActions.getHomePageProducts());
   }, [dispatch]);

   

   return (
      <div>
         <Helmet>
            <title> Shopstick | Online Shopping </title>
            <meta name="description" content="Shopstick Home page" />
         </Helmet>
         <Container style={{ maxWidth: "100%", padding: "0", overflow:"hidden" }}>
            <Row className="sidebar-and-banner">
               <Col xl={3} lg={3} md={3} sm={3} xs={12} style={{height:"732px"}}>
                  <Sidebar/>
               </Col>
               <Col xl={9} lg={9} md={9} sm={9} xs={12}>
                  <div className="home-banner">


                     <CarouselProvider 
                     naturalSlideWidth={100} 
                     naturalSlideHeight={125} 
                     totalSlides={4}
                     isPlaying={true}
                     interval={3000}
                     touchEnabled={true}
                     isIntrinsicHeight={true}
                     infinite={true}
                     >
                        <Slider>
                           <Slide index={0}>
                              <Link to="/special-offers-all">
                                 <img src="/images/homebanner/MasterBanner1.jpg" alt="banner" style={{borderRadius:"10px",}} />
                              </Link>
                           </Slide>
                           <Slide index={1}><img src="/images/homebanner/MasterBanner2.png" alt="banner" style={{borderRadius:"10px",}} /></Slide>
                           <Slide index={2}><img src="/images/homebanner/MasterBanner3.png" alt="banner" style={{borderRadius:"10px",}} /></Slide>
                           <Slide index={3}><img src="/images/homebanner/MasterBanner4.jpg" alt="banner" style={{borderRadius:"10px",}} /></Slide>
                        </Slider>
                     </CarouselProvider>


                  </div>
                  <Row className="home-banner-below">
                     <Col xl={6} lg={6} md={6} sm={12} xs={12} >
                        <img src="/images/homebanner/first.png" style={{maxWidth:"100%", }} />
                     </Col>
                     <Col xl={6} lg={6} md={6} sm={12} xs={12} className="home-banner-below-second-image">
                        <img src="/images/homebanner/second.png" style={{maxWidth:"100%", }} />
                     </Col>
                  </Row>
               </Col>
            </Row>
            
            <HomePageSpecialOffers/>
            <HomePageRegularOffers/>
            <HomePageShopStickStore/>
            <WhatWeHaveMore/>
            <Categories/>

         </Container>
      </div>
   );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
