import React, { useState } from "react";
import {Link} from "react-router-dom";

interface Props {
   to: string;
   imageSrc: string;
   imageSrcOnHover: string;
   alt: string;
   name: string;
}

export default function MenuItem(props: Props) {
   const [mEntered, setmEntered] = useState(false);
   return (
      <Link to={props.to}>
         <div 
            onMouseEnter={() => {
               setmEntered(true);
            }}
            onMouseLeave={() => {
               setmEntered(false);
            }} className="item" >

            {mEntered ? <img src={props.imageSrcOnHover} alt={props.alt} /> : <img src={props.imageSrc} alt={props.alt} />} 
            <span>{props.name}</span>
         </div>
      </Link>
   );
}
