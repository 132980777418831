import Axios, { AxiosError, AxiosResponse } from "axios";
import { User } from "models/user";
import { getAuthHeader } from "services/userServices";
import { convertObjectToFormData } from "services/util";
import { linkAddCart, linkAddAllToCart, linkGetCart, linkRemoveCart, linkUpdateCart } from "./apiEndpoints";
class cartDataAccess {
   getCart = () => {
      return Axios.get(linkGetCart, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               let total = 0;
               let cart = response.data.data.map((item) => {
                  total = total + item.quantity;
                  return { id: item.product_id, quantity: item.quantity };
               });
               return { cart, total };
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   addToCart = (product_id, quantity = 1) => {
      const _data = convertObjectToFormData({ product_id, quantity });
      return Axios.post(linkAddCart, _data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               let total = 0;
               let cart = response.data.data.map((item) => {
                  total = total + item.quantity;
                  return { id: item.product_id, quantity: item.quantity };
               });
               return { cart, total };
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   addAllToCart = (products) => {
      let _formData = new FormData();
      products.forEach((element) => {
         _formData.append("product_id[]", element.id);
         _formData.append("quantity[]", element.quantity);
      });
      return Axios.post(linkAddAllToCart, _formData, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               let total = 0;
               let cart = response.data.data.map((item) => {
                  total = total + item.quantity;
                  return { id: item.product_id, quantity: item.quantity };
               });
               return { cart, total };
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   updateCart = (product_id, quantity = 1) => {
      if (quantity !== 0) {
         const _data = convertObjectToFormData({ product_id, quantity });
         return Axios.post(linkUpdateCart, _data, {
            headers: {
               Authorization: getAuthHeader(),
            },
         })
            .then((response: AxiosResponse) => {
               if (response.data.success) {
                  let total = 0;
                  let cart = response.data.data.map((item) => {
                     total = total + item.quantity;
                     return { id: item.product_id, quantity: item.quantity };
                  });
                  return { cart, total };
               } else {
                  throw new Error(response.data.message);
               }
            })
            .catch((error: AxiosError) => {
               throw new Error(error.message);
            });
      } else {
         const _data = convertObjectToFormData({ product_id });
         return Axios.post(linkRemoveCart, _data, {
            headers: {
               Authorization: getAuthHeader(),
            },
         })
            .then((response: AxiosResponse) => {
               if (response.data.success) {
                  let total = 0;
                  let cart = response.data.data.map((item) => {
                     total = total + item.quantity;
                     return { id: item.product_id, quantity: item.quantity };
                  });
                  return { cart, total };
               } else {
                  throw new Error(response.data.message);
               }
            })
            .catch((error: AxiosError) => {
               throw new Error(error.message);
            });
      }
   };
}

export default new cartDataAccess();
