import Axios, { AxiosError, AxiosResponse } from "axios";
import { convertObjectToFormData } from "services/util";
import { getAuthHeader } from "services/userServices";
import { linkAddReviewToProduct, getUserReviewProductsAll } from "./apiEndpoints";

class reviewDA {

   addReviewToProduct = (ID, Rating, Description, orderDetailsID) => {
      const _data = convertObjectToFormData({ product_id: ID, rating: Rating, description: Description, order_details_id: orderDetailsID });
      return Axios.post(linkAddReviewToProduct, _data, {
         headers: { Authorization: getAuthHeader(), }, 
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

     
   getUserReviewProductsAll = () => {
      return Axios.get(getUserReviewProductsAll, {
         headers: { Authorization: getAuthHeader(), }, 
      })
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

}
export default new reviewDA();
