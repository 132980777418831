/**
 *
 *
 * @format
 */

import { Favorite } from "models/favorite";
import { Product } from "models/product";

class favoriteServices {
   isProductOnFavorite = (productId: string, favorite: Array<Favorite>) => {
      for (let index = 0; index < favorite.length; index++) {
         if (favorite[index].id === productId) return true;
      }
      return false;
   };

   getFavoriteQuantity = (productId: string, Favorite: Array<Favorite>) => {
      for (let index = 0; index < Favorite.length; index++) {
         if (Favorite[index].id === productId) return Favorite[index].quantity;
      }
      return 0;
   };

   /**
    * Gets user Favorite from local storage
    */
   getFavorite = () => {
      const FavoriteString = localStorage.getItem("Favorite");
      if (FavoriteString) {
         return JSON.parse(FavoriteString);
      } else {
         return [];
      }
   };

   getFavoriteProduct(favorite: Favorite, products: Array<Product>) {
      let product: Product = {};
      for (let index = 0; index < products.length; index++) {
         if (favorite.id.toString() === products[index].id.toString()) {
            product = products[index];
         }
      }
      return product;
   }
   postFavorite = (Favorite = []) => {
      localStorage.setItem("Favorite", JSON.stringify(Favorite));
   };
}
export default new favoriteServices();
