import Axios, { AxiosError, AxiosResponse } from "axios";
import { linkGetCategory, linkGetCategoryTreeView } from "./apiEndpoints";

class categoryDataAccess {
   getCategoryTreeView = () => {
      return Axios.get(linkGetCategoryTreeView)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
   getCategory = (categoryId) => {
      return Axios.get(linkGetCategory + categoryId)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
}
export default new categoryDataAccess();
