import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import categoryDataAccess from "dataAccess/categoryDataAccess";
import { connect, useDispatch } from "react-redux";
import SpinnerLocal from "components/spinner";
import { actions as categoryActions } from "@redux/categoryRedux";

const NavbarAllProductDropdown=(props: any) => {
   const dispatch = useDispatch();
   const menuRef = useRef(null);
   const [isDropdownOpen, setIsDropdownOpen] = useState(true);
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleClickOutside = (event: Event) => {
         if (menuRef.current && !menuRef.current.contains(event.target)) {
            props.hideMenu();
         }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [props.hideMenu]);

   const getVisibleSlides = () => {
      if (windowWidth > 992) {
         return 5;
      } else if (windowWidth > 768) {
         return 4;
      } else if (windowWidth > 576) {
         return 2;
      } else {
         return 1;
      }
   };

   useEffect(()=>{
      if(props.category.categoryTree.length==0){
         dispatch(categoryActions.getCategoryTree());
      }
   },[]);

   if(props.category.categoryTreeLoading===true)
   {
      return <SpinnerLocal />;
   }
   return (
      <div ref={menuRef}>
         { isDropdownOpen==true &&
            <div className="all-products-dropdown">
               
               <CarouselProvider
                  naturalSlideWidth={500}
                  naturalSlideHeight={140}
                  totalSlides={props.category.categoryTree.length}
                  visibleSlides={getVisibleSlides()}
                  isIntrinsicHeight={true}
                  className="products-slider"
               >
                  <Slider style={{ margin: "0px -15px" }} className="sliders">
                     {props.category.categoryTree.map((category, index) => (
                        <Slide index={index} className="slider-item">
                           <div>
                              <Link to={"/our-products/" + category.slug} onClick={()=>{setIsDropdownOpen(false)}}>
                                 <div className="parent-category">
                                    {category.type !== "secondary_child" && (
                                       <>
                                          <div className={"dropdown-header"}>
                                             <FontAwesomeIcon icon={category.icon ? category.icon : "beer"} color="white" />
                                          </div>
                                             <div className="title">
                                                <span> {category.name} </span>
                                             </div>
                                       </>
                                    )}
                                 </div>
                              </Link>
                              <hr />
                              <div className="sub-category" onClick={()=>{setIsDropdownOpen(false)}}>
                                 {category.child &&
                                    category.child.length > 0 &&
                                    category.child.slice(0,7).map((child, index) => (
                                       <Link to={"/our-products/" + child.slug} className="item" key={index} >
                                          <p>{child.name}</p>
                                       </Link>
                                    ))}
                              </div>
                           </div>
                        </Slide>
                     ))}
                  </Slider>
                  <div className="prev-next">
                     <ButtonBack style={{ background: "transparent", border: "0px" }}>
                        <img src="/images/arrowleft.png" alt="Previous product" />
                     </ButtonBack>
                     <ButtonNext style={{ background: "transparent", border: "0px" }}>
                        <img src="/images/arrowright.png" alt="Next product" />
                     </ButtonNext>
                  </div>
               </CarouselProvider>

               <div className="our-offer-btn">
                  <div className="our-offer-in-header-menu">
                     <Link to={"/special-offers-all"}>
                        <div className="offer-btn" onClick={()=>{setIsDropdownOpen(false)}}>
                           <div>
                              <img src="/images/giftyellow.png" alt="shopstick" />
                           </div>
                           <div className="offer-text">
                              <span> Special Offers </span>
                           </div>
                        </div>
                     </Link>
                  </div>
               </div>
               
            </div>
         }
      </div>
   );
};
const mapStateToProps = ({ category }) => ({
   category,
});
export default connect(mapStateToProps)(NavbarAllProductDropdown);