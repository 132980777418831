export const languages = {
   bengali: "bengali",
   english: "english",
};

const languageValues = {
   header: {
      allProducts: {
         english: "All Products",
         bengali: "সব পণ্য",
      },
      englishBengali: {
         english: "English",
         bengali: "বাংলা",
      },
   },
   footer: {
      myProfile: {
         english: "My Profile",
         bengali: "প্রোফাইল",
      },
      aboutShopstick: {
         english: "About Shopstick",
         bengali: "শপস্টিক",
      },
      haveAlook: {
         english: "Have a look",
         bengali: "একবার দেখুন",
      },
      contactInfo: {
         english: "Contact info",
         bengali: "যোগাযোগ",
      },
   },
   page: {
      whatWeHaveMore: {
         english: "What we have more!",
         bengali: "আমাদের যা আছে",
      },
      ShopStick_Mall: {
         english: "ShopStick Store",
         bengali: "শপস্টিক মল",
      },
      shopstick_provides_you_more_than: {
         english: "Shopstick provides you more than 1000+ products from each category. You can choose products from here as well as filter also.",
         bengali: "শপস্টিক প্রতিটি ক্যাটাগরি থেকে 1000+ এরও বেশি পণ্য সরবরাহ করে। আপনি এখান থেকে ফিল্টার করে পণ্য ক্রয় করতে পারেন।", 
      },
      see_all: {
         english: "See All",
         bengali: "আরো দেখুন",
      },
      explore_categories: {
         english: "Explore Categories",
         bengali: "ভিজিট ক্যাটাগরি",
      },
      ourOffers: {
         english: "Our offers",
         bengali: "আমাদের অফার",
      },
      Add_To_Cart: {
         english: "Add To Cart",
         bengali: "কার্টে যোগ করুন",
      },
      Details: {
         english: "Details",
         bengali: "বিস্তারিত",
      },
   },
};
export default languageValues;
