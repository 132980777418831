import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { actions as cartActions } from "@redux/cartRedux";
import { actions as favoriteActions } from "@redux/favoriteRedux";
import SpinnerLocal from "components/spinner";
import cartServices from "services/cartServices";
import favoriteServices from "services/favouriteServices";
import { Cart } from "models/cart";
import { isUserLoggedIn } from "services/userServices";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const MyProfileShoppingCart = (props) => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(cartActions.getCartProducts(props.cart.cart));
   }, [dispatch, props.cart.cart]);


   /*
   if(props.cart.productsLoading==true)
   {
      return <SpinnerLocal />;
   } */

   
   if (props.cart.productsError) {
      return <div>Something went wrong</div>;
   }
   return (
      <div className="user-profile-top">
         <Helmet>
            <title> Shopstick | Cart </title>
            <meta name="description" content="My Shopping Cart"/>
         </Helmet>
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col className="sideNav">
                  <Row className="shopping-cart-container">
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="first-section">
                        <div>
                           <span className="header"> Shopping Cart </span>
                        </div>
                     </Col>
                     <hr style={{ width: "100%" }} />
                     {props.cart.products && props.cart.products.length > 0 ? (
                        props.cart.cart.map((cart: Cart, index) => {
                           const product = cartServices.getCartProduct(cart, props.cart.products);
                           if(!product){
                              return null;
                           }
                           return (
                              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="second-section" key={product.id}>
                                 <Row>
                                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                       <Row>
                                          <Col>
                                             <img src={product.image1} alt="shopstick" className="left-img-circle" />
                                          </Col>
                                          <Col>
                                             <h4 style={{fontSize:"12px", }}>{product.name}</h4>
                                             
                                             {parseFloat(product.special_price)>0 && 
                                                <div>
                                                   <span style={{color:"#40bfff"}}> {product.special_price} Tk </span> &nbsp;
                                                   <del className="price" style={{color:"red"}}> {product.price} Tk </del>
                                                </div>
                                             }
                                             {parseFloat(product.special_price)==0 && 
                                                <span className="price" style={{color:"#40bfff"}}> {product.price} Tk </span>
                                             }
                                             
                                          </Col>
                                       </Row>
                                    </Col>
                                    <Col xl={3} lg={3} md={3} sm={12} xs={12} className="cancel-and-favorite mobile-view-shopping-cart">
                                       <img
                                          onClick={() => {
                                             dispatch(
                                                cartActions.removeFromCart(cart.id, isUserLoggedIn(props.user.user))
                                             );
                                          }}
                                          src="images/cross2.png"
                                          alt="shopstick"
                                          className="cancel-product"
                                       />{" "}
                                       &nbsp; &nbsp;
                                       {favoriteServices.isProductOnFavorite(product.id, props.favorite.favorite) ? (
                                          <img
                                             onClick={() => {
                                                dispatch(
                                                   favoriteActions.removeProductFromFavorite(
                                                      product.id,
                                                      isUserLoggedIn(props.user.user)
                                                   )
                                                );
                                             }}
                                             width="24px"
                                             height="20px"
                                             src="/images/heartyelo.png"
                                             alt="shopstick"
                                          />
                                       ) : (
                                          <img
                                             onClick={() => {
                                                dispatch(
                                                   favoriteActions.addProductToFavorite(
                                                      product.id,
                                                      isUserLoggedIn(props.user.user)
                                                   )
                                                );
                                             }}
                                             width="24px"
                                             height="20px"
                                             src="/images/heart.png"
                                             alt="shopstick"
                                          />
                                       )}
                                    </Col>
                                    <Col xl={3} lg={3} md={3} sm={12} xs={12} className="cart-button-plus mobile-view-shopping-cart">
                                       <img
                                          onClick={() => {
                                             dispatch(
                                                cartActions.decreaseProductQuantity(
                                                   cart.id,
                                                   cart.quantity,
                                                   isUserLoggedIn(props.user.user)
                                                )
                                             );
                                          }}
                                          src="images/MinusCart.png"
                                          alt="shopstick"
                                       />
                                       <span> &nbsp; {cart.quantity} &nbsp; </span>
                                       <img
                                          onClick={() => {
                                             cartServices.getCartQuantity(product.id, props.cart.cart) === product.quantity ? (
                                                toast.error("stock out")
                                                ) : (
                                                dispatch(
                                                   cartActions.increaseProductQuantity(
                                                      cart.id,
                                                      cart.quantity,
                                                      isUserLoggedIn(props.user.user)
                                                   )
                                                )
                                             )
                                          }}
                                          src="images/AddCart.png"
                                          alt="shopstick"
                                       />
                                    </Col>
                                    <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mobile-view-shopping-cart">
                                       <h5 className="total-price">
                                          <strong> 
                                             { parseFloat(product.special_price)==0.0 && <span>{parseFloat(product.price) * cart.quantity} Tk </span> }
                                           </strong>
                                          <strong> 
                                             { parseFloat(product.special_price)>0.0 && <span>{parseFloat(product.special_price) * cart.quantity} Tk </span> }
                                           </strong>
                                       </h5>
                                    </Col>
                                 </Row>
                                 <br/>
                              </Col>
                           );
                        })
                     ) : (
                        <div className="no-product-on-cart"> <span> No products on cart </span> </div>
                     )}
                     <hr style={{ width: "96%" }} />

                     {/*
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="third-section">
                        <span className="sub-total"> Sub Total: </span>
                        <span className="sub-total-price">
                           {" "}
                           {props.cart.products && props.cart.cart
                              ? cartServices.getTotalPrice(props.cart.products, props.cart.cart)
                              : 0}{" "}
                           tk{" "}
                        </span>
                     </Col> */}
                     
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="five-section">
                        <span className="net-total"> Sub Total: </span>
                        <span className="net-price">
                           <h4>
                              <strong>
                                 {props.cart.products && props.cart.cart
                                    ? cartServices.getTotalPrice(props.cart.products, props.cart.cart)
                                    : 0}
                                 &nbsp;Tk
                              </strong>
                           </h4>
                        </span>
                     </Col> 
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="six-section">
                        <Button
                           onClick={() => {
                              props.history.push("/");
                           }}
                           className="continue-shopping-btn"
                        >
                           <img src="images/arrow-rightt.png" alt="shopstick" />
                           <span className="btn-text"> Continue Shopping </span>
                        </Button>
                        <Link to="/checkout">
                           <Button variant="primary" className="checkout">
                              <span className="btn-text"> Checkout </span>
                           </Button>
                        </Link>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </div>
      </div>
   );
};
const mapStateToProps = ({ cart, user, favorite }) => ({
   cart,
   user,
   favorite,
});

export default connect(mapStateToProps)(MyProfileShoppingCart);