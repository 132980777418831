import React, { useState, useRef, useEffect } from "react";
import { Col, Row, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import userDataAccess from "dataAccess/userDataAccess";
import { actions as userActions } from "@redux/userRedux";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import ImageUpload from 'image-upload-react'
import 'image-upload-react/dist/index.css'

const ProfileSidebar = (props: any) => {
   const dispatch = useDispatch();
   const [upload, setUpload] = useState(false);
   const [selectedFile, setSelectedFile] = useState(null);
   const [user, setUser] = useState(null);
   const [showCamera, setShowCamera] = useState(false);

   const uploadImage = () => {
      const formData = new FormData();
      formData.append("image", selectedFile);

      return userDataAccess.updateAvatar(formData)
         .then((data) => {
            toast.success("Avatar Updated successfully");
            //dispatch(userActions.getUserDetails());
            userDataAccess.getUserDetails()
               .then((data) => {
                  setUser(data);
               })
               .catch((error) => { });
               })
         .catch((error) => {
            toast.error("Avatar Not Updated");
         });
    };

    const loadImageFile = (event) => {
      setSelectedFile(event.target.files[0]);
      console.log("selected file = ", selectedFile);
    };

    useEffect(()=>{
       userDataAccess.getUserDetails()
         .then((data) => {
            setUser(data);
         })
         .catch((error) => { });
    },[]);
   
  if(user===null)
  {
     return <p> Loading </p>
  }
   return (
      <div className="sideNav-container">
         <ListGroup>
            <Row>
               <Col>
                  <div className="profile-image">
                     <div className="avatar-image-area" 
                        onMouseEnter={()=>{setShowCamera(true)}}
                        onMouseLeave={()=>{setShowCamera(false)}}
                     >
                        {user.image=="" ? (
                              <img 
                              src="/images/user.png" 
                              alt="avatar" 
                              className="user-image" 
                              style={{width: "40px", height: "40px", borderRadius: "40px"}} 
                              />
                           ): (
                              <img 
                              src={user.image} 
                              alt="avatar" 
                              className="user-image" 
                              style={{width: "100px", height: "100px", borderRadius: "100px"}}
                              />
                        )}
                        {showCamera && (
                           <div className="camera-area">
                              <img src="/images/camera.png" onClick={()=>{setUpload(true);}} />
                           </div>
                        )}
                     </div>

                     {upload && (
                        <div className="avatar-image-upload">
                           <div className="upload-cancel">
                              <Row>
                                 <label htmlFor="file" className="choose-file"> Upload
                                    <input type="file" onChange={loadImageFile} name="file" id="file" className="browse-btn" style={{visibility:"hidden"}}/>
                                 </label>
                              </Row>
                              <Row>
                                 <button type="button" onClick={() => { setUpload(false); }} className="cancel"> Cancel </button>
                                 {selectedFile!=null ? (
                                    <button type="submit" onClick={uploadImage} className="update" > Update </button>
                                 ) : (
                                    <button type="submit" onClick={uploadImage} className="update-disabled" disabled > Update </button>
                                 )}
                              </Row>
                           </div>
                        </div>
                     )}
                     
                     <div className="user-details">
                        <span className="user-name">{user.name}</span> <br/>
                        <span className="user-phone">{user.phone_number}</span>
                     </div>
                  </div>
               </Col>
            </Row>

            <ListGroup.Item className="side-menu-item">
               <img src="/images/IconBook.png" className="profile-sidebar-icon" alt="shopStick" />
               <Link to="/my-profile"><span className="profile-sidebar-item-name">Personal Information</span></Link>
            </ListGroup.Item>
            <ListGroup.Item className="side-menu-item">
               <img src="/images/lock.png" className="profile-sidebar-icon" alt="shopStick" />
               <Link to="/change-password"><span className="profile-sidebar-item-name">Change Password</span></Link>
            </ListGroup.Item>
            <ListGroup.Item className="side-menu-item">
               <img src="/images/order.png" className="profile-sidebar-icon" alt="shopStick" />
               <Link to="/orders"><span className="profile-sidebar-item-name">Orders</span></Link>
            </ListGroup.Item> 
            <ListGroup.Item className="side-menu-item">
               <img src="/images/IconStar.png" className="profile-sidebar-icon" alt="shopStick" />
               <Link to="/user-reviews-all"><span className="profile-sidebar-item-name">Reviews</span></Link>
            </ListGroup.Item> 
            <ListGroup.Item className="side-menu-item">
               <img src="/images/IconCoupons.png" className="profile-sidebar-icon" alt="shopStick" />
               <Link to="/coupons"><span className="profile-sidebar-item-name">Coupons</span></Link>
            </ListGroup.Item>
            <ListGroup.Item className="side-menu-item">
               <img src="/images/IconLocation.png" className="profile-sidebar-icon" alt="shopStick" />
               <Link to="/address"><span className="profile-sidebar-item-name">Addresses</span></Link>
            </ListGroup.Item>
         </ListGroup>
      </div>
   );
};

const mapStateToProps = ({ user }) => ({
   user,
});

export default connect(mapStateToProps)(ProfileSidebar);
