import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import reduxPromise from "redux-promise";
import reduxThunk from "redux-thunk";
import reducers from "@redux";
import Router from "./router";
import ScrollToTop from "hoc/scrollToTop";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
library.add(fab, far, fas);

const createStoreWithMiddleware = applyMiddleware(reduxPromise, reduxThunk)(createStore);
const store = createStoreWithMiddleware(
   reducers
);
ReactDOM.render(
   <Provider store={store}>
      <BrowserRouter>
         <ScrollToTop>
            <Router />
         </ScrollToTop>
      </BrowserRouter>
   </Provider>,
   document.getElementById("root")
);
