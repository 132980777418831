import React from "react";
import DatePicker from "react-datepicker";
interface Props {
   value: Date | string;
   onChange: Function;
}
const CustomDatePicker = (props: Props) => {
   return (
      <DatePicker
         selected={props.value}
         onChange={props.onChange}
         placeholderText="Click to select a date"
         peekNextMonth
         showMonthDropdown
         showYearDropdown
         dropdownMode="select"
         dateFormat="d/MM/yyyy"
         className="label form-control col-sm-12"
      />
   );
};
export default CustomDatePicker;
