import React, { Component } from "react";
import { connect, DispatchProp } from "react-redux";
import { actions as userActions } from "@redux/userRedux";
import { actions as cartActions } from "@redux/cartRedux";
import SpinnerLocal from "components/spinner";
import { toast } from "react-toastify";
export default function (TargetComponent, isLoginRegister: boolean, isOpen: boolean = false) {
   interface Props extends DispatchProp {
      user: any;
   }

   class Auth extends Component<any> {
      state = { isDispatched: false };
      componentDidMount() {
         if (this.props.location.pathname === "/logout") {
            this.props.dispatch(userActions.logout());
            toast.success("Logged out");
            this.props.history.push("/");
         } else {
            this.props.dispatch(userActions.auth(this.props.user));
            this.setState({ isDispatched: true });
         }
      }

      static getDerivedStateFromProps(props, state) {
         if (!props.user.authLoading && props.user.user.name && isLoginRegister) {
            props.history.push("/");
            return {};
         }
         if (state.isDispatched && !props.user.authLoading && !props.user.user.name && !isOpen && !isLoginRegister) {
            props.history.push("/login");
            return {};
         }

         return {};
      }

      render() {
         if (!this.state.isDispatched) {
            return <SpinnerLocal />;
         }
         if (this.props.user.authLoading && !isOpen) {
            return <SpinnerLocal />;
         }
         return <TargetComponent {...this.props} user={this.props.user} />;
      }
   }

   const mapStateToProps = ({ user }) => ({
      user,
   });

   return connect(mapStateToProps)(Auth);
}
