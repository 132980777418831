/**
 * Created by Atikur Rahman Sabuj on 02/08/2020.
 *
 * @format
 */
import { keys } from "common/constants";
import userDataAccess from "dataAccess/userDataAccess";
import { User } from "models/user";
import { dateToString, stringToDate } from "services/dateTimeService";
import { getLanguage, setLanguage } from "services/userServices";
import { actions as cartActions } from "./cartRedux";
const typesPrefix = "USER_";
const types = {
   LOGOUT: typesPrefix + "LOGOUT",
   LOGIN: typesPrefix + "LOGIN",
   authLoading: typesPrefix + "AUTH_LOADING",
   AUTH: typesPrefix + "AUTH",
   REFRESH_USER: typesPrefix + "REFRESH_USER",
   setLanguage: typesPrefix + "SET_LANGUAGE",
   addAddress: typesPrefix + "ADD_ADDRESS",
   userLoading: typesPrefix + "USER_LOADING",
   userLoadingComplete: typesPrefix + "USER_LOADING_COMPLETE",
   userLoadingError: typesPrefix + "USER_LOADING_ERROR",
};

export const actions = {
   auth: (oldUser) => (dispatch) => {
      dispatch({ type: types.authLoading });
      userDataAccess
         .auth()
         .then((data) => {
            let _data = data;
            _data.dob = stringToDate(data.dob);
            dispatch({ type: types.AUTH, payload: _data });
            //loading the user cart if user is logged in and page loading is first time
            if (oldUser.isFirstTimeLoad && data.name) {
               dispatch(cartActions.getCartFromServer());
            }
         })
         .catch((error) => {
            dispatch({ type: types.AUTH, payload: {} });
         });
   },
   setUser: (user) => (dispatch) => {
      let _data = user;
      dispatch({ type: types.AUTH, payload: _data });
   },
   login: (user: User) => (dispatch) => {
      localStorage.setItem(keys.accessTokenKey, user.access_token);
      dispatch(cartActions.getCartFromServer());
      dispatch({ type: types.LOGIN, payload: user });
   },
   logout: () => (dispatch) => {
      localStorage.setItem(keys.accessTokenKey, "");
      dispatch(cartActions.getCartFromLocalStorage());
      dispatch({ type: types.LOGOUT });
   },
   refreshUser: (user: User) => {
      return { type: types.REFRESH_USER, payload: user };
   },
   setLanguage: (language: string) => {
      setLanguage(language);
      return { type: types.setLanguage, payload: language };
   },
   addAddress: (data: string) => (dispatch) => {
      userDataAccess
         .addAddress(data)
         .then((data) => {
            dispatch({ type: types.addAddress, payload: data });
         })
         .catch((error) => {});
   },
   getUserDetails: () => (dispatch) => {
      dispatch({ type: types.userLoading });
      userDataAccess
      .getUserDetails()
         .then((data) => {
            dispatch({ type: types.userLoadingComplete, payload: data.data });
         })
         .catch((error) => {
            dispatch({ type: types.userLoadingError, payload: error.message });
         });
   },
};

const initialState = {
   user: {},
   authLoading: false,
   finishIntro: null,
   language: getLanguage(),
   address: [],
   isFirstTimeLoad: true,
   showFilter: true,
   showHeaderAllProducts: true,
   userErrorMessage:"",
};

export const reducer = (state: any = initialState, action: any) => {
   const { type, payload } = action;
   switch (type) {
      case types.LOGOUT:
         return { ...state, user: {} };
      case types.LOGIN:
         return { ...state, user: payload };
      case types.authLoading:
         return { ...state, authLoading: true };
      case types.AUTH:
         return { ...state, user: payload, authLoading: false, isFirstTimeLoad: false };
      case types.REFRESH_USER:
         return { ...state, user: payload };
      case types.setLanguage: {
         return { ...state, language: payload };
      }
      case types.addAddress: {
         return { ...state, address: [...state.address, { id: action.payload }] };
      }
      case types.userLoading: {
         return { ...state, userLoading: true, userLoadingError: false };
      }
      case types.userLoadingComplete: {
         return { ...state, userLoading: false, userLoadingError: false, user: action.payload };
      }
      case types.userLoadingError: {
         return { ...state, userLoading: false, userLoadingError: true, userErrorMessage: action.payload };
      }
      default:
         return state;
   }
};
