import { apiPrefix, apiUrl } from "common/config";

//#region user
export const linkLogin = apiUrl + apiPrefix + "user/login";
export const linkSignUp = apiUrl + apiPrefix + "user/signup";
export const linkOTPResend = apiUrl + apiPrefix + "user/otp-send";
export const linkVerifyOTP = apiUrl + apiPrefix + "user/otp-verify";
export const linkForgotPassword = apiUrl + apiPrefix + "user/otp-forget-password";
export const linkUserResetPassword = apiUrl + apiPrefix + "user/password-reset";
export const linkAuth = apiUrl + apiPrefix + "user/auth";
export const linkUpdateProfile = apiUrl + apiPrefix + "user/updateProfile";
export const linkChangePassword = apiUrl + apiPrefix + "user/changePassword";
export const linkContactUs = apiUrl + apiPrefix + "contact-us";
export const linkAddAddress = apiUrl + apiPrefix + "user/addAddress";
export const linkUpdateAddress = apiUrl + apiPrefix + "user/updateAddress";
export const linkAddressList = apiUrl + apiPrefix + "user/AddressList";
export const linkRemoveAddress = apiUrl + apiPrefix + "user/removeAddress";
export const linkUpdateAvatar = apiUrl + apiPrefix + "user/Profile-pic-upload";
export const linkGetUserDetails = apiUrl + apiPrefix + "user/details";
export const linkGetUserDashboard = apiUrl + apiPrefix + "user/dashboard";
export const linkGetUserAllOrders = apiUrl + apiPrefix + "user/orders";
export const linkGetCoupons = apiUrl + apiPrefix + "couponList";
export const linkGetCityList = apiUrl + apiPrefix + "user/cityList";
export const linkGetUserAreaList = apiUrl + apiPrefix + "user/areaList";
//#endregion

//# region Social Login
export const linkSocialLogin = apiUrl + apiPrefix + "user/social-login";
//# region Social Login End

//#region product
export const linkGetProducts = apiUrl + apiPrefix + "products/getAll";
export const linkGetProductsByCategory = apiUrl + apiPrefix + "productByCategory";
export const linkGetAllCategory = apiUrl + apiPrefix + "product/allCategory";
export const linkGetProductsBySearch = apiUrl + apiPrefix + "product";
export const linkGetProductDetails = apiUrl + apiPrefix + "productDetails";
export const linkGetHomePageProducts = apiUrl + apiPrefix + "product/home";
export const linkGetProductsByIds = apiUrl + apiPrefix + "productsbyIds";
export const linkAddReviewToProduct = apiUrl + apiPrefix + "add/review";
export const getUserReviewProductsAll = apiUrl + apiPrefix + "user/review";
export const getHomePageShopStickProduct = apiUrl + apiPrefix + "home/shopstick-product";
export const linkProductRequestToStock = apiUrl + apiPrefix + "product/addToDemand";
//#endregion

//#region category
export const linkGetCategoryTreeView = apiUrl + apiPrefix + "product/category/treeview";
export const linkGetCategory = apiUrl + apiPrefix + "product/category/";
//#endregion

//#region favorite
export const linkAddFavorite = apiUrl + apiPrefix + "product/addToFavouriteList";
export const linkRemoveFavorite = apiUrl + apiPrefix + "product/removeFromFavouriteList";
export const linkRemoveAllFavorites = apiUrl + apiPrefix + "product/removeAllProductFromFavouriteList";
export const linkGetFavoriteList = apiUrl + apiPrefix + "product/favouriteList";
//#endregion

//#region Cart
export const linkAddCart = apiUrl + apiPrefix + "product/addToCart";
export const linkAddAllToCart = apiUrl + apiPrefix + "product/addAllToCart";
export const linkRemoveCart = apiUrl + apiPrefix + "product/removeFromCart";
export const linkUpdateCart = apiUrl + apiPrefix + "product/updateCart";
export const linkGetCart = apiUrl + apiPrefix + "product/cartList";
//#endregion

//#region Offer
export const linkSpecialOffer = apiUrl + apiPrefix + "specialOffer";
export const linkRegularOffer = apiUrl + apiPrefix + "regularOffer";
//#endregion

//#region Order
export const linkApplyToken = apiUrl + apiPrefix + "user/coupon";
export const linkGetShippingCost = apiUrl + apiPrefix + "user/groceryDeliveryCharge";
export const linkPostOrder = apiUrl + apiPrefix + "place-order";
export const linkPayment = apiUrl + apiPrefix + "pay";
export const linkUserOrderDelete = apiUrl + apiPrefix + "user/order-delete";
//#endregion
