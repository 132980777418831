import React, { useEffect, useState } from "react";
import { Col, Row, Form, Table, Button } from "react-bootstrap";
import ProfileSidebar from "components/profileSidebar/sidebar";
import MyActivity from "components/profileSidebar/activity";
import UserDataAccess from "dataAccess/userDataAccess";
import SpinnerLocal from "components/spinner";
import Rating from "react-rating";
import ReviewDataAccess from "dataAccess/reviewDataAccess";
import ProductDataAccess from "dataAccess/productDataAccess";
import { toast } from "react-toastify";
import OrderDataAccess from "dataAccess/orderDataAccess";
import { Helmet } from "react-helmet";

const UserOrdersDetailPage = (props: any) => {
  const [paymentSectionOpen, setPaymentSectionOpen] = useState(false);
  const [isOnlinePayment, setIsOnlinePayment] = useState(false);
  const [orderIndex, setOrderIndex] = useState(-1);
  const [orders, setOrders] = useState(undefined);
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(5);
  const [ratingProductID, setRatingProductID] = useState("");
  const [isPaymentAble, setIsPaymentAble] = useState(false);

  const toggleOrderDetails = (value) => {
    if(orderIndex==value)
    {
      setOrderIndex(-1);
    }
    else {
      setOrderIndex(value);
    }
  };

  useEffect(() => {
    UserDataAccess.getUserAllOrders()
    .then((data) => {
      setOrders(data);
    })
    .catch((error) => {});
  }, []);

  const userRatingToProduct=(rating, productID)=>{
    setRating(rating);
    setRatingProductID(productID);
  };
  
  const addReview = (productId, rating, reviewText, ordersStatus, orderDetailsID) => {
    if(ordersStatus==4){
      ReviewDataAccess.addReviewToProduct(productId, rating, reviewText, orderDetailsID)
       .then((data)=>{
          toast.success("Review added successfully");
       })
       .catch((error)=>{ 
         toast.error("Review already added");
       });
    }
    else {
      toast.error("Review is only available in Delivered Status");
    }
  };

  const deleteUserOrder=(orderID)=>{
    OrderDataAccess.userOrderDelete(orderID)
      .then((data)=>{
          UserDataAccess.getUserAllOrders()
            .then((data) => {
              setOrders(data);
            })
            .catch((error) => {});
      toast.success("Order deleted Successfully");
    })
    .catch((error)=>{ })
  };
  
  const paymentFromOrderList=(orderID, isOnlinePayment)=>{
    
    OrderDataAccess
      .payment(orderID, isOnlinePayment)
      .then((data) => {
        if(!isOnlinePayment) {
            toast.success("Order completed successfully");
            props.history.push("/orders");
        }
      })
      .catch((_error) => {
        toast.error("Something went wrong, Please try again");
      });
  };

  const checkIsPayAble=(orderCreatedDate)=>{
    
    let temp = orderCreatedDate.slice(8,10);
    let createdDate = parseInt(temp);
    let lastDate=0;

    if(createdDate==29){
      lastDate=1;
    }else if(createdDate==30){
      lastDate=2;
    }else if(createdDate==31){
      lastDate=2;
    }else{
      lastDate = createdDate+2;
    }
    
    let today=Date();
    let todayDate=today.slice(8,10); 
    let todayExact = parseInt(todayDate);

    if(todayExact>=lastDate){
      setIsPaymentAble(false);
    }
    else{
      setIsPaymentAble(true);
    }
  };


  if (orders === undefined) {
    return <span> Your orders </span>;
  }
  return (
    <div className="user-profile-top">
      <Helmet>
        <title> Shopstick | Orders </title>
        <meta name="description" content="My Shopstick Orders"/>
      </Helmet>
      <div className="create-account-container contact-us-bottom profile-bottom">
        <Row>

          <Col md={3} sm={12} className="sideNav">
            <ProfileSidebar />
          </Col>

          <Col md={9} sm={12} className="sideNav">
            <MyActivity/>
            <div className="user-orders-page">
              <p className="header">Orders</p>
              <hr className="solid"></hr> 
              <div className='user-orders-table'>
                <Row>
                  <Col> <p className="title">Order Info</p>  </Col>
                  <Col> <p className="title">Order Status</p> </Col>
                  <Col> <p className="title">Payment Status</p> </Col>
                  <Col> <p className="title">No. of item (Price)</p> </Col>
                  <Col> <p className="title">Total Price</p> </Col>
                </Row> <hr/>

              { orders.map((order, index) => (
                  <Row key={index} className="product-data">
                    <Col> 
                      <span className="order-number"> {order.order_number} </span> <br/>
                      <span className="item-quantity"> 
                        <span className="items-amount"> {order.order_details.length} items </span> &nbsp;
                        <span onClick={()=>{ toggleOrderDetails(index); checkIsPayAble(order.created_at)  }}>
                        { orderIndex == index ? (
                            <img 
                            src="/images/arrUpOrder.png"
                            alt="shopstick" 
                            className="down-arrow" />
                          ) : ( 
                            <img 
                              src="/images/arDownOrder.png"
                              alt="shopstick" 
                              className="down-arrow" />
                          )} 
                        </span>
                      </span>
                      <p style={{color:"#787c87", }}>{order.created_at.substring(0, 10)}</p>
                    </Col> 

                      <Col className="order-status">
                        {order.order_status==1 && <span className="status-pending" > Pending </span> }
                        {order.order_status==2 && <span className="status-processing"> Processing </span> }
                        {order.order_status==3 && <span className="status-shipping"> Shipping </span> }
                        {order.order_status==4 && <span className="status-delivered"> Delivered </span> }
                        {order.order_status==5 && <span className="status-return"> Cancelled </span> }
                        {order.order_status==6 && <span className="status-return"> Return </span> }
                        {order.order_status==8 && <span className="status-return"> Delivered Failed </span> }
                      </Col>

                      <Col>
                          <p> 
                            { order.is_order_successful == 1 && order.is_cash_on_delivery == 1 && <span className="payment-type"> Cash On </span> }
                            { order.is_order_successful == 1 && order.is_cash_on_delivery != 1 && <span className="payment-type"> Online </span> }
                            { order.is_order_successful != 1 && <span className="payment-type"> Pending </span> }
                          </p>
                      </Col>

                      <Col>
                          <span className="total-order-amount">Price: { order.total_order_amount } tk</span> <br/>
                          { order.coupon_discount != 0 && <span className="total-order-amount">Coupon: { order.coupon_discount } tk <br/></span> }
                          <p className="total-order-amount">Delivery charge: { order.delivery_charge } tk</p>
                      </Col>

                      <Col>
                          <p className="sub-total-price"> { order.total_payable_amount } tk</p>
                      </Col>
                      
                      { orderIndex === index &&  
                        <div className="dropdown-orders">

                          {order.order_details.map((product, index) => (
                            <Row key={index} className="drop-item">
                              <Col>
                                <div className="product-image"> <img src={ product.product.image1 } alt="shopstick" /> </div>  
                                <div className="name"> <span>{product.name}</span> <p> {product.product_price}</p> </div>
                              </Col>
                              <Col className="quantity"> <br/> <span> Quantity: { product.quantity } </span> </Col>
                              <Col className="total-price"> <br/> { product.product_price * product.quantity } tk </Col>
                              <Col className="add-review">
                                <div className="add-review">
                                  <br/>
                                  <Row>
                                  {
                                    order.order_status==4 ? (
                                      product.review==null ? (
                                        <Col>
                                          { ratingProductID==product.product_id ? (
                                            <div style={{ color: "#F9C732", fontSize:"12px" }}>
                                              <Rating
                                                emptySymbol="fa fa-star-o fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={1}
                                                onChange={(value)=>{ userRatingToProduct(value, product.product_id); }}
                                                initialRating={rating}
                                              />
                                            </div>
                                          ) : (
                                            <div style={{ color: "#F9C732", fontSize:"12px" }}>
                                              <Rating
                                                emptySymbol="fa fa-star-o fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={1}
                                                onChange={(value)=>{ userRatingToProduct(value, product.product_id); }}
                                                initialRating={5}
                                              />
                                            </div>
                                          )}
                                          <Form>
                                              <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    value={reviewText}
                                                    onChange={(event) => {
                                                      setReviewText(event.target.value);
                                                    }}
                                                    placeholder="Your comment here..."
                                                />
                                              </Form.Group>
                                          </Form>
                                          <p style={{color:"#787c87"}}> Review will be public for others. </p>
                                          <Button variant="primary" className="submit-review-btn" 
                                              onClick={()=>{addReview(product.product_id, rating, reviewText, order.order_status, product.id )}}>
                                              Add Review 
                                          </Button>
                                        </Col>
                                      ) : (
                                        <Col>
                                          <div style={{ color: "#F9C732", fontSize:"12px" }}>
                                              <Rating
                                                emptySymbol="fa fa-star-o fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={1}
                                                readonly={true}
                                                initialRating={product.review.rating}
                                              />
                                              <p style={{fontSize:"16px", color:"#787c87", }}>
                                                { product.review.description==null ? (
                                                  " "
                                                ) : (
                                                    product.review.description.length <= 20 ? (
                                                      product.review.description
                                                    ):(
                                                      product.review.description.substring(0, 20)+" . . ."
                                                    )
                                                )}
                                              </p>
                                          </div>
                                        </Col>
                                      )
                                    ) : (
                                      " "
                                    )
                                  }
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}

                          <Row style={{float:"right", textAlign:"right", alignItems:"right", marginRight:"1%", }}>
                            <div>
                            
                              { isPaymentAble==true && order.order_status==1 && 
                                <button className="pay-on-order" onClick={()=>{setPaymentSectionOpen(true)}}> Payment </button> 
                              }
                              { order.order_status==1 && <button className="delete-this-order" onClick={()=>{deleteUserOrder(order.id)}}> Delete </button> }
                              { paymentSectionOpen==true && 
                                <div style={{marginTop:"20px", }}>
                                  <button onClick={()=>{setIsOnlinePayment(true)}} className={isOnlinePayment==true? "selected-pm" : "not-selected-pm"} >
                                    Online Payment
                                  </button>
                                  <button onClick={()=>{setIsOnlinePayment(false)}} className={isOnlinePayment==false? "selected-pm" : "not-selected-pm"} >
                                    Cash On Delivery
                                  </button>
                                  <button onClick={()=>{paymentFromOrderList(order.id, isOnlinePayment)}} className="order-from-order-list">Proceed</button>
                                </div>
                              }
                            </div>
                          </Row>
                        </div>
                      }
                      </Row> ))}
                  </div>
             
            </div>
          </Col>
        </Row>

      </div>
    </div>
  );
};
export default UserOrdersDetailPage;
