import React from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import ProfileSidebar from "components/profileSidebar/sidebar";
import MyActivity from "components/profileSidebar/activity";
import { InputFieldForAccount } from "components/form/inputRenderes";
import { minLength, required } from "services/validators";
import { Field, reduxForm, SubmissionError } from "redux-form";
import userDataAccess from "dataAccess/userDataAccess";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const minLength6 = minLength(6);

function ChangePassword(props) {
   const handleFormSubmit = (passwords) => {
      return userDataAccess
         .changePassword(passwords)
         .then((data) => {
            toast.success("Updated password successfully");
         })
         .catch((error: Error) => {
            throw new SubmissionError({ _error: error.message });
         });
   };
   return (
      <div className="user-profile-top">
         <Helmet>
            <title> Shopstick | Change Password </title>
            <meta name="description" content="My Profile Change Password"/>
         </Helmet>
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col md={3} sm={12} className="sideNav">
                  <ProfileSidebar />
               </Col>
               <Col md={9} sm={12} className="sideNav">
                  <MyActivity />
                  <div className="sideNav-container sideNav-container-bottom">
                     <p className="bottom-header">Change Password</p>
                     <hr className="solid"></hr>
                     <Form className="information" onSubmit={props.handleSubmit((data) => handleFormSubmit(data))}>
                        <Field
                           name="old_password"
                           type="password"
                           label="Old"
                           component={InputFieldForAccount}
                           placeholder="*****"
                           validate={[required]}
                        />
                        <Field
                           name="password"
                           type="password"
                           label="New"
                           component={InputFieldForAccount}
                           placeholder="*****"
                           validate={[required, minLength6]}
                        />
                        <Field
                           name="password_confirmation"
                           type="password"
                           label="Confirm"
                           component={InputFieldForAccount}
                           placeholder="*****"
                           validate={[required]}
                        />

                        {props.error && (
                           <div>
                              <strong style={{ color: "red" }}>{props.error}</strong>
                           </div>
                        )}
                        <Button disabled={props.submitting} type="submit">
                           Save
                        </Button>
                     </Form>
                  </div>
               </Col>
            </Row>
         </div>
      </div>
   );
}
const validDateConfirmPassword = (values) => {
   const errors: any = {};
   if (values.password === values.old_password) {
      errors.password = "Old and new password must be different";
   }
   if (values.password !== values.password_confirmation) {
      errors.password_confirmation = "Password did not matched";
   }
   return errors;
};
const ChangePasswordForm = reduxForm({ form: "ChangePassword", validate: validDateConfirmPassword })(ChangePassword);
export default ChangePasswordForm;
