import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import LanguageText from "components/common/languageText";

const whatWeHaveMore = (props) => {
    
    return (
      <div className="home-what-we-have">
         <div className="content">
         <Row className="what-we-have-more">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
               <p className="more-title"> <LanguageText firstKey="page" secondKey="whatWeHaveMore" /> </p>
            </Col>
         </Row>

         <Row>  
            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/img1.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> 24/7 Support </span> <br></br> Support every time </Col>
               </Row>
            </Col>

            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/car-123.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> Free Shipping </span> <br></br> Minimum order 500 tk </Col>
               </Row>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/payment.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> Online Payment </span> <br></br> Cards - Mobile - Internet </Col>
               </Row>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/cashback.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> 100% Cash Back </span> <br></br> Within 7 days(Guarantee) </Col>
               </Row>
            </Col>

            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/organicfood.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> Organic Food </span> <br></br> Fruits & Vegitables </Col>
               </Row>
            </Col>

            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/securepay.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> Secured Payment </span> <br></br> 100% secured payment </Col>
               </Row>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/cprefer.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> Customer Preference </span> <br></br> Make you own order </Col>
               </Row>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12} >
               <Row className="wwh-more-item">
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}> <img src="images/qualitypro.png" alt="shopstick"></img> </Col>
                  <Col xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={12}> <span> Quality Products </span> <br></br> 100% quality products </Col>
               </Row>
            </Col>
         </Row>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(whatWeHaveMore);