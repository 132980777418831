import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { InputFieldWithoutLabel } from "components/form/inputRenderes";
import { required, minLength } from "services/validators";
import { actions as userActions } from "@redux/userRedux";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import userDataAccess from "dataAccess/userDataAccess";
import socialLoginDataAccess from "dataAccess/socialLoginDataAccess";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { actions as cartActions } from "@redux/cartRedux";
import { keys } from "common/constants";
import Axios from "axios";
import { Helmet } from "react-helmet";

const minLength6 = minLength(6);

interface ContinueWithProps {
   imgSrc: string;
   imgAlt: string;
   text: string;
}

function CreateAccount(props: any) {
   const dispatch = useDispatch();
   const [showPassword, setShowPassword] = useState(false);
   const [phoneNumber, setPhoneNumber] = useState("");
   const [isOTPSent, setIsOTPSent] = useState(false);
   const [otp, setOtp] = useState("");
   const [isOTPEnabled, setIsOTPEnabled] = useState(true);

   const [userFbName, setUserFbName] = useState();
   const [socialLogInProvider, setSocialLogInProvider] = useState(0);
   const [fbUserID, setFbUserID] = useState(0);
   const [userFbImage, setUserFbImage] = useState();
   
   const [userGoogleName, setUserGoogleName] = useState();
   const [googleUserID, setGoogleUserID] = useState(0);
   const [userGoogleEmail, setUserGoogleEmail] = useState();
   const [userGoogleImage, setUserGoogleImage] = useState();
   
   const toggleShowPassword = () => {
      if (showPassword == true) {
         setShowPassword(false);
      } else {
         setShowPassword(true);
      }
   };

   const handleFormSubmit = (user) => {
      let data = Object.assign(user, { phone_number: phoneNumber, otp: otp });
      return userDataAccess
         .signUp(data)
         .then((data) => {
            dispatch(userActions.login(data));
            toast.success("Register successful");
            props.history.push("/");
         })
         .catch((error) => {
            throw new SubmissionError({ _error: error.message });
         });
   };
   const sendOTP = () => {
      userDataAccess
         .sendOpt(phoneNumber)
         .then((data) => {
            setIsOTPSent(true);
            setIsOTPEnabled(false);
            enableOTP();
            toast.success("OTP sent successfully!");
         })
         .catch((error) => {
            // setIsOTPSent(true);
            // setIsOTPEnabled(false);
            // enableOTP();
            toast.error("Could not sent OTP, Try again");
         });
   };
   const enableOTP = () => {
      setTimeout(() => {
         setIsOTPEnabled(false);
      }, 60000);
   };

   const responseFacebook = (response) => {
      if(response.status !== 'unknown') {
         console.log("fb response = ", response);
         setUserFbName(response.name);
         setSocialLogInProvider(2);
         setUserFbImage(response.picture.data.url);
         setFbUserID(response.userID);
      }
   };
   const componentClicked = () => {
      //console.log("facebook btn clicked");
   };
   
   
   const responseGoogle=(response)=>{

      if(response.profileObj !== undefined && response.error !== "popup_closed_by_user"){
            console.log("google response = ", response);
            console.log("google response2 = ", response.profileObj);
            setUserGoogleName(response.profileObj.name);
            setSocialLogInProvider(3);
            setUserGoogleEmail(response.profileObj.email);
            setUserGoogleImage(response.profileObj.imageUrl);
            setGoogleUserID(response.profileObj.googleId);
      }
   };

  

   useEffect(()=>{
      socialLoginDataAccess.createAccountByGoogleId(userGoogleName, socialLogInProvider, userGoogleEmail, userGoogleImage, googleUserID)
      .then((data)=>{
         toast.success("Logged in successfully");
         dispatch(userActions.login(data));
         localStorage.setItem(keys.accessTokenKey, data.access_token);
         dispatch(cartActions.addAllProductsToCart(props.cart.cart, true));
         props.history.push("/");
      })
      .catch((error)=>{" "});
   },[googleUserID]);

   useEffect(()=>{
      if(fbUserID != 0){
         socialLoginDataAccess.createAccountByFacebookId(userFbName, socialLogInProvider, userFbImage, fbUserID)
         .then((data)=>{
            toast.success("Logged in successfully");
            dispatch(userActions.login(data));
            localStorage.setItem(keys.accessTokenKey, data.access_token);
            dispatch(cartActions.addAllProductsToCart(props.cart.cart, true));
            props.history.push("/");
         })
         .catch((error)=>{" "});
      }
   },[fbUserID]);

   return (
      <div className="create-account-container">
         <Helmet>
            <title> Shopstick | Create Account </title>
            <meta name="description" content="Create An Account At Shopstick Online Shopping"/>
         </Helmet>
         <div className="create-account-text">Create Account</div>
         <form className="create-form" onSubmit={props.handleSubmit((data) => handleFormSubmit(data))}>
            <Field
               name="name"
               type="text"
               component={InputFieldWithoutLabel}
               placeholder="Name"
               validate={[required]}
               className="form-design"
            />
            {/* <Field
               name="phone_number"
               type="text"
               component={InputFieldWithoutLabel}
               placeholder="Phone number"
               validate={[required]}
            /> */}

            <Form.Group>
               <Form.Control
                  className="form-design"
                  value={phoneNumber}
                  onChange={(e) => {
                     if (!isOTPSent) setPhoneNumber(e.target.value);
                  }}
                  type="text"
                  as="input"
                  placeholder="Phone number"
               />
               {/* {touched && error && (
                  <Form.Control.Feedback style={{ display: "block" }} type="invalid">
                     {error}
                  </Form.Control.Feedback>
               )} */}
            </Form.Group>
            <div style={{ position: "relative" }}>
               <Field
                  name="password"
                  type={showPassword == true ? "text" : "password"}
                  component={InputFieldWithoutLabel}
                  placeholder="Password"
                  validate={[required, minLength6]}
                  className="form-design"
               />
               <span className="show-pass-btn" onClick={toggleShowPassword}>
                  Show
               </span>
            </div>

            <div className="otp-section">
               <input
                  type="text"
                  name="verification-code"
                  placeholder="Verification Code"
                  className="verification-code"
                  value={otp}
                  onChange={(e) => {
                     setOtp(e.target.value);
                  }}
               />
               <button
                  onClick={() => {
                     sendOTP();
                  }}
                  disabled={!isOTPEnabled}
                  className="send-otp-btn"
                  type="button"
               >
                  {isOTPSent ? "Resend OTP" : "Send OTP"}
               </button>
            </div>

            {props.error && (
               <div>
                  <strong style={{ color: "red" }}>{props.error}</strong>
               </div>
            )}
            <Button disabled={props.submitting || !isOTPSent} className="create-account-btn" type="submit">
               Register
            </Button>
         </form>
         <div>
            <hr />
            <p className="or">Or</p>
            <hr />
         </div>

         
         <div className="continue-with-row">
            <FacebookLogin
            appId="304547944026923"
            textButton="Continue with facebook"
            cssClass="continue-with-fb-btn"
            upperCase={false}
            autoLoad={false}
            fields="name,email,picture"
            //onClick={componentClicked}
            callback={responseFacebook}
            icon={<i className="fa fa-facebook" style={{margin:'3px 6px 0px 3px', color:"#40bfff", float:"left"}}/>}
            />
            <br/>
            <GoogleLogin
            clientId="997620380831-793ncf7044go7im4216fgqdfj1nlbkf9.apps.googleusercontent.com"
            buttonText="Continue with google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            className="continue-with-google-btn"
            />
         </div>

         <div className="login">
            Already have an account? {" "}
            <Link to="/login" className="login-text">
               Login
            </Link>

            
         </div>
         
      </div>
   );
}

const createAccount = reduxForm({ form: "createAccount" })(CreateAccount);
export default createAccount;