import favoriteDataAccess from "dataAccess/favoriteDataAccess";
import productDataAccess from "dataAccess/productDataAccess";
import { Favorite } from "models/favorite";

const typePrefix = "Favorite_";

const Types = {
   getFavoriteList: typePrefix + "GET_FAVORITE_LIST",
   addProductToFavorite: typePrefix + "ADD_PRODUCT_TO_FAVORITE",
   removeProductFromFavorite: typePrefix + "REMOVE_PRODUCT_FROM_FAVORITE",
   removeAllProductFromFavorite: typePrefix + "REMOVE_ALL_PRODUCT_FROM_FAVORITE",
   getFavoriteProductLoading: typePrefix + "GET_FAVORITE_PRODUCT_LOADING",
   getFavoriteProductComplete: typePrefix + "GET_FAVORITE_PRODUCT_COMPLETE",
   getFavoriteProductError: typePrefix + "GET_FAVORITE_PRODUCT_ERROR",
};

export const actions = {
   getUserFavorites: () => (dispatch) => {
      favoriteDataAccess
         .getUserFavorites()
         .then((data) => {
            dispatch({ type: Types.getFavoriteList, payload: data });
         })
         .catch((error) => {
            dispatch({ type: Types.getFavoriteList, payload: [] });
         });
   },
   addProductToFavorite: (productId: string, isUserLoggedIn: boolean) => (dispatch) => {
      if (isUserLoggedIn) {
         favoriteDataAccess
            .addFavorite(productId)
            .then((data) => {
               //dispatch({ type: Types.addProductToFavorite, payload: productId });
            })
            .catch((error) => {});
      } else {
         //dispatch({ type: Types.addProductToFavorite, payload: productId });
      }
      dispatch({ type: Types.addProductToFavorite, payload: productId });
   },
   removeProductFromFavorite: (productId: string, isUserLoggedIn: boolean) => (dispatch) => {
      if (isUserLoggedIn) {
         favoriteDataAccess
            .removeFavorite(productId)
            .then((data) => {
               //dispatch({ type: Types.removeProductFromFavorite, payload: productId });
            })
            .catch((error) => {});
      } else {
         // dispatch({ type: Types.removeProductFromFavorite, payload: productId });
      }
      dispatch({ type: Types.removeProductFromFavorite, payload: productId });
   },
   removeAllProductFromFavorite: (isUserLoggedIn: boolean) => (dispatch) => {
      if (isUserLoggedIn) {
         favoriteDataAccess
            .removeAllProductFromFavorite()
            .then((data) => {
               dispatch({ type: Types.removeAllProductFromFavorite});
            })
            .catch((error) => {});
      } else {
          dispatch({ type: Types.removeAllProductFromFavorite});
      }
   },
   getFavoriteProducts: (favorite: Array<Favorite>) => (dispatch) => {
      dispatch({ type: Types.getFavoriteProductLoading });
      productDataAccess
         .getSpecificProducts(favorite.map((favoriteItem) => favoriteItem.id))
         .then((data) => {
            dispatch({ type: Types.getFavoriteProductComplete, payload: data });
         })
         .catch((error) => {
            dispatch({ type: Types.getFavoriteProductError, payload: error.message });
         });
   },
};

const initialState = {
   favorite: [],
   total: 0,
   productsLoading: false,
   products: [],
   productsError: false,
   productsErrorMessage: "",
};

export const reducer = (state: any = initialState, action: any) => {
   switch (action.type) {
      case Types.getFavoriteList: {
         return { ...state, favorite: action.payload };
      }
      case Types.addProductToFavorite: {
         return { ...state, favorite: [...state.favorite, { id: action.payload }] };
      }
      case Types.removeProductFromFavorite: {
         let newFavorite = [];
         for (let index = 0; index < state.favorite.length; index++) {
            if (state.favorite[index].id !== action.payload) {
               newFavorite.push(state.favorite[index]);
            }
         }
         return { ...state, favorite: newFavorite };
      }
      case Types.removeAllProductFromFavorite: {
         return { ...state, favorite:[] };
      }
      case Types.getFavoriteProductLoading: {
         return { ...state, productsLoading: true, productsError: false };
      }
      case Types.getFavoriteProductComplete: {
         return { ...state, productsLoading: false, productsError: false, products: action.payload };
      }
      case Types.getFavoriteProductError: {
         return { ...state, productsLoading: false, productsError: true, productsErrorMessage: action.payload };
      }
      
      default:
         return state;
   }
};
