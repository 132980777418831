import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, ListGroup } from "react-bootstrap";
import SidebarMore from "components/sidebar/more";
//import { menuList } from "data/menu";
import LanguageText from "components/common/languageText";
import categoryDataAccess from "dataAccess/categoryDataAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Sidebar = (props: any) => {
   const [selectedExpandableIndex, setSelectedExpandableIndex] = useState(-1);
   const [menuList, setMenuList] = useState([]);

   useEffect(() => {
      categoryDataAccess
         .getCategoryTreeView()
         .then((data) => {
            setMenuList(data);
         })
         .catch((error) => {});
   }, []);

   return (
      <div className="sidebar">
         <Card style={{ maxWidth: "100%", borderRadius: "14px" }}>
            <Card.Header className="sidebar-header">
               <Link to="/allProducts" className="sidebar-all-products-link">
                  <LanguageText firstKey="header" secondKey="allProducts" />
               </Link>
            </Card.Header>
            <ListGroup variant="flush">
               {menuList.map((menuItem, index) => (
                  <Link to={ "/our-products/" + menuItem.slug } key={index}>
                     <ListGroup.Item
                        onClick={() => {
                           // setShowMore(!showMore);
                        }}
                        className="sidebar-item"
                     >
                        <div
                           onMouseEnter={() => {
                              setSelectedExpandableIndex(index);
                           }}
                        >
                           <FontAwesomeIcon icon={menuItem.icon ? menuItem.icon : "mobile-alt"} />
                           <span style={{ marginLeft: "10px" }}>{menuItem.name}</span>
                           {menuItem.child && menuItem.child.length > 0 && (
                              <img src="images/chevron-right.png" className="img-last" alt="img" />
                           )}
                        </div>
                     </ListGroup.Item>
                  </Link>
               ))}
               <Link to={"/special-offers-all"}>
                  <div className="our-offers-in-home-sidebar">
                     <div>
                        <img src="/images/giftyellow.png" alt="shopstick" />
                     </div>
                     <div className="offer-text">
                        <span>Special Offers</span>
                     </div>
                  </div>
               </Link>
            </ListGroup>
            
         </Card>
         {selectedExpandableIndex !== -1 && (
            <div className="sidebar-more">
               <SidebarMore
                  selectedIndex={selectedExpandableIndex}
                  hideMenu={() => {
                     setSelectedExpandableIndex(-1);
                  }}
                  childMenuList={menuList[selectedExpandableIndex].child}
               />
            </div>
         )}
      </div>
   );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Sidebar);
