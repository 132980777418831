import cartDataAccess from "dataAccess/cartDataAccess";
import productDataAccess from "dataAccess/productDataAccess";
import { Cart } from "models/cart";
import cartServices from "services/cartServices";

const typePrefix = "CART_";

const Types = {
   getCartFromServer: typePrefix + "GET_CART_FROM_SERVER",
   addProductToCart: typePrefix + "ADD_PRODUCT_TO_CART",
   addAllProductsToCart: typePrefix + "ADD_ALL_PRODUCTS_TO_CART",
   increaseProductQuantity: typePrefix + "INCREASE_PRODUCT_QUANTITY",
   decreaseProductQuantity: typePrefix + "DECREASE_PRODUCT_QUANTITY",
   removeProductFromCart: typePrefix + "REMOVE_PRODUCT_FROM_CART",
   getCartProductLoading: typePrefix + "GET_CART_PRODUCT_LOADING",
   getCartProductComplete: typePrefix + "GET_CART_PRODUCT_COMPLETE",
   getCartProductError: typePrefix + "GET_CART_PRODUCT_ERROR",
   getCartFromLocalStorage: typePrefix + "GET_CART_FROM_LOCAL_STORAGE",
};

export const actions = {
   getCartFromServer: () => (dispatch) => {
      cartDataAccess
         .getCart()
         .then((data) => {
            dispatch({ type: Types.getCartFromServer, payload: data });
         })
         .catch((error) => {
            dispatch({ type: Types.getCartFromServer, payload: { cart: [], total: 0 } });
         });
   },
   addProductToCart: (productId: string, isUserLoggedIn: boolean = false) => (dispatch) => {
      if (isUserLoggedIn) {
         cartDataAccess.addToCart(productId);
      }
      dispatch({ type: Types.addProductToCart, payload: { productId, isUserLoggedIn } });
   },
   addAllProductsToCart: (products, isUserLoggedIn: boolean = false) => (dispatch) => {
      if (products.length === 0) {
         return;
      }
      if (isUserLoggedIn) {
         cartDataAccess
            .addAllToCart(products)
            .then((data) => {
               dispatch({ type: Types.getCartFromServer, payload: data });
            })
            .catch((error) => {});
      }
      //dispatch({ type: Types.addAllProductsToCart, payload: { products, isUserLoggedIn } });
   },
   increaseProductQuantity: (productId: string, currentQuantity, isUserLoggedIn: boolean = false) => (dispatch) => {
      if (isUserLoggedIn) {
         cartDataAccess.updateCart(productId, currentQuantity + 1);
      }
      dispatch({ type: Types.increaseProductQuantity, payload: { productId, isUserLoggedIn } });
   },
   decreaseProductQuantity: (productId: string, currentQuantity, isUserLoggedIn: boolean = false) => (dispatch) => {
      if (isUserLoggedIn) {
         cartDataAccess.updateCart(productId, currentQuantity - 1);
      }
      dispatch({ type: Types.decreaseProductQuantity, payload: { productId, isUserLoggedIn } });
   },

   removeFromCart: (productId: string, isUserLoggedIn) => (dispatch) => {
      if (isUserLoggedIn) {
         cartDataAccess.updateCart(productId, 0);
      }
      dispatch({ type: Types.removeProductFromCart, payload: { productId, isUserLoggedIn } });
   },
   getCartProducts: (cart: Array<Cart>) => (dispatch) => {
      dispatch({ type: Types.getCartProductLoading });
      productDataAccess
         .getSpecificProducts(cart.map((cartItem) => cartItem.id))
         .then((data) => {
            dispatch({ type: Types.getCartProductComplete, payload: data });
         })
         .catch((error) => {
            dispatch({ type: Types.getCartProductError, payload: error.message });
         });
   },
   getCartFromLocalStorage: () => (dispatch) => {
      dispatch({ type: Types.getCartFromLocalStorage });
   },
};

const initialState = {
   //    ToDo:: If logged in make necessary network calls if not then get cart from local storage
   cart: cartServices.getCartFromLS(),
   total: cartServices.getCartTotal(cartServices.getCartFromLS()),
   productsLoading: false,
   products: [],
   productsError: false,
   productsErrorMessage: "",
};

export const reducer = (state: any = initialState, action: any) => {
   switch (action.type) {
      case Types.getCartFromServer: {
         return {
            ...state,
            cart: action.payload.cart,
            total: action.payload.total,
         };
      }
      case Types.getCartFromLocalStorage: {
         return {
            ...state,
            cart: cartServices.getCartFromLS(),
            total: cartServices.getCartTotal(cartServices.getCartFromLS()),
         };
      }
      case Types.addProductToCart: {
         if (!action.payload.isUserLoggedIn) {
            cartServices.postCartToLS([...state.cart, { id: action.payload.productId, quantity: 1 }]);
         }
         return {
            ...state,
            cart: [...state.cart, { id: action.payload.productId, quantity: 1 }],
            total: state.total + 1,
         };
      }
      case Types.increaseProductQuantity: {
         let newCart = [];

         state.cart.forEach((element: Cart) => {
            if (element.id === action.payload.productId) {
               newCart.push({ id: element.id, quantity: element.quantity + 1 });
            } else {
               newCart.push(element);
            }
         });
         if (!action.payload.isUserLoggedIn) {
            cartServices.postCartToLS(newCart);
         }
         return { ...state, cart: newCart, total: state.total + 1 };
      }
      case Types.decreaseProductQuantity: {
         let newCart = [];
         state.cart.forEach((element: Cart) => {
            if (element.id === action.payload.productId) {
               newCart.push({ id: element.id, quantity: element.quantity - 1 });
            } else {
               newCart.push(element);
            }
         });
         newCart = newCart.filter((element) => element.quantity > 0);
         if (!action.payload.isUserLoggedIn) {
            cartServices.postCartToLS(newCart);
         }
         return { ...state, cart: newCart, total: state.total - 1 };
      }
      case Types.removeProductFromCart: {
         let newCart = [];
         let itemQuantity = 0;
         state.cart.forEach((element: Cart) => {
            if (element.id !== action.payload.productId) {
               newCart.push(element);
            } else {
               itemQuantity = element.quantity;
            }
         });
         if (!action.payload.isUserLoggedIn) {
            cartServices.postCartToLS(newCart);
         }
         return { ...state, cart: newCart, total: state.total - itemQuantity };
      }
      case Types.getCartProductLoading:
         return { ...state, productsLoading: true, productsError: false };

      case Types.getCartProductComplete:
         return { ...state, productsLoading: false, productsError: false, products: action.payload };

      case Types.getCartProductError:
         return { ...state, productsLoading: false, productsError: true, productsErrorMessage: action.payload };

      default:
         return state;
   }
};
