import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card } from "react-bootstrap";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";

const SuperSaleProducts = (props: any) => {
  
   return (
      <div className="super-sale-products">
        <Row>
            <Col sm={12} md={8} lg={8}>
                <img src="/images/homebanner/offerPage.png" alt="shopStick" style={{maxWidth:"100%", borderRadius:"20px", }} />
            </Col>
            <Col sm={12} md={4} lg={4}>
                <Row className="super-sale-right-first">
                    <img src="/images/homebanner/first.png" alt="shopStick" style={{maxWidth:"100%", }} />
                </Row>
                <Row style={{marginTop:"15px", }}>
                    <img src="/images/homebanner/second.png" alt="shopStick" style={{maxWidth:"100%", }} />
                </Row>
            </Col>
        </Row>
    </div>  
   );
};
export default SuperSaleProducts;
