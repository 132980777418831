import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { InputFieldWithoutLabel } from "components/form/inputRenderes";
import { required, minLength } from "services/validators";
import { actions as addressActions } from "@redux/addressRedux";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import userDataAccess from "dataAccess/userDataAccess";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function AddAddress(props: any) {
   const dispatch = useDispatch();
   const addressId = props.addressId;
   const previousAddress = props.initialAddress;
   
   const handleFormSubmit = (data) => {
      let _data = Object.assign({},data,{city: selectedCity, area: selectedAreaName});
      
      if (addressId === "0") {
         return userDataAccess
            .addAddress(_data)
            .then((data) => {
               toast.success("Address added successfully");
               dispatch(addressActions.getAddressList());
               props.setIsAddAddressModalOpen(false);
            })
            .catch((error) => {
               throw new SubmissionError({ _error: error.message });
            });
      } else {
         //ToDo:: Call update data access here
         return userDataAccess
            .updateAddress(_data)
            .then((data) => {
               toast.success("Address Updated successfully");
               dispatch(addressActions.getAddressList());
               props.setIsAddAddressModalOpen(false);
            })
            .catch((error) => {
               throw new SubmissionError({ _error: error.message });
            });
      }
   };


   const [cityList, setCityList] = useState(undefined);
   const [selectedCity, setSelectedCity] = useState(0);
   const [areaList, setAreaList] = useState(undefined);
   const [selectedAreaName, setSelectedAreaName] = useState("");
   useEffect(()=>{
      userDataAccess.getCityList()
      .then((data)=>{
         setCityList(data);

      })
      .catch((error)=>{" "})
   },[selectedCity]);

   useEffect(()=>{
      if(selectedCity==0){

      }else {
         userDataAccess.getUserAreaList(selectedCity)
         .then((data)=>{
            setAreaList(data);
         })
         .catch((error)=>{" "})
      }
   },[selectedCity]);

   const handleCityChange=(e)=>{
      setSelectedCity(e.target.value);
      setSelectedAreaName("");
      setAreaList(undefined);
   };
   const handleAreaChange=(e)=>{
      setSelectedAreaName(e.target.value);
   };

   //console.log("city id = ", selectedCity);
   //console.log("area name selected = ", selectedAreaName);

   if(cityList==undefined && areaList==undefined){
      return <span> </span>
   }
   return (
      <span>
         <Helmet>
            <title> Shopstick | Add Address </title>
            <meta name="description" content="Add A New Shipping Address"/>
         </Helmet>
         <Modal show={true} className="add-address-modal">
            <div className="sideNav-container sideNav-container-bottom">
               <div className="add-address-header">
                  <span className="title">{addressId === "0" ? "Add Address" : "Update Address"}</span>
               </div>{" "}
               <br />
               <form onSubmit={props.handleSubmit((data) => handleFormSubmit(data))}>
                  <Field
                     name="name"
                     type="text"
                     component={InputFieldWithoutLabel}
                     placeholder="Enter Your Name"
                     validate={[required]}
                     className="form-design"
                  />
                  <Field
                     name="phone_number"
                     type="text"
                     component={InputFieldWithoutLabel}
                     placeholder="Phone Number"
                     validate={[required]}
                  />

                  {/*
                  <Field
                     name="city"
                     type="text"
                     component={InputFieldWithoutLabel}
                     placeholder="City"
                     validate={[required]}
                     className="form-design"
                     /> 
                  */}

                  <select onChange={handleCityChange} name="city" className="select-city-options">
                     <option> { previousAddress==undefined ? "Select City" : previousAddress.city } (Except This) </option>
                     {cityList.map((city, index)=>(
                        <option value={city.id} key={index} > {city.name} </option>
                     ))}
                  </select>

                  { areaList==undefined ? (
                     <select name="area" className="select-area-options">
                        <option value={0}> {previousAddress==undefined ? "Select Area" : previousAddress.area } </option>
                     </select> ) : (
                     <select onChange={handleAreaChange} name="area" className="select-area-options">
                        <option value={0}> Select Area  </option>
                        {areaList.map((area, index)=>(
                           <option value={area.name} key={index}> {area.name} </option>
                        ))}
                     </select>
                     )
                  }
                  
                  {/*
                  <Field
                     name="area"
                     type="text"
                     component={InputFieldWithoutLabel}
                     placeholder="Area"
                     validate={[required]}
                     className="form-design"
                  />
                  */}

                  <Field
                     name="postal_code"
                     type="text"
                     component={InputFieldWithoutLabel}
                     placeholder="Postal Code (Optional)"
                     //validate={[required]}
                     className="form-design"
                  />
                  <Field
                     name="details"
                     type="text"
                     component={InputFieldWithoutLabel}
                     placeholder="Details (Optional)"
                     //validate={[required]}
                     className="form-design"
                  />
                  {props.error && (
                     <div>
                        <strong style={{ color: "red" }}>{props.error}</strong>
                     </div>
                  )}
                  <br />
                  <div className="add-address-form-bottom">
                     <span
                        onClick={() => {
                           props.setIsAddAddressModalOpen(false);
                        }}
                        className="cancel-add-address"
                     >
                        Cancel
                     </span>

                     <Button disabled={props.submitting} className="save-add-address" type="submit">
                        {addressId === "0" ? "Save" : "Update"}
                     </Button>
                  </div>
               </form>
            </div>
         </Modal>
      </span>
   );
}

const addAddress = reduxForm({ form: "addAddress", enableReinitialize: true })(AddAddress);
const mapStateToProps = ({ address }, ownProps) => ({ address, initialValues: ownProps.initialAddress });

export default connect<{}, {}, any>(mapStateToProps)(addAddress);
