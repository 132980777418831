import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, InputGroup, Modal, Alert } from "react-bootstrap";
import { actions as cartActions } from "@redux/cartRedux";
import { actions as addressActions } from "@redux/addressRedux";
import { connect, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { generateAddressString } from "services/util";
import AddAddress from "./addAddress";
import orderDataAccess from "dataAccess/orderDataAccess";
import cartServices from "services/cartServices";
import SpinnerLocal from "components/spinner";
import { dateToString, dateToTimeString } from "services/dateTimeService";
import { toast } from "react-toastify";

const Checkout = (props: any) => {
   const [showLoadSpinner, setShowLoadSpinner] = useState(false);
   const [deliveryDate, setDeliveryDate] = useState("");
   const [deliveryTime, setDeliveryTime] = useState("");
   const [deliveryFee, setDeliveryFee] = useState(0);
   const [couponDetails, setCouponDetails] = useState(undefined);
   const [couponDiscount, setCouponDiscount] = useState(0);
   const [selectedAddressId, setSelectedAddressId] = useState("0");
   const [isOnlinePayment, setIsOnlinePayment] = useState(true);
   const [promoCode, setPromoCode] = useState("");
   const [isAddNewAddressModalOpen, setIsAddNewAddressModalOpen] = useState(false);
   const [isPaymentAble, setIsPaymentAble] = useState(false);
   

   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(cartActions.getCartProducts(props.cart.cart));
      dispatch(addressActions.getAddressList());
   }, [dispatch, props.cart.cart]);
   const onAddressChange = (event) => {
      setSelectedAddressId(event.target.value);
      getShippingCost(event.target.value);
   };
   
   const onPromoApply = (promoCode, orderAmount) => {
      setShowLoadSpinner(true);
      orderDataAccess
         .applyToken(promoCode, orderAmount)
         .then((data) => {
            if (data.message === "The Coupon Is Invalid") {
               toast.info("Invalid coupon");
               setShowLoadSpinner(false);
               return;
            }
            if (parseFloat(data.data.min_spend) <= cartServices.getTotalPrice(props.cart.products, props.cart.cart)) {
               setCouponDetails(data.data);
               setCouponDiscount(data.data.value);
               toast.success("Coupon applied");
               setShowLoadSpinner(false);
            }
            if(data.data.free_shipping == 1){
               setDeliveryFee(0);
            }
         })
         .catch((error) => {
            toast.error(error.message);
            setShowLoadSpinner(false);
         });
   };

   const getShippingCost = (addressId) => {
      let userOrderAmount = props.cart.products && props.cart.cart ? cartServices.getTotalPrice(props.cart.products, props.cart.cart) : 0;
      setShowLoadSpinner(true);
      let cityID = 0;
      props.address.addressList.forEach((address) => {
         if (address.id == addressId) {
            cityID = address.city_id;
         }
      });
      orderDataAccess
         .getDeliveryFee(cityID, promoCode, userOrderAmount)
         .then((data) => {
            if(data.success==true){
               setDeliveryFee(data.data.delivery_charge);
               setIsPaymentAble(true);
               setShowLoadSpinner(false);
            }else {
               setDeliveryFee(0);
               setIsPaymentAble(false);
               setShowLoadSpinner(false);
               toast.error(data.message);
            }
            console.log(data);
            
         })
         .catch((error) => {
            setDeliveryFee(0);
            setShowLoadSpinner(false);
         });
   };

   const onProceed = () => {
      /*
      if (deliveryDate === "") {
         toast.info("Input expected delivery date");
         return;
      }
      if (deliveryTime === "") {
         toast.info("Input expected delivery time");
         return;
      }
      */
      if (selectedAddressId === "0") {
         toast.info("Select a shipping address first");
         return;
      }
      setShowLoadSpinner(true);
      let orderData = {
         shipping_id: selectedAddressId,
         preferable_date: dateToString(deliveryDate),
         preferable_time: dateToTimeString(deliveryTime),
         coupon_code: promoCode,
         coupon_discount: couponDiscount,
         delivery_charge: deliveryFee,
         total_order_amount: cartServices.getTotalPrice(props.cart.products, props.cart.cart),
      };
      orderDataAccess
         .postOrder(orderData)
         .then((data) => {
            orderDataAccess
               .payment(data.id, isOnlinePayment)
               .then((data) => {
                  setShowLoadSpinner(false);
                  if(!isOnlinePayment) {
                     toast.success("Order completed successfully");
                     props.history.push("/orders");
                  }
               })
               .catch((_error) => {
                  toast.error(_error);
                  setShowLoadSpinner(false);
               });
         })
         .catch((error) => {
            toast.error(error);
            setShowLoadSpinner(false);
         });
   };
   if (props.cart.cart.length !== props.cart.products.length) {
      return <SpinnerLocal />;
   }
   if (props.cart.productsError) {
      return <div>Something went wrong</div>;
   }
   
   return (
      <div className="user-profile-top">
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col className="sideNav">
                  <Row className="order-summary">
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="first-section">
                        <div>
                           <span className="header"> Order Summary </span>
                        </div>
                     </Col>

                     <hr style={{ width: "100%" }} />
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="second-section">
                        <Form>
                           <Form.Group>
                              <Form.Row>
                                 <Form.Label column="lg" lg={3} className="shipping-addr-form-label">
                                    {" "}
                                    Delivery Date{" "}
                                 </Form.Label>
                                 <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                                    <InputGroup style={{ position: "relative" }} className="delivery-date">
                                       <DatePicker
                                          selected={deliveryDate}
                                          onChange={(value) => {
                                             setDeliveryDate(value);
                                          }}
                                          placeholderText="Optional"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          className="form-control col-12 input-date"
                                       />
                                       <img src="/images/calendar.png" alt="shopstick" className="calendar-img" />{" "}
                                    </InputGroup> {/*
                                    <span className="suggest-date">
                                       {" "}
                                       We suggest a delivery date within <strong>
                                          {" "}
                                          04/11/2020 to 10/11/2020{" "}
                                       </strong>{" "}
                                    </span> */}
                                 </Col>
                              </Form.Row>{" "}
                              <br />
                              <Form.Row>
                                 <Form.Label column="lg" lg={3} className="shipping-addr-form-label">
                                    {" "}
                                    Delivery Time{" "}
                                 </Form.Label>
                                 <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                                    <InputGroup className="delivery-time">
                                       <DatePicker
                                          selected={deliveryTime}
                                          onChange={(value) => {
                                             setDeliveryTime(value);
                                          }}
                                          placeholderText="Optional"
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={15}
                                          timeCaption="Time"
                                          dateFormat="h:mm aa"
                                          className="form-control col-sm-12 input-time"
                                       />
                                       <img className="time-img" src="/images/clock.png" alt="shopstick" />{" "}
                                    </InputGroup>
                                 </Col>
                              </Form.Row>{" "}
                              <br/>
                              <Form.Row>
                                 <Form.Label column="lg" lg={3} className="shipping-addr-form-label">
                                    {" "}
                                    Shipping Address{" "}
                                 </Form.Label>
                                 <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                    <Form.Control
                                       as="select"
                                       value={selectedAddressId}
                                       onChange={onAddressChange}
                                       placeholder="Choose a shipping address"
                                       className="shipping-addr-input"
                                    >
                                       <option value={"0"} key={0}>
                                          Select a address
                                       </option>
                                       {props.address.addressList.map((address) => (
                                          <option value={address.id} key={address.id}>
                                             {generateAddressString(address)}
                                          </option>
                                       ))}
                                    </Form.Control>
                                 </Col>
                                 <Col xl={2} lg={2} md={12} sm={12} xs={12}>
                                    <div className="add-new-shipping">
                                       <Button
                                          onClick={() => {
                                             setIsAddNewAddressModalOpen(true);
                                          }}
                                          className="add-new-btn"
                                       >
                                          <span className="new-btn-text"> Add New </span>
                                       </Button>
                                    </div>
                                 </Col>
                              </Form.Row>{" "}
                              <br />
                              <Form.Row>
                                 <Form.Label column="lg" lg={3} className="shipping-addr-form-label">
                                    {" "}
                                    Promo Code{" "}
                                 </Form.Label>
                                 <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                    <Form.Control
                                       type="text"
                                       value={promoCode}
                                       onChange={(event) => {
                                          setPromoCode(event.target.value);
                                       }}
                                       placeholder="Enter promo code"
                                       className="promo-code-input"
                                    />
                                 </Col>
                                 <Col xl={2} lg={2} md={12} sm={12} xs={12}>
                                    <div className="apply-promo-btn">
                                       <Button className="promo-btn" 
                                                onClick={()=>{onPromoApply(promoCode, props.cart.products && 
                                                   props.cart.cart ? cartServices.getTotalPrice(props.cart.products, props.cart.cart)
                                                   : 0)}}>
                                          <span className="promo-btn-text"> Apply </span>
                                       </Button>
                                    </div>
                                 </Col>
                              </Form.Row>
                              <br />
                              <Row className="payment-method-container">
                                 <Col>
                                    <Form.Label column="lg" className="shipping-addr-form-label pm-label">
                                       Payment Method
                                    </Form.Label>
                                 </Col>
                                 <Col
                                    onClick={() => {
                                       setIsOnlinePayment(true);
                                    }}
                                    className={`payment-method ${isOnlinePayment ? "selected" : ""}`}
                                 >
                                    Online payment
                                 </Col>
                                 <Col
                                    onClick={() => {
                                       setIsOnlinePayment(false);
                                    }}
                                    className={`payment-method ${!isOnlinePayment ? "selected" : ""}`}
                                 >
                                    Cash on delivery
                                 </Col>
                              </Row>
                           </Form.Group>
                        </Form>
                        {/* <span className="delivery shipping-addr-form-label"> Delivery Fee: </span>
                        <span className="delivery-fee">
                           {" "}
                           <strong> 120 tk </strong>{" "}
                        </span> */}
                        <div className="checkout-amount-bottom">
                           <span className="checkout-amount-calculation"> Total: {props.cart.products && props.cart.cart
                                 ? cartServices.getTotalPrice(props.cart.products, props.cart.cart)
                                 : 0}{" "}
                              tk{" "} 
                           </span> <br/>

                           {couponDiscount > 0 && (
                              <div>
                                 <span className="checkout-amount-coupon">Coupon Discount: {couponDiscount} tk </span> <br/>
                              </div>
                           )}

                           {couponDetails != undefined && couponDetails.free_shipping == 1 && (
                              <div>
                                 <span className="checkout-amount-coupon">Coupon Discount: Free Delivery </span> <br/>
                              </div>
                           )}

                           <span className="checkout-delivery-fee">Delivery Fee: {deliveryFee ? deliveryFee : 0} tk </span> <br/>
                           <span className="checkout-amount-sub-total">Sub Total: {cartServices.getTotalWithShippingFee(
                                 props.cart.products && props.cart.cart
                                    ? cartServices.getTotalPrice(props.cart.products, props.cart.cart)
                                    : 0,
                                 deliveryFee ? deliveryFee : 0
                              ) - couponDiscount}
                              tk 
                           </span>  <br/>
                           

                        </div> 

                        {/* <p className="delivery-reduce"> (Shop for 200tk more to reduce delivery fee) </p> */}
                     </Col>

                     <hr style={{ width: "95%" }} />
                     <Col xl={12} lg={12} md={12} sm={12} xs={12} className="third-section">
                        <Link to="/shopping-cart">
                           <Button className="back">
                              <span className="btn-text">
                                 <img src="/images/arrow-rightt.png" alt="shop_stick" /> Back{" "}
                              </span>
                           </Button>
                        </Link>
                        <Link to="/">
                           <Button className="cancel-order">
                              <span className="btn-text"> Cancel Order </span>
                           </Button>
                        </Link>

                        { isPaymentAble==true ? (
                           <Button onClick={onProceed} className="proceed-shipping-btn">
                              <span className="proceed-btn-text"> Proceed </span>
                           </Button> ) : (
                           <Button className="proceed-shipping-btn" disabled >
                              <span className="proceed-btn-text"> Proceed </span>
                           </Button>
                        )}
                        
                     </Col>
                  </Row>
               </Col>
            </Row>
         </div>
         {isAddNewAddressModalOpen && (
            <AddAddress addressId={"0"} initialAddress={{}} setIsAddAddressModalOpen={setIsAddNewAddressModalOpen} />
         )}
         
         <Modal show={showLoadSpinner} centered className="checkout-modal">
            <SpinnerLocal />
         </Modal>
      </div>
   );
};
const mapStateToProps = ({ cart, user, address }) => ({
   user,
   cart,
   address,
});

export default connect(mapStateToProps)(Checkout);
