import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap";
import LanguageText from "components/common/languageText";
import ProductDataAccess from "dataAccess/productDataAccess";
import { connect, useDispatch } from "react-redux";
import { actions as cartActions } from "@redux/cartRedux";
import { actions as favoriteActions } from "@redux/favoriteRedux";
import cartServices from "services/cartServices";
import favoriteServices from "services/favouriteServices";
import { isUserLoggedIn } from "services/userServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import { Link } from "react-router-dom";
import SpinnerLocal from "components/spinner";
import Rating from "react-rating";
import ReviewDataAccess from "dataAccess/reviewDataAccess";
import ReactHtmlParser from 'react-html-parser';
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const ProductDetails = (props: any) => {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   const [product, setProduct] = useState(undefined);
   const [productLargeImage, setProductLargeImage] = useState(undefined);
   const [productSmallImages, setProductSmallImages] = useState([]);
   const [productReviews, setProductReviews] = useState(undefined);
   const productId = props.match.params.productId;
   const dispatch = useDispatch();
   let totalImages = 1;

   useEffect(() => {
      ProductDataAccess.getProductDetails(productId)
         .then((data) => {
            setProductLargeImage(data.images[0]);
            let smallImage=[];
            for(let i=0; i<=4; i++)
            {
               if(data.images[i].length > 70)
               {
                  smallImage.push(data.images[i]);
               }
            }
            setProductSmallImages(smallImage);
            setProduct(data);
            setProductReviews(data.review_list);
         })
         .catch((error) => {});
   }, [productId]);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   const getVisibleSlides = () => {
      if (windowWidth > 1400) {
         return 2;
      } else if (windowWidth > 1200) {
         return 2;
      } else if (windowWidth > 1000) {
         return 1;
      } else if (windowWidth > 800) {
         return 1;
      } else {
         return 1;
      }
   };

   const toggleProductLargeImage = (image) => {
      setProductLargeImage(image);
   };

   const requestToStock = (productId) => {
      ProductDataAccess.ProductRequestToStock(productId)
      .then((data)=>{
          toast.success(data);
      })
      .catch((error)=>{
          toast.error("Login first and send request again");
      });
   };

   if (product == undefined || productReviews == undefined ) {
      return <span> </span>;
   }
   return (
      <div className="product-details-section">
         <Helmet>
            <title> {product.name} | Shopstick </title>
            <meta name="description" content="Product Details"/>
         </Helmet>
         <Container>
            <Row>
               <Col xl={8} lg={8} md={8} sm={12} xs={12} className="product-section">
                  <Row>
                     <Col>
                        <p>
                           <Link to="/allProducts">
                              {" "}
                              <span className="product-link">
                                 {" "}
                                 All Products &nbsp; <img src="/images/arrow-right2.png" />
                              </span>{" "}
                           </Link>

                           <Link to={ "/our-products/" + product.category_list[0].slug }>
                              <span className="product-link">
                                 {" "}
                                 &nbsp; {product.category_list[0].name} &nbsp; <img src="/images/arrow-right2.png" />
                              </span>
                           </Link>
                           
                           <span className="product-link"> &nbsp; {product.name} &nbsp; </span>
                        </p>
                     </Col>
                  </Row>
                  <Row className="img-and-des">
                     <Col xl={6} lg={6} md={6} sm={6} xs={6} className="product-image">
                        <div className="product-large">
                           <div className="image">
                              <img src={productLargeImage} alt="shopstick" className="big-image"></img>
                           </div>
                        </div>
                        <CarouselProvider
                           naturalSlideWidth={5}
                           naturalSlideHeight={5}
                           totalSlides={productSmallImages.length}
                           visibleSlides={4}
                           isIntrinsicHeight={true}
                        >
                           <div className="small-image-slider">
                              <div className="products-row">
                                 <ButtonBack className="previous">
                                    <img src="/images/arroleft.png" alt="shopstick" className="first" />
                                 </ButtonBack>
                                 <Slider className="slider">
                                    {productSmallImages.map((image, index) => (
                                       
                                          <Slide index={index} className="slide">
                                             <img src={image} alt="shopstick" className="small-img" onClick={()=>{toggleProductLargeImage(image)}}></img>
                                          </Slide>
                                    ))}
                                 </Slider>
                                 <ButtonNext className="next">
                                    <img src="/images/arrowrr.png" alt="shopstick" className="last" />
                                 </ButtonNext>
                              </div>
                           </div>
                        </CarouselProvider>
                     </Col>
                     <Col xl={6} lg={6} md={6} sm={6} xs={6} className="product-description">
                        <h2>{product.name}</h2>
                        {product.avg_rating == 0 ? (
                           " "
                        ) : (
                           <div className="rating-star" style={{color: "#F9C732", fontSize:"12px"}}>
                              <Rating
                                 emptySymbol="fa fa-star-o fa-2x"
                                 fullSymbol="fa fa-star fa-2x"
                                 fractions={2}
                                 initialRating={product.avg_rating.toFixed(2)}
                                 readonly={true}
                              />
                           </div>
                        )}
                        <div className="review-total"> {product.review_count} Reviews </div>
                        <div className="share-with"> Share with  &nbsp;
                           <a href="https://www.facebook.com/ShopstickKhulna" target="_blank"> <img src="/images/facebookw.png" alt="img"></img> </a> &nbsp;
                           <img src="/images/twitter.png" alt="img"></img> &nbsp;
                           <img src="/images/linkedin.png" alt="img"></img> &nbsp;
                        </div>
                        <div>
                           <span> Shop: {product.vendor.shop_name} </span> <br/>
                           <span> Shop Rating: 
                                 <span> { product.vendor.total_review > 0 ? (
                                     <span> ( {product.vendor.total_review} ) </span>
                                     ) : (
                                        <span> Not rated </span>
                                    )}
                                 </span>
                            </span>
                        </div>
                        <br />
                        {favoriteServices.isProductOnFavorite(product.id, props.favorite.favorite) ? (
                           <div
                              onClick={() => {
                                 dispatch(
                                    favoriteActions.removeProductFromFavorite(
                                       product.id,
                                       isUserLoggedIn(props.user.user)
                                    )
                                 );
                              }}
                              className="favorite-icon"
                           >
                              <img
                                 className="like"
                                 src={process.env.PUBLIC_URL + "/images/likered.png"}
                                 alt="shopStick"
                              />
                           </div>
                        ) : (
                           <div
                              onClick={() => {
                                 dispatch(
                                    favoriteActions.addProductToFavorite(product.id, isUserLoggedIn(props.user.user))
                                 );
                              }}
                              className="favorite-icon"
                           >
                              <img className="like" src={process.env.PUBLIC_URL + "/images/like.png"} alt="shopStick" />
                           </div>
                        )}
                        <br />
                        <p className="price">
                           {product.special_price == 0 ? product.price : product.special_price } tk &nbsp;
                           {product.special_price != 0 && <del className="product-before-price"> {product.price} tk </del> }
                        </p>
                        {/* 
                        <p className="size">
                           {" "}
                           Size: &nbsp;
                           <span className="p-size"> 39 </span> &nbsp;
                           <span className="p-size"> 40 </span> &nbsp;
                           <span className="p-size"> 41 </span> &nbsp;
                           <span className="p-size"> 42 </span> &nbsp;
                        </p>
                        <p className="color">
                           {" "}
                           Color: &nbsp;
                           <span className="red"></span> &nbsp;
                           <span className="green"></span> &nbsp;
                           <span className="yellow"></span> &nbsp;
                           <span className="orange"></span> &nbsp;
                        </p> */}

                        <div className="price-and-cart-btn">
                        {
                           product.quantity === 0 ? (
                              <div>
                                    <p className="request-to-stock-product-details" onClick={()=>{ requestToStock(product.id) }}>
                                       <span className="request-to-stock-btn-text-product-details">Request to Stock</span>
                                    </p>
                                    <span className="out-of-stock"> Out Of Stock </span>
                              </div>
                           ) : (
                              cartServices.isProductOnCart(product.id, props.cart) ? (
                                 <p className="cart-btn-after-click">
                                    <span className="after-click">
                                       <span
                                          className="cart-minus-sign"
                                          onClick={() => {
                                             props.dispatch(
                                                cartActions.decreaseProductQuantity(
                                                   product.id,
                                                   cartServices.getCartQuantity(product.id, props.cart),
                                                   isUserLoggedIn(props.user.user)
                                                )
                                             );
                                          }}
                                       >
                                          <FontAwesomeIcon icon="minus" />
                                       </span>
                                       &nbsp;&nbsp;&nbsp;&nbsp;
                                       <span>{cartServices.getCartQuantity(product.id, props.cart)}</span>
                                       &nbsp;&nbsp;&nbsp;&nbsp;
                                       <span
                                          className="cart-plus-sign"
                                          onClick={() => {
                                             cartServices.getCartQuantity(product.id, props.cart) === product.quantity ? (
                                                toast.error("stock out")
                                             ) : (
                                                dispatch(
                                                   cartActions.increaseProductQuantity(
                                                      product.id,
                                                      cartServices.getCartQuantity(product.id, props.cart),
                                                      isUserLoggedIn(props.user.user)
                                                   )
                                                )
                                             )
                                          }}
                                       >
                                          <FontAwesomeIcon icon="plus" />
                                       </span>
                                    </span>
                                 </p>
                              ) : (
                                 <p
                                    className="cart-button"
                                    onClick={() => {
                                       dispatch(cartActions.addProductToCart(product.id, isUserLoggedIn(props.user.user)));
                                    }}
                                 >
                                    <span className="before-click">
                                       <span className="cart">
                                          <img
                                             className="cart-image"
                                             src={process.env.PUBLIC_URL + "/images/cart-blue.png"}
                                             alt="shopStick"
                                          />
                                       </span>
                                       <span className="cart responsive-width">Add to Cart</span>
                                    </span>
                                 </p>
                              )
                           )
                        }
                        </div>
                     </Col>
                  </Row>
                  <Row>
                     <Col className="tab-menu">
                        <hr />
                        <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
                           <Tab eventKey="details" title="Details">
                              <div>
                                 <br></br>
                                 <h3> Description: </h3>
                                 {product.description == null ? (
                                    <p> Description not added. </p>
                                 ) : (
                                    <div> {ReactHtmlParser(product.description)} </div>
                                 )}
                                 <p style={{marginTop:"20px", }}>
                                    Category: {product.category_list.map((category) => (
                                       <b> {category.name} &nbsp; </b>
                                    ))}
                                 </p>
                                 <p> Status: {product.quantity === 0 ? <b>Out Of Stock</b> : <b>In Stock</b>} </p>{" "}
                                 <br></br>
                              </div>
                           </Tab>
                           <Tab eventKey="reviews" title="Reviews">
                              {  productReviews.length === 0 ? (
                                 " Review is empty "
                              ) : (
                                 productReviews.map((data, index)=>(
                                    <Row key={index} style={{marginTop:"30px", }}> 
                                       <Col xs="auto">
                                          <div> 
                                             <img src={data.user.image} alt="userImage" style={{width:"80px", height:"80px", borderRadius:"80px", }} /> 
                                          </div>
                                       </Col>
                                       <Col>
                                          <b>{data.user.name}</b> reviews &nbsp;
                                          <span style={{ color: "#F9C732", fontSize:"10px" }}>
                                             <Rating
                                                emptySymbol="fa fa-star-o fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={1}
                                                readonly={true}
                                                initialRating={data.rating}
                                             />
                                             <span style={{fontSize:"20px", color:"#40bfff", paddingLeft:"8px" }}>{data.rating}</span>
                                          </span> <br/>
                                          {data.description}
                                          <p>{data.created_at.substring(0, 10)}</p>
                                       </Col>
                                    </Row>
                                 ))
                                 
                              )}
                           </Tab>
                        </Tabs>
                     </Col>
                  </Row>
               </Col>
               <Col xl={4} lg={4} md={4} sm={6} xs={6} className="recommended-section">
                  <Row>
                     <Col className="what-shopstick-have-more">
                        <p className="title"> What shopstick have more? </p>
                        <Row className="more">
                           <Col xs="auto" className="item-img">
                              <img src="/images/payment.png" alt="shopstick" />
                           </Col>
                           <Col className="description">
                              <span className="have-one"> Cash on & Home delivery </span> <br />
                              <span className="time"> Within 3 - 7 days </span>
                           </Col>
                        </Row>
                        <Row className="more">
                           <Col xs="auto" className="item-img">
                              <img src="/images/car-123.png" alt="shopstick" />
                           </Col>
                           <Col className="description">
                              <span className="have-one"> 7 days returns </span> <br />
                              <span className="time"> Don't use the product </span>
                           </Col>
                        </Row>
                        <Row className="more">
                           <Col xs="auto" className="item-img">
                              <img src="/images/securepay.png" alt="shopstick" />
                           </Col>
                           <Col className="description">
                              <span className="have-one"> Warranty </span> <br />
                              <span className="time"> Not available </span>
                           </Col>
                        </Row>
                     </Col>
                  </Row>
                  <Row>
                     <Col className="recommended-for-u">
                        <p style={{ color: "#898C96", fontSize: "18px" }}> Recommended for you </p>
                        {
                        product.recommended_product.data.map((relatedProduct, index) => (
                           <Row className="recommended-item" key={index}>
                              <Col xs="auto" className="product">
                                 <Link to={"/product-details/" + relatedProduct.slug}>
                                    <img src={relatedProduct.image1} alt="shopstick" className="image" />
                                 </Link>
                              </Col>
                              <Col className="description">
                                 <h5> {relatedProduct.name.substring(0, 20)}... </h5>
                                 <div className="rating-star" style={{color: "#F9C732", fontSize:"8px"}}>
                                    <Rating
                                       emptySymbol="fa fa-star-o fa-2x"
                                       fullSymbol="fa fa-star fa-2x"
                                       fractions={2}
                                       initialRating={
                                          relatedProduct.avg_rating[0] != undefined && 
                                          relatedProduct.avg_rating[0].rating > 0 ? relatedProduct.avg_rating[0].rating : 0 }
                                       readonly={true}
                                    />
                                    <span style={{ color: "#40BFFF", fontSize: "18px" }}> &nbsp; {
                                          relatedProduct.avg_rating[0] != undefined && 
                                          relatedProduct.avg_rating[0].rating > 0 ? relatedProduct.avg_rating[0].rating : 0 } </span>
                                 </div>
                                 <p style={{ color: "#40BFFF", fontSize: "25px", paddingTop: "8px" }}>
                                    <b> {relatedProduct.special_price == 0 ? relatedProduct.price : relatedProduct.special_price } tk </b> &nbsp; 
                                    {relatedProduct.special_price != 0 && <del className="product-before-price"> {relatedProduct.price} tk </del> }
                                 </p>
                              </Col>
                           </Row>
                        ))}
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Container>
      </div>
   );
};

const mapStateToProps = ({ cart, favorite, user }) => {
   return { cart: cart.cart, favorite, user };
};
export default connect(mapStateToProps)(ProductDetails);
