import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { InputFieldWithoutLabel } from "components/form/inputRenderes";
import { TextAreaField } from "components/form/inputRenderes";
import { email, required } from "services/validators";
import { Button, Col, Card, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import userDataAccess from "dataAccess/userDataAccess";
import { Helmet } from "react-helmet";

function ContactUs(props: any) {
   const handleFormSubmit = (data) => {
      return userDataAccess
         .contactUs(data)
         .then((data) => {
            toast.success("Message send successfully");
            props.history.push("/");
         })
         .catch((error: Error) => {
            throw new SubmissionError({ _error: error.message });
         });
   };
   return (
      <div className="contact-us-top">
         <Helmet>
            <title> Shopstick | Contact </title>
            <meta name="description" content="Contact With Shopstick"/>
         </Helmet>
         <Row className="contact-us-row">
            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
               <Card className="text-center contact-us-card">
                  <img src="/images/ccall.png" className="contact-us-image" alt="category" />
                  <Card.Body>
                     <Card.Title className="card-title">Call us</Card.Title>
                     <Card.Text className="contact-card-text">
                        +88 01923 188 188
                        <br />
                        +88 01923 199 199
                        <br />
                        +88 01923 211 211
                        <br />
                     </Card.Text>
                  </Card.Body>
               </Card>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
               <Card className="text-center contact-us-card">
                  <img src="/images/cmap.png" className="contact-us-image" alt="category" />
                  <Card.Body>
                     <Card.Title className="card-title">Visit us</Card.Title>
                     <Card.Text className="contact-card-text">
                        Sonadanga Main Road, Sonadanga, Khulna, Bangladesh 
                     </Card.Text>
                  </Card.Body>
               </Card>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
               <Card className="text-center contact-us-card">
                  <img src="/images/cmail.png" className="contact-us-image" alt="category" />
                  <Card.Body>
                     <Card.Title className="card-title">Mail us</Card.Title>
                     <Card.Text className="contact-card-text">info@shopstick.com.bd</Card.Text>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         <div className="create-account-container contact-us-bottom">
            <div className="create-account-header">Contact Us</div>
            <form className="create-form" onSubmit={props.handleSubmit((data) => handleFormSubmit(data))}>
               <Field
                  name="email"
                  type="text"
                  component={InputFieldWithoutLabel}
                  placeholder="Your email"
                  validate={[required, email]}
               />
               <Field
                  name="subject"
                  type="text"
                  component={InputFieldWithoutLabel}
                  placeholder="Subject"
                  validate={[required]}
               />
               <Field
                  name="message"
                  type="text"
                  component={TextAreaField}
                  placeholder="Your message"
                  validate={[required]}
               />
               {props.error && (
                  <div>
                     <strong style={{ color: "red" }}>{props.error}</strong>
                  </div>
               )}
               <Button disabled={props.submitting} className="contact-btn" type="submit">
                  Send
               </Button>
            </form>
         </div>
         <div className="google-map-our-location">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2186.682849750298!2d89.54256189795133!3d22.819223570102633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff9aaf52672c3d%3A0x7457988431037161!2sSonadanga%20R%2FA%2C%20(2nd%20Phase).%2C%20Khulna!5e0!3m2!1sen!2sbd!4v1607668369293!5m2!1sen!2sbd" 
               width="100%" height="450" frameBorder="0" style={{border:"0"}} aria-hidden="false" >
            </iframe>
         </div>
      </div>
   );
}

const ContactUsForm = reduxForm({ form: "ContactUs" })(ContactUs);

export default ContactUsForm;
