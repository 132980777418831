import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Row, Form, Table, Button } from "react-bootstrap";
import ProfileSidebar from "components/profileSidebar/sidebar";
import MyActivity from "components/profileSidebar/activity";
import { Link } from "react-router-dom";
import userDataAccess from "dataAccess/userDataAccess";
import AddAddress from "./addAddress";
import { actions as addressActions } from "@redux/addressRedux";
import { Helmet } from "react-helmet";

const ShippingAddress = (props) => {
   const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
   const [selectedAddress, setSelectedAddress] = useState(undefined);
   
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(addressActions.getAddressList());
   }, [dispatch]);

   return (
      <div className="user-profile-top">
         <Helmet>
            <title> Shopstick | Address </title>
            <meta name="description" content="My Shopstick Shipping Addresses"/>
         </Helmet>
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col md={3} sm={12} className="sideNav">
                  <ProfileSidebar />
               </Col>
               <Col md={9} sm={12} className="sideNav">
                  <MyActivity />
                  <div className="sideNav-container sideNav-container-bottom">
                     <Button
                        onClick={() => {
                           setSelectedAddress(undefined);
                           setIsAddAddressModalOpen(true);
                        }}
                        className="btn-primary address"
                     >
                        Add address
                     </Button>

                     <p className="bottom-header">Addresses</p>
                     <hr className="solid"></hr>
                     <Form className="orders">
                        <Table responsive className="order-table">
                           <thead>
                              <tr className="t-row">
                                 <th>Name</th>
                                 <th>City</th>
                                 <th>Area</th>
                                 <th>Postal Code</th>
                                 <th>Details</th>
                                 <th>Actions</th>
                              </tr>
                           </thead>
                           <tbody>
                              {props.address.addressList.map((data, index) => (
                                 <tr className="t-row" key={index}>
                                    <td>
                                       <p className="payment-type">{data.name}</p>
                                    </td>
                                    <td>
                                       <p className="payment-type">{data.city}</p>
                                    </td>
                                    <td>
                                       <p className="payment-type">{data.area}</p>
                                    </td>
                                    <td>
                                       <p className="payment-type">{data.postal_code}</p>
                                    </td>
                                    <td>
                                       <p className="payment-type">{data.details}</p>
                                    </td>
                                    <td>
                                       <span
                                          className="span"
                                          onClick={() => {
                                             setSelectedAddress(data);
                                             setIsAddAddressModalOpen(true);
                                          }}
                                       >
                                          <img src="images/update.png" alt="shopStick"></img>
                                       </span>
                                       &nbsp; &nbsp; &nbsp;
                                       <span
                                          className="span"
                                          onClick={()=> {dispatch(addressActions.removeAddress(data.id))  }}
                                       >
                                          <img src="images/delete.png" alt="shopStick"></img>
                                       </span>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </Table>
                     </Form>
                  </div>
               </Col>
            </Row>
         </div>
         {isAddAddressModalOpen && (
            <AddAddress
               addressId={selectedAddress ? selectedAddress.id : "0"}
               initialAddress={selectedAddress ? selectedAddress : undefined }
               setIsAddAddressModalOpen={setIsAddAddressModalOpen}
            />
         )}
      </div>
   );
};
const mapStateToProps = ({ address }) => ({ address });
export default connect(mapStateToProps) (ShippingAddress);
