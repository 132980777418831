import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import SpinnerLocal from "components/spinner";
import ProductBox from "./productBox";

class ProductsList extends React.Component<any> {
   render() {
      return (
         <Row>
            {this.props.product.products.map((productItem, index) => (
               <ProductBox productItem={productItem} key={index} />
            ))}
            {this.props.product.productsLoading && <SpinnerLocal />}
            {this.props.product.productsError && <div>{this.props.product.productsErrorMessage}</div>}
         </Row>
      );
   }
}
const mapStateToProps = ({ product, cart, favorite, user }) => {
   return { product, favorite, user };
};

export default connect(mapStateToProps)(ProductsList);
