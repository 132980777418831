import Axios, { AxiosError, AxiosResponse } from "axios";
import { User } from "models/user";
import { getAuthHeader } from "services/userServices";
import { convertObjectToFormData } from "services/util";
import {
   linkAuth,
   linkLogin,
   linkSignUp,
   linkUpdateProfile,
   linkChangePassword,
   linkContactUs,
   linkAddAddress,
   linkUpdateAddress,
   linkAddressList,
   linkRemoveAddress,
   linkUpdateAvatar,
   linkGetUserDetails,
   linkOTPResend,
   linkVerifyOTP,
   linkGetUserDashboard,
   linkGetUserAllOrders,
   linkGetCoupons,
   linkForgotPassword,
   linkUserResetPassword,
   linkGetCityList,
   linkGetUserAreaList,
} from "./apiEndpoints";
import { dateToString, stringToDate } from "services/dateTimeService";
class userDataAccess {
   auth = () => {
      return Axios.get(linkAuth, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   login = (userLoginInfo: User) => {
      const _user = convertObjectToFormData(userLoginInfo);
      return Axios.post(linkLogin, _user)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   signUp = (user: User) => {
      const _user = convertObjectToFormData(user);
      return Axios.post(linkSignUp, _user)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
   sendOpt = (phoneNumber) => {
      const _data = convertObjectToFormData({ phone_number: phoneNumber });
      return Axios.post(linkOTPResend, _data)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
   verifyOTP = (phoneNumber, verificationCode) => {
      const _data = convertObjectToFormData({ phone_number: phoneNumber, verification_code: verificationCode });
      return Axios.post(linkVerifyOTP, _data)
         .then((response: AxiosResponse) => {
            return response.data;
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   updateProfile = (user) => {
      let _user = Object.assign({}, user);
      _user.dob = dateToString(user.dob);
      _user = convertObjectToFormData(_user);
      return Axios.post(linkUpdateProfile, _user, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               let data = response.data.data;
               data.dob = stringToDate(data.dob);
               return data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   changePassword = (userPasswords) => {
      const _userPasswords = convertObjectToFormData(userPasswords);
      return Axios.post(linkChangePassword, _userPasswords, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   contactUs = (data) => {
      const _data = convertObjectToFormData(data);
      return Axios.post(linkContactUs, _data)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   // add shipping address
   addAddress = (data) => {
      const address = convertObjectToFormData(data);
      return Axios.post(linkAddAddress, address, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   // update shipping address
   updateAddress = (data) => {
      const _data = convertObjectToFormData({ ...data, address_id: data.id });
      return Axios.post(linkUpdateAddress, _data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getAddressList = () => {
      return Axios.get(linkAddressList, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   removeAddress = (addressId: string) => {
      const _data = convertObjectToFormData({ address_id: addressId });
      return Axios.post(linkRemoveAddress, _data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getAddressById(addressId: string) {
      return Axios.get(linkAddressList, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               const address = response.data.data.address.filter((item) => item.id.toString() === addressId);
               if (address.length !== 1) {
                  throw new Error("Address not found");
               } else {
                  return address[0];
               }
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   }


   updateAvatar = (data) => {
      return Axios.post(linkUpdateAvatar, data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   
   getUserDetails = () => {
      return Axios.get(linkGetUserDetails, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getUserDashboard = () => {
      return Axios.get(linkGetUserDashboard, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

   getUserAllOrders = () => {
      return Axios.get(linkGetUserAllOrders, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

   getCoupons = () => {
      return Axios.get(linkGetCoupons, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

   forgotPassword = (phoneNumber) => {
      const _data = convertObjectToFormData({ phone_number: phoneNumber });
      return Axios.post(linkForgotPassword, _data)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   userResetPassword = (phoneNumber, resetToken, newPassword) => {
      const _data = convertObjectToFormData({ phone_number: phoneNumber, reset_code: resetToken, new_password: newPassword  });
      return Axios.post(linkUserResetPassword, _data)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getCityList = () => {
      return Axios.get(linkGetCityList, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

   getUserAreaList = (cityID) => {
      return Axios.get(linkGetUserAreaList+"/"+cityID, { headers: { Authorization: getAuthHeader(), }, })
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

}

export default new userDataAccess();
