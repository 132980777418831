import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Button } from "react-bootstrap";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import OfferDataAccess from "dataAccess/offerDataAccess";

const SpecialOffers = (props: any) => {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   const [specialOffer, setSpecialOffer] = useState([]);
   const [totalOfferProduct, setTotalOfferProduct] = useState(null);
   const [showProductIndex, setShowProductIndex] = useState(-1);
   const [seeAllBtn, setSeeAllBtn] = useState(false);
   
   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   const getVisibleSlides = () => {
      if (windowWidth > 992) {
         return 4;
      } else if (windowWidth > 768) {
         return 3;
      } else if (windowWidth > 576) {
         return 2;
      } else {
         return 1;
      }
   };

   useEffect(() => {
      OfferDataAccess
         .getSpecialOfferProducts()
         .then((data) => {
            setSpecialOffer(data);
            setTotalOfferProduct(data.length);
         })
         .catch((error) => {});
   }, []);

   /*
   const showAllBtn = () => {
      const path = window.location.pathname;
      if(path == "/"){
         return <Link to="/special-offers-all"> <span className="show-all"> Show All </span> </Link>;
      }
      else {
         return " ";
      }
   }; */

   
   return (
         <div className="our-offers">
            <CarouselProvider
               naturalSlideWidth={20}
               naturalSlideHeight={125}
               totalSlides={totalOfferProduct}
               visibleSlides={getVisibleSlides()}
               isIntrinsicHeight={true}
            >
             <Row>
                <Col className="products-row" lg="6">
                   <p className="offer-header">
                      <Link to="/our-offers">Our Special Offers</Link>
                   </p>
                </Col>
                <Col>
                   <div className="slider explore-categories-header">
                      {/* showAllBtn() */}
                      <Link to="/special-offers-all"> <span className="show-all"> Show All </span> </Link>
                      <ButtonBack style={{ background: "transparent", border: "0px" }}>
                         <img src="/images/arrowleft.png" alt="Previous product" />
                      </ButtonBack>
                      &nbsp;
                      <ButtonNext style={{ background: "transparent", border: "0px" }}>
                         <img src="/images/arrowright.png" alt="Next product" />
                      </ButtonNext>
                      &nbsp;
                   </div>
                </Col>
             </Row>
             <Slider style={{ margin: "0px -15px" }}>
                {
                   specialOffer.map((product, index) => (
                      <Slide index={index}>
                         <div 
                           onMouseEnter={() => setShowProductIndex(index)}
                           onMouseLeave={() => setShowProductIndex(-1)} >

                           {showProductIndex === index ? (
                              <Card className="card-offer-mouse-enter">
                                 <Link to="/special-offers-all" className="offer-image">
                                    <img src={ product.image1 } className="image" alt="shopStick" />
                                 </Link>
                                 <Card.Body>
                                    <Card.Title className="product-name">{ product.name }</Card.Title>
                                    <Card.Text>
                                       <span className="product-price">
                                          {product.special_price == 0 ? product.price : product.special_price } tk &nbsp; 
                                          {product.special_price != 0 && <del className="product-before-price"> {product.price} tk </del> }
                                       </span>
                                       <div
                                       className="button"
                                       onMouseEnter={() => {
                                          setSeeAllBtn(true);
                                       }}
                                       onMouseLeave={() => setSeeAllBtn(false)}
                                       >
                                          {seeAllBtn ? (
                                             <Link to="/special-offers-all">
                                                <Button className="see-all-btn" variant="primary">
                                                   See All &nbsp; &nbsp; <img src="/images/arrow-right-white.png" />
                                                </Button>
                                             </Link>
                                          ) : (
                                             <Link to="/special-offers-all">
                                                <Button className="see-all-btn" variant="primary">
                                                   See All &nbsp; &nbsp; <img src="/images/arrow-right.png" />
                                                </Button>
                                             </Link>
                                          )}
                                       </div>
                                    </Card.Text>
                                 </Card.Body>
                              </Card>

                           ) : (
                              <Card className="card-offer">
                                 <Link to="/special-offers-all" className="offer-image-link">
                                    <img src={ product.image1 } className="offer-image" alt="shopStick" />
                                 </Link>
                                 <Card.Body>
                                 <Card.Title className="offer-card-title">{ product.name }</Card.Title>
                                    <Card.Text className="card-offer-text">
                                       {product.special_price == 0 ? product.price : product.special_price } tk &nbsp; 
                                       {product.special_price != 0 && <del className="product-before-price"> {product.price} tk </del> }
                                    </Card.Text>
                                 </Card.Body>
                              </Card>
                           )}

                         </div>
                      </Slide> 
                   ))
                }
             </Slider>
          </CarouselProvider>
      </div>
   );
};
export default SpecialOffers;
