import Axios, { AxiosError, AxiosResponse } from "axios";
import { User } from "models/user";
import { getAuthHeader } from "services/userServices";
import { convertObjectToFormData } from "services/util";
import { linkApplyToken, linkGetShippingCost, linkPostOrder, linkPayment, linkUserOrderDelete } from "./apiEndpoints";
class orderDataAccess {
   postOrder = (orderData) => {
      let _orderData = convertObjectToFormData(orderData);
      return Axios.post(linkPostOrder, _orderData, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               //return response.data.data[0];
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
   payment = (orderId, isOnlinePayment) => {
      let _orderData = convertObjectToFormData({ order_id: orderId, payment_method: isOnlinePayment ? "" : "COD" });
      return Axios.post(linkPayment, _orderData, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (isOnlinePayment && response.data.success) {
               window.location.href = response.data.redirect_url;
            }
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   applyToken = (tokenValue, orderAmount) => {
      console.log("code = ", tokenValue);
      console.log("amount = ", orderAmount);
      let _data = convertObjectToFormData({ code: tokenValue, order_amount: orderAmount });
      return Axios.post(linkApplyToken, _data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   getDeliveryFee = (cityID, promoCode, orderAmount) => {
      //console.log("city=", cityID);
      let _formData = new FormData();
      _formData.append("city_id", cityID);
      _formData.append("code", promoCode);
      _formData.append("order_amount", orderAmount);
      return Axios.post(linkGetShippingCost, _formData, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            return response.data;
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   userOrderDelete = (orderID) => {
      console.log("order-id = ", orderID);
      let _orderData = convertObjectToFormData({ order_id: orderID});
      return Axios.post(linkUserOrderDelete, _orderData, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data.delivery_charge;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

}

export default new orderDataAccess();
