import userDataAccess from "dataAccess/userDataAccess";

const typePrefix = "ADDRESS_";

const Types = {
   addressLoading: typePrefix + "ADDRESS_LOADING",
   addressComplete: typePrefix + "ADDRESS_COMPLETE",
   addressListComplete: typePrefix + "ADDRESS_LIST_COMPLETE",
   addressError: typePrefix + "ADDRESS_ERROR",
   addressDelete: typePrefix + "ADDRESS_DELETE",
};

export const actions = {
   getAddress: (addressId: string) => (dispatch) => {
      dispatch({ type: Types.addressLoading });
      userDataAccess
         .getAddressById(addressId)
         .then((data) => {
            dispatch({ type: Types.addressComplete, payload: data });
         })
         .catch((error) => {
            dispatch({ type: Types.addressError, payload: error.message });
         });
   },

   getAddressList: () => (dispatch) => {
      dispatch({ type: Types.addressLoading });
      userDataAccess
         .getAddressList()
            .then((data) => {
               dispatch({ type: Types.addressListComplete, payload: data.address });
            })
            .catch((error) => {
               dispatch({ type: Types.addressError, payload: error.message });
            });
   },

   removeAddress: (addressId: string) => (dispatch) => {
      userDataAccess.removeAddress(addressId)
         .then((data) => {
            dispatch({ type: Types.addressDelete, payload: addressId });
         })
         .catch((error) => {
            dispatch({ type: Types.addressError, payload: error.message });
         });
   },
};

const initialState = {
   address: {},
   addressList: [],
   addressLoading: true,
   addressError: false,
   addressErrorMessage: "",
};

export const reducer = (state: any = initialState, action: any) => {
   switch (action.type) {

      case Types.addressLoading:
         return { ...state, addressLoading: true, addressError: false };

      case Types.addressComplete:
         return { ...state, addressLoading: false, addressError: false, address: action.payload };

      case Types.addressListComplete:
         return { ...state, addressLoading: false, addressError: false, addressList: action.payload };

      case Types.addressError:
         return { ...state, addressLoading: false, addressError: true, addressErrorMessage: action.payload };

      case Types.addressDelete: 
            let newAddressList = [];
            for (let index = 0; index < state.addressList.length; index++) {
               if (state.addressList[index].id !== action.payload) {
                  newAddressList.push(state.addressList[index]);
               }
            }
            return { ...state, addressList: newAddressList };
          
      default:
         return state;
   }
};
