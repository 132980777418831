import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import ProfileSidebar from "components/profileSidebar/sidebar";
import MyActivity from "components/profileSidebar/activity";
import UserDataAccess from "dataAccess/userDataAccess";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Helmet } from "react-helmet";

const Coupons = () => {
   const[coupons, setCoupons] = useState(null);
   const [copySuccess, setCopySuccess] = useState('');

   useEffect(()=>{
      UserDataAccess.getCoupons()
      .then((data)=>{
         setCoupons(data);
      })
      .catch((error)=>{});
   },[]);


   if(coupons==null){
      return <span> Coupon Data </span>
   }
   return (
      <div className="user-profile-top">
         <Helmet>
            <title> Shopstick | Coupons </title>
            <meta name="description" content="Shopstick Available Coupons" />
         </Helmet>
         <div className="create-account-container contact-us-bottom profile-bottom">
            <Row>
               <Col md={3} sm={12} className="sideNav">
                  <ProfileSidebar />
               </Col>
               <Col md={9} sm={12} className="sideNav">
                  <MyActivity />
                  <div className="sideNav-container sideNav-container-bottom">
                     <p className="bottom-header">Coupons</p>
                     <hr className="solid"></hr>
                     { coupons.map((coupon, index)=>(
                        <Row className="coupons-row" key={index}>
                           <Col>
                              <span className="coupon-date"> {coupon.name} </span> <br/>
                              <span className="coupon-details"> Code: {coupon.code} 
                              <CopyToClipboard text={coupon.code}>
                                 <img src="/images/IconCopy.png" style={{cursor: "pointer", float:"right"}} />
                              </CopyToClipboard>
                              </span> <br/>
                              <span className="coupon-details"> Valid till: {coupon.end_date} </span> <br/>
                              <span className="coupon-details"> Discount: {coupon.value} </span>
                           </Col>
                        </Row>
                     ))}
                  </div>
               </Col>
            </Row>
         </div>
      </div>
   );
};
export default Coupons;
