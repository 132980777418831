import React, { useState } from "react";
import userDataAccess from "dataAccess/userDataAccess";
import { toast } from "react-toastify";

const ForgotPassword = (props: any) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [resetToken, setResetToken] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [isOTPEnabled, setIsOTPEnabled] = useState(true);
    const [passWordTextType, setPassWordTextType] = useState(false);
   
    const enableOTP = () => {
        setTimeout(() => {
           setIsOTPEnabled(true);
        }, 1800000);
     };

     const sendOTP = () => {
        if(phoneNumber=="")
        {
            toast.error("Please enter valid phone number");
        }
        else{
            userDataAccess
               .forgotPassword(phoneNumber)
               .then((data) => {
                  setIsOTPSent(true);
                  setIsOTPEnabled(false);
                  enableOTP();
                  toast.success("OTP sent successfully!");
               })
               .catch((error) => {
                  toast.error("The phone number is not registered");
               });
           }
      };
     

     const resetPassword = () => {
         if(newPassword==confirmNewPassword) {
            userDataAccess
               .userResetPassword(phoneNumber, resetToken, newPassword)
               .then((data) => {
                  toast.success("Password Reset successfully!");
                  props.history.push("/login");
               })
               .catch((error) => {
                  toast.error("Please enter correct OTP");
               });
         }
         else {
            toast.error("Password did not match");
         }
     }; 
     
   const togglePasswordText = () => {
      if(passWordTextType==true)
      {
         setPassWordTextType(false);
      }
      else {
         setPassWordTextType(true);
      }
   };

   return (
      <div className="forgot-password-page"> 
         {isOTPEnabled===true &&
            <div>
               <div className="forgot-password-header">Reset Password</div>
               <div className="create-form">
                  <input 
                  type="text" 
                  value={phoneNumber}
                  name="phone_number" 
                  placeholder="Enter your phone number" 
                  className="phone"
                  onChange={(e) => {
                     if (!isOTPSent) setPhoneNumber(e.target.value);
                  }}
                  /> <br/>
                  <div className="sent-otp-section">
                     <button
                        onClick={() => {
                           sendOTP();
                        }}
                        disabled={!isOTPEnabled}
                        className="send-otp-btn"
                        type="button"
                     >
                        {isOTPSent ? "Resend OTP" : "Send OTP"}
                     </button>
                  </div>

               </div> 
            </div>
         } 

         {
            isOTPEnabled===false &&  
            <div>
               <div className="forgot-password-header">Reset Password</div>
                  <input 
                     type="text" 
                     value={resetToken}
                     name="OTP" 
                     placeholder="OTP"
                     className="reset-password-otp"
                     onChange={(e) => { setResetToken(e.target.value); }}
                  /> <br/>
                  <div style={{position: "relative"}}>
                  <input 
                     type={ passWordTextType == true ? "text"  : "password" }
                     value={newPassword}
                     name="password" 
                     placeholder="New password" 
                     className="new-password"
                     onChange={(e) => {
                        setNewPassword(e.target.value);
                     }}
                  />
                  <button type="button" className="show-password-btn" onClick={()=>{togglePasswordText()}}> show </button>
                  </div> 
                  <input 
                     type={ passWordTextType == true ? "text"  : "password" }
                     value={confirmNewPassword}
                     name="password" 
                     placeholder="Confirm new password" 
                     className="confirm-new-password"
                     onChange={(e) => {
                        setConfirmNewPassword(e.target.value);
                     }}
                  /> <br/>
                  <div className="submit-request">
                     <button
                           onClick={() => {
                              resetPassword();
                           }}
                           className="reset-password-btn"
                           type="button"
                     >
                        Submit
                     </button>
                  </div>
            </div>
         }

      </div>
   );
};

export default ForgotPassword;