import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { InputFieldWithoutLabel } from "components/form/inputRenderes";
import { minLength, required } from "services/validators";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import userDataAccess from "dataAccess/userDataAccess";
import { User } from "models/user";
import { useDispatch } from "react-redux";
import { actions as userActions } from "@redux/userRedux";
import { actions as cartActions } from "@redux/cartRedux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { keys } from "common/constants";
import socialLoginDataAccess from "dataAccess/socialLoginDataAccess";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { Helmet } from "react-helmet";

const minLength6 = minLength(6);
interface ContinueWithProps {
   imgSrc: string;
   imgAlt: string;
   text: string;
}



const Login = (props: any) => {
   const submitting = props.submitting;
   const dispatch = useDispatch();

   const [userFbName, setUserFbName] = useState();
   const [socialLogInProvider, setSocialLogInProvider] = useState(0);
   const [fbUserID, setFbUserID] = useState(0);
   const [userFbImage, setUserFbImage] = useState();
   
   const [userGoogleName, setUserGoogleName] = useState();
   const [googleUserID, setGoogleUserID] = useState(0);
   const [userGoogleEmail, setUserGoogleEmail] = useState();
   const [userGoogleImage, setUserGoogleImage] = useState();

   const responseFacebook = (response) => {
      if(response.status !== 'unknown') {
         console.log("fb response = ", response);
         setUserFbName(response.name);
         setSocialLogInProvider(2);
         setUserFbImage(response.picture.data.url);
         setFbUserID(response.userID);
      }
   };
   const componentClicked = () => {
      //console.log("facebook btn clicked");
   };
   
   
   const responseGoogle=(response)=>{
      if( response.profileObj !== undefined && response.error !== "popup_closed_by_user" ) {
         //console.log("google r1 = ", response);
         //console.log("google r2 = ", response.profileObj);
         setUserGoogleName(response.profileObj.name);
         setSocialLogInProvider(3);
         setUserGoogleEmail(response.profileObj.email);
         setUserGoogleImage(response.profileObj.imageUrl);
         setGoogleUserID(response.profileObj.googleId);
      }
   };

  

   useEffect(()=>{
      socialLoginDataAccess.createAccountByGoogleId(userGoogleName, socialLogInProvider, userGoogleEmail, userGoogleImage, googleUserID)
      .then((data)=>{
         toast.success("Logged in successfully");
         dispatch(userActions.login(data));
         localStorage.setItem(keys.accessTokenKey, data.access_token);
         dispatch(cartActions.addAllProductsToCart(props.cart.cart, true));
         props.history.push("/");
      })
      .catch((error)=>{" "});
   },[googleUserID]);

   useEffect(()=>{
      if(fbUserID != 0){
         socialLoginDataAccess.createAccountByFacebookId(userFbName, socialLogInProvider, userFbImage, fbUserID)
         .then((data)=>{
            toast.success("Logged in successfully");
            dispatch(userActions.login(data));
            localStorage.setItem(keys.accessTokenKey, data.access_token);
            dispatch(cartActions.addAllProductsToCart(props.cart.cart, true));
            props.history.push("/");
         })
         .catch((error)=>{" "});
      }
   },[fbUserID]);

   const handleFormSubmit = (user: User) => {
      return userDataAccess
         .login({ ...user, remember_me: "on" })
         .then((data: User) => {
            toast.success("Logged in successfully");
            localStorage.setItem(keys.accessTokenKey, data.access_token);
            dispatch(cartActions.addAllProductsToCart(props.cart.cart, true));
            dispatch(userActions.login(data));
            props.history.push("/");
         })
         .catch((error) => {
            throw new SubmissionError({ _error: error.message });
         });
   };

   return (
      <div className="create-account-container">
         <Helmet>
            <title> Shopstick | Login </title>
            <meta name="description" content="Shopstick login"/>
         </Helmet>
         <div className="create-account-header">Login</div>
         <form className="create-form" onSubmit={props.handleSubmit((data) => handleFormSubmit(data))}>
            <Field
               name="phone_number"
               type="text"
               component={InputFieldWithoutLabel}
               placeholder="Phone number"
               validate={[required]}
            />
            <Field
               name="password"
               type="password"
               component={InputFieldWithoutLabel}
               placeholder="Password"
               validate={[required, minLength6]}
               className="form-design"
            />
            <div className="forgot">
               <Link to="/forgot-password">
                  <p className="forgot-password">Forgot Password?</p>
               </Link>
            </div>
            {props.error && (
               <div>
                  <strong style={{ color: "red" }}>{props.error}</strong>
               </div>
            )}
            <Button disabled={submitting} className="create-account-btn" type="submit">
               Continue
            </Button>
         </form>
         <div>
            <hr />
            <p className="or">Or</p>
            <hr />
         </div>
         
         <div className="continue-with-row">
            <FacebookLogin
            appId="304547944026923"
            textButton="Continue with facebook"
            cssClass="continue-with-fb-btn"
            upperCase={false}
            autoLoad={false}
            fields="name,email,picture"
            //onClick={componentClicked}
            callback={responseFacebook}
            icon={<i className="fa fa-facebook" style={{margin:'3px 6px 0px 3px', color:"#40bfff", float:"left"}}/>}
            />
            <br/>
            <GoogleLogin
            clientId="997620380831-793ncf7044go7im4216fgqdfj1nlbkf9.apps.googleusercontent.com"
            buttonText="Continue with google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            className="continue-with-google-btn"
            />
         </div> 

         <div className="login">
            Don't have an account?{" "}
            <Link to="/create-account" className="login-text">
               Create Account
            </Link>
         </div>
      </div>
   );
};

const login = reduxForm({ form: "login" })(Login);
const mapStateToProps = ({ cart }) => ({
   cart,
});

export default connect(mapStateToProps)(login);
