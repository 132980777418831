import Axios, { AxiosError, AxiosResponse } from "axios";
import { User } from "models/user";
import { getAuthHeader } from "services/userServices";
import { convertObjectToFormData } from "services/util";
import { linkSocialLogin,} from "./apiEndpoints";

class SocialLoginDataAccess {

   createAccountByGoogleId = (name, provider, email, imageGoogle, googleID ) => {
      const _data = convertObjectToFormData({name:name, provider:provider, social_id:googleID, email:email, image: imageGoogle});
      return Axios.post(linkSocialLogin, _data)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

   createAccountByFacebookId = (name, provider, imageFacebook, facebookID ) => {
      //console.log(name, provider, facebookID, imageFacebook);
      const _data = convertObjectToFormData({name: name, provider: provider, image: imageFacebook, social_id: facebookID});
      return Axios.post(linkSocialLogin, _data)
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };

}
export default new SocialLoginDataAccess();