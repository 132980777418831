import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpecialOffers from "components/ourOffers/specialOffers";
import ProductList from "components/ourproducts/productList";
import { connect, useDispatch } from "react-redux";
import { actions as productActions } from "@redux/productRedux";
import { actions as categoryActions } from "@redux/categoryRedux";
import FilterMultiSelect from "components/form/categoryFilter";
import ShortByFilter from "components/form/shortByFilter";
import SuperSaleProducts from "components/ourOffers/superSale";

const ProductsBySearch = (props: any) => {
   const dispatch = useDispatch();

   const [selectedCategories, setSelectedCategories] = useState(["4", "7"]);
   const [selectedShortByItem, setSelectedShortByItem] = useState([]);
   const [selectedNestle, setSelectedNestle] = useState(["2"]);

   const searchText = props.match.params.searchText;
   useEffect(() => {
      dispatch(productActions.clearProductList());
      dispatch(productActions.getProductsBySearch(searchText));
      //dispatch(categoryActions.getCategory(categoryId));
   }, [searchText, dispatch]);

   let categoryValues = [
      { id: "1", name: "Category1" },
      { id: "2", name: "Category2" },
      { id: "3", name: "Category3" },
      { id: "4", name: "Category4" },
      { id: "5", name: "Category5" },
      { id: "6", name: "Category6" },
      { id: "7", name: "Category7" },
   ];

   let shortByItemValues = [
      { id: "1", name: "Price(Low to High)" },
      { id: "2", name: "Price(High to Low)" },
      { id: "3", name: "Top reviews" },
      { id: "4", name: "Ascending" },
      { id: "5", name: "Descending" },
      { id: "6", name: "Others" },
   ];

   let nestleValues = [
      { id: "1", name: "Modhumoti" },
      { id: "2", name: "Nestle" },
      { id: "3", name: "Basundhora" },
   ];

   return (
      <div className="search-page">
         
         <SuperSaleProducts/>

         <div className="search-page-products">
            <Row>
               <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                  <p className="header">
                     {" "}
                     <Link to="/our-products"> Showing results for "{searchText}" </Link>
                  </p>
                  <p className="our-products-des">
                     ShopStick provides you more than 1000+ products from each category.
                     <br /> You can choose products from here as well as filter also.
                  </p>
               </Col>
            {/*
               <Col xl={8} lg={8} md={12} sm={12} xs={12} className="product-filter-section">
                  <div className="filter-content">
                     
                     <span className="filter-btn-main" style={{display:"inline-block"}}> Nestle
                           <img
                              className="cross"
                              src={process.env.PUBLIC_URL + "/images/cross.png"}
                              alt="shopStick"
                           />
                     </span> 
                     <span className="filter-btn-main">
                        <span>Price</span>
                     </span>

                     <span className="filter-btn-main">
                        <span>Date 0f arrival</span>
                     </span>
                     <span className="filter-btn-main">
                        <span>Search product</span>
                     </span>
                     <FilterMultiSelect
                        label="Category"
                        values={categoryValues}
                        selectedValues={selectedCategories}
                        setSelectedValues={(values) => {
                           setSelectedCategories(values);
                        }}
                        onClear={() => {}}
                        onApply={() => {}}
                     />
                     <FilterMultiSelect
                        label="Nestle"
                        values={nestleValues}
                        selectedValues={selectedNestle}
                        setSelectedValues={(values) => {
                           setSelectedNestle(values);
                        }}
                        onClear={() => {}}
                        onApply={() => {}}
                     />
                     <ShortByFilter
                        label="Short by"
                        values={shortByItemValues}
                        selectedValues={selectedShortByItem}
                        setSelectedValues={(values) => {
                           setSelectedShortByItem(values);
                        }}
                     />
                  </div>
               </Col> */}
            </Row>
            <ProductList />
            {!props.product.productsLoading &&
               props.product.productsInfo.current_page &&
               props.product.productsInfo.current_page !== props.product.productsInfo.last_page && (
                  <Row>
                     <Col className="more-product" style={{textAlign:"center", }}>
                        <Button
                           className="load-more"
                           variant="primary"
                           onClick={() => {
                              dispatch(
                                 productActions.getProductsBySearch(
                                    searchText,
                                    props.product.productsInfo.current_page + 1
                                 )
                              );
                           }}
                        >
                           <span className="btn-text">Load more</span>
                        </Button>{" "}
                     </Col>
                  </Row>
               )}
         </div>

         <div >
            <SpecialOffers/>
         </div>

      </div>
   );
};

const mapStateToProps = ({ product, category }) => ({
   product,
   category,
});

export default connect(mapStateToProps)(ProductsBySearch);
