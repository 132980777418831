import React, { useEffect, useState, useCallback } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpecialOffers from "components/ourOffers/specialOffers";
import ProductList from "components/ourproducts/productList";
import { connect, useDispatch } from "react-redux";
import { actions as productActions } from "@redux/productRedux";
import { actions as categoryActions } from "@redux/categoryRedux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import CategoryFilter from "components/form/categoryFilter";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import ShortByFilter from "components/form/shortByFilter";
import NestleFilter from "components/form/nestleFilter";
import PriceRangeFilter from "components/form/priceRangeFilter";
import DateOfArrivalFilter from "components/form/dateOfArrivalFilter";
import SuperSaleProducts from "components/ourOffers/superSale";
import { Helmet } from "react-helmet";

const ProductsByCategory = (props: any) => {
   const dispatch = useDispatch();

   const [selectedCategories, setSelectedCategories] = useState(["4", "7"]);
   const [selectedShortByItem, setSelectedShortByItem] = useState([]);
   const [selectedNestle, setSelectedNestle] = useState(["2"]);

   const [searchText, setSearchText] = useState("");
   const search = useCallback(() => {
      if (searchText.length > 0) props.history.push("/search/" + searchText);
   }, [props.history, searchText]);

   const categoryId = props.match.params.categoryId;
   useEffect(() => {
      dispatch(productActions.clearProductList());
      dispatch(productActions.getProductsByCategory(categoryId));
      dispatch(categoryActions.getCategory(categoryId));
      //props.history.push("/our-products/" + categoryId);
   }, [categoryId, dispatch]);

   let categoryValues = [
      { id: "1", name: "Category1" },
      { id: "2", name: "Category2" },
      { id: "3", name: "Category3" },
      { id: "4", name: "Category4" },
      { id: "5", name: "Category5" },
      { id: "6", name: "Category6" },
      { id: "7", name: "Category7" },
   ];

   let shortByItemValues = [
      { id: "1", name: "Price(Low to High)" },
      { id: "2", name: "Price(High to Low)" },
      { id: "3", name: "Top reviews" },
      { id: "4", name: "Ascending" },
      { id: "5", name: "Descending" },
      { id: "6", name: "Others" },
   ];

   let nestleValues = [
      { id: "1", name: "Modhumoti" },
      { id: "2", name: "Nestle" },
      { id: "3", name: "Basundhora" },
   ];

   return (
      <div>
         <Helmet>
            <title> {"Shopstick | " + props.category.category.name} </title>
            <meta name="description" content="Product By Category" />
         </Helmet>
         <div className="products-by-category">
            <Row>
               <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                  <p className="header">
                     {props.category.category.name ? props.category.category.name : "Our products"}
                  </p>
                  <p className="our-products-des">
                     ShopStick provides you more than 1000+ products from each category.
                     <br /> You can choose products from here as well as filter also.
                  </p>
               </Col>

               {/*

               {props.user.showFilter && (
                  <Col xl={8} lg={8} md={12} sm={12} xs={12} className="product-filter-section">
                     <div className="filter-content">
                        <Row>
                           <Col>
                              <DateOfArrivalFilter
                                 label="Date of arrivals"
                                 onClear={() => {}}
                                 onApply={() => {}}
                              />
                              <PriceRangeFilter label="Price" onClear={() => {}} onApply={() => {}} />
                              <NestleFilter
                                 label="Nestle"
                                 values={nestleValues}
                                 selectedValues={selectedNestle}
                                 setSelectedValues={(values) => {
                                    setSelectedNestle(values);
                                 }}
                                 onClear={() => {}}
                                 onApply={() => {}}
                              />
                              <CategoryFilter
                                 label="Category"
                                 values={categoryValues}
                                 selectedValues={selectedCategories}
                                 setSelectedValues={(values) => {
                                    setSelectedCategories(values);
                                 }}
                                 onClear={() => {}}
                                 onApply={() => {}}
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <ShortByFilter
                                 label="Short by"
                                 values={shortByItemValues}
                                 selectedValues={selectedShortByItem}
                                 setSelectedValues={(values) => {
                                    setSelectedShortByItem(values);
                                 }}
                              />
                              <div className="search-at-filter-section">
                                 <div className="search-at-filter">
                                    <input
                                       className="search-input"
                                       value={searchText}
                                       onChange={(event) => {
                                          setSearchText(event.target.value);
                                       }}
                                       type="text"
                                       onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                             search();
                                          }
                                       }}
                                       placeholder="Search your needs..."
                                    />
                                    <img
                                       onClick={search}
                                       style={{ height: "28px" }}
                                       alt="search button"
                                       src="/images/search_with_back.png"
                                    />
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  </Col>
               )}
               */}
            </Row>

            <ProductList />

            {  !props.product.productsLoading &&
               props.product.productsInfo.current_page &&
               props.product.productsInfo.current_page !== props.product.productsInfo.last_page && (
                  <Row style={{textAlign:"center", marginTop:"20px", }}>
                     <Col>
                        <Button
                           className="load-more"
                           variant="primary"
                           onClick={() => {
                              dispatch(
                                 productActions.getProductsByCategory(
                                    categoryId,
                                    props.product.productsInfo.current_page + 1
                                 )
                              );
                           }}
                        >
                           <span className="btn-text">Load more</span>
                        </Button>{" "}
                     </Col>
                  </Row>
               )
            }
         </div>
         <SpecialOffers />
      </div>
   );
};

const mapStateToProps = ({ product, category, user }) => ({
   product,
   category,
   user,
});

export default connect(mapStateToProps)(ProductsByCategory);
