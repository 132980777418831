/**
 * Created by Atikur Rahman Sabuj on 02/08/2020.
 *
 * @format
 */

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as userReducer } from "./userRedux";
import { reducer as testReducer } from "./testRedux";
import { reducer as productReducer } from "./productRedux";
import { reducer as cartReducer } from "./cartRedux";
import { reducer as FavoriteReducer } from "./favoriteRedux";
import { reducer as AddressReducer } from "./addressRedux";
import { reducer as CategoryReducer } from "./categoryRedux";

export default combineReducers({
   user: userReducer,
   test: testReducer,
   product: productReducer,
   cart: cartReducer,
   favorite: FavoriteReducer,
   address: AddressReducer,
   category: CategoryReducer,
   form: formReducer,
});
