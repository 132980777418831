/**
 * Created by Atikur Rahman Sabuj on 30/10/2020.
 *
 * @format
 */

import productDataAccess from "dataAccess/productDataAccess";
import { Dispatch } from "redux";

const typesPrefix = "PRODUCT_";
const types = {
   productsLoadingStart: typesPrefix + "PRODUCTS_LOADING_START",
   productsLoadingComplete: typesPrefix + "PRODUCTS_LOADING_COMPLETE",
   productsLoadingError: typesPrefix + "PRODUCTS_LOADING_ERROR",
   homeProductsLoadingStart: typesPrefix + "HOME_PRODUCTS_LOADING_START",
   homeProductsLoadingComplete: typesPrefix + "HOME_PRODUCTS_LOADING_COMPLETE",
   allProductsLoadingComplete: typesPrefix + "ALL_PRODUCTS_LOADING_COMPLETE",
   homeProductsLoadingError: typesPrefix + "HOME_PRODUCTS_LOADING_ERROR",
   clearProducts: typesPrefix + "CLEAR_PRODUCTS",
   productLoadingStart: typesPrefix + "PRODUCT_LOADING_START",
   productLoadingComplete: typesPrefix + "PRODUCT_LOADING_COMPLETE",
   productLoadingError: typesPrefix + "PRODUCT_LOADING_ERROR",
};

export const actions = {
   clearProductList: () => (dispatch: Dispatch) => {
      dispatch({ type: types.clearProducts });
   },
   //ToDo:: Update with proper parameters
   getProducts: (categoryId: string) => (dispatch: Dispatch) => {
      dispatch({ type: types.productsLoadingStart });
      productDataAccess
         .getProducts()
         .then((data) => {
            dispatch({ type: types.productsLoadingComplete, payload: data });
         })
         .catch((error: Error) => {
            dispatch({ type: types.productsLoadingError, payload: error.message });
         });
   },
   getProductsByCategory: (categoryId: string, page = 1, perPage = 10) => (dispatch: Dispatch) => {
      dispatch({ type: types.productsLoadingStart });
      productDataAccess
         .getProductsByCategory(categoryId, page, perPage)
         .then((data) => {
            dispatch({ type: types.productsLoadingComplete, payload: data });
         })
         .catch((error: Error) => {
            dispatch({ type: types.productsLoadingError, payload: error.message });
         });
   },

   getAllProducts: (page = 1) => (dispatch: Dispatch) => {
      dispatch({ type: types.productsLoadingStart });
      productDataAccess
         .getAllProducts(page)
         .then((data) => {
            //console.log("all products = ", data);
            dispatch({ type: types.allProductsLoadingComplete, payload: data });
         })
         .catch((error: Error) => {
            dispatch({ type: types.productsLoadingError, payload: error.message });
         });
   },

   getProductsBySearch: (searchText: string, page = 1, perPage = 10) => (dispatch: Dispatch) => {
      dispatch({ type: types.productsLoadingStart });
      productDataAccess
         .getProductsBySearch(searchText, page, perPage)
         .then((data) => {
            dispatch({ type: types.productsLoadingComplete, payload: { products: data } });
         })
         .catch((error: Error) => {
            dispatch({ type: types.productsLoadingError, payload: error.message });
         });
   },

   getProductDetails: (productId: string) => (dispatch: Dispatch) => {
      dispatch({ type: types.productLoadingStart });
      productDataAccess
         .getProductDetails(productId)
         .then((data) => {
            dispatch({ type: types.productLoadingComplete, payload: data });
         })
         .catch((error: Error) => {
            dispatch({ type: types.productLoadingError, payload: error.message });
         });
   },

   getHomePageProducts: () => (dispatch) => {
      dispatch({ type: types.homeProductsLoadingStart });
      productDataAccess
         .getHomePageProducts()
         .then((data) => {
            dispatch({ type: types.homeProductsLoadingComplete, payload: data });
         })
         .catch((error: Error) => {
            dispatch({ type: types.homeProductsLoadingError, payload: error.message });
         });
   },
};

const initialState = {
   products: [],
   productsLoading: true,
   productsError: false,
   productsInfo: {},
   homeProducts: [],
   homeProductsLoading: true,
   homeProductsError: false,
   productsErrorMessage: "",
   product: {},
   productLoading: true,
   productError: false,
   productErrorMessage: "",
};

interface Action {
   type: string;
   payload: any;
}
export const reducer = (state: any = initialState, action: Action) => {
   switch (action.type) {

      case types.clearProducts:
         return { ...state, products: [] };

      case types.productsLoadingStart:
         return { ...state, productsLoading: true, productsError: false };

      case types.productsLoadingComplete:
         return {
            ...state,
            productsLoading: false,
            products: [...state.products, ...action.payload.products.data],
            productsInfo: action.payload.products,
         };

      case types.allProductsLoadingComplete:
         return {
            ...state,
            productsLoading: false,
            products: [...state.products, ...action.payload.data],
            productsInfo: action.payload,
         };

      case types.productsLoadingError:
         return { ...state, productsLoading: false, productsError: true, productsErrorMessage: action.payload };

      case types.homeProductsLoadingStart:
         return { ...state, homeProductsLoading: true, homeProductsError: false };

      case types.homeProductsLoadingComplete:
         return { ...state, homeProductsLoading: false, homeProducts: action.payload };

      case types.homeProductsLoadingError:
         return { ...state, homeProductsLoading: false, homeProductsError: true, productsErrorMessage: action.payload };

      case types.productLoadingStart:
         return { ...state, productLoading: true, productError: false };

      case types.productLoadingComplete:
         return { ...state, productLoading: false, product: action.payload };

      case types.productLoadingError:
         return { ...state, productLoading: false, productError: true, productErrorMessage: action.payload };

      default:
         return state;
   }
};
