import React from "react";
import { connect } from "react-redux";
import { Container} from "react-bootstrap";
import SpecialOffers from "components/ourOffers/specialOffers";
import RegularOffers from "components/ourOffers/regularOffers";
import { Helmet } from "react-helmet";

const OurOffers = (props: any) => {
  return (
      <div>
        <Helmet>
            <title> { "Shopstick | Our Offers" } </title>
            <meta name="description" content="Shopstick Our Offers"/>
         </Helmet>
         <div>
          <SpecialOffers/>
          <RegularOffers/>
        </div>
      </div>
    );
};

const mapStateToProps = ({  }) => ({ });
export default connect(mapStateToProps)(OurOffers);