import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OfferDataAccess from "dataAccess/offerDataAccess";
import { actions as cartActions } from "@redux/cartRedux";
import { actions as favoriteActions } from "@redux/favoriteRedux";
import cartServices from "services/cartServices";
import favoriteServices from "services/favouriteServices";
import { isUserLoggedIn } from "services/userServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating";
import SuperSaleProducts from "components/ourOffers/superSale";
import { actions as productActions } from "@redux/productRedux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import ProductDataAccess from "dataAccess/productDataAccess";

const RegularOffersAll = (props) => {
    const productItem = props.product;
    const dispatch = useDispatch();

    const [regularOfferProducts, setRegularOfferProducts] = useState([]);
    useEffect(() => {
        OfferDataAccess.getRegularOfferProducts()
           .then((data) => {
                setRegularOfferProducts(data);
           })
           .catch((error) => {});
     }, []);

    const requestToStock = (productId) => {
        ProductDataAccess.ProductRequestToStock(productId)
        .then((data)=>{
            toast.success(data);
        })
        .catch((error)=>{
            toast.error("Login first and send request again");
        });
    }

    return (
        <div>
            <Helmet>
                <title> Shopstick | Regular Offers All </title>
                <meta name="description" content="Regular Offers All"/>
            </Helmet>
            <div className="regular-offer">
                <Row className="top">
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <p className="top-title"> Regular Offers All</p>
                    </Col>
                </Row>

                <Row>
                    { regularOfferProducts.map((product, index) => (
                        <div className="product-card-container" key={index}>
                        <div className="product-card">
                            <div className="card-inner">
                            <div className="card-left">
                                <Link to={"/product-details/" + product.slug} className="product-image-big">
                                    {" "}
                                    <img className="product-image" src={product.image1} alt="shopstick" />{" "}
                                </Link>
                            </div>

                            <div className="card-right">
                                {favoriteServices.isProductOnFavorite(product.id, props.favorite.favorite) ? (
                                    <span
                                        onClick={() => {
                                        dispatch(
                                            favoriteActions.removeProductFromFavorite(product.id, isUserLoggedIn(props.user.user))
                                        );
                                        }}
                                        className="favorite-icon"
                                    >
                                        <img className="like" src={process.env.PUBLIC_URL + "/images/likered.png"} alt="shopStick" />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => {
                                        dispatch(
                                            favoriteActions.addProductToFavorite(product.id, isUserLoggedIn(props.user.user))
                                        );
                                        }}
                                        className="favorite-icon"
                                    >
                                        <img className="like" src={process.env.PUBLIC_URL + "/images/like.png"} alt="shopStick" />
                                    </span>
                                )}
                                <div className="rating-content" style={{ color: "gold" }}>
                                    {product.avg_rating && product.avg_rating.length > 0 && product.avg_rating[0].rating && (
                                        <span style={{ marginRight: "5px" }}>
                                        <Rating
                                            fullSymbol="fa fa-star"
                                            emptySymbol="fa fa-star-o"
                                            readonly
                                            initialRating={product.avg_rating[0].rating}
                                        />
                                        </span>
                                    )}
                                    <span style={{ color: "#40BFFF" }}>
                                        {product.avg_rating && product.avg_rating.length > 0 && product.avg_rating[0].rating
                                        ? product.avg_rating[0].rating
                                        : "not rated"}
                                    </span>
                                </div>
                                <span>
                                    <p className="card-product-name">{product.name}</p>
                                </span>
                                <span className="price-and-cart-btn">
                                    <p className="product-price"> 
                                        {product.special_price == 0 ? product.price : product.special_price } tk &nbsp; 
                                        {product.special_price != 0 && <del className="product-before-price"> {product.price} tk </del> }
                                    </p>
                                    
                                    { product.quantity === 0 ? (
                                        <div>
                                            <p className="request-to-stock-button" onClick={()=>{ requestToStock(product.id) }}>
                                                <span className="request-to-stock-btn-text">Request to Stock</span>
                                            </p>
                                            <span className="out-of-stock"> Out Of Stock </span>
                                        </div>
                                    ) : (
                                        cartServices.isProductOnCart(product.id, props.cart) ? (
                                            <p className="cart-btn-after-click">
                                            <span className="after-click">
                                                <span
                                                    className="cart-minus-sign"
                                                    onClick={() => {
                                                        props.dispatch(
                                                        cartActions.decreaseProductQuantity(
                                                            product.id,
                                                            cartServices.getCartQuantity(product.id, props.cart),
                                                            isUserLoggedIn(props.user.user)
                                                        )
                                                        );
                                                    }}
                                                >
                                                <FontAwesomeIcon icon="minus" />
                                                </span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>{cartServices.getCartQuantity(product.id, props.cart)}</span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span
                                                    className="cart-plus-sign"
                                                    onClick={() => {
                                                        cartServices.getCartQuantity(product.id, props.cart) === product.quantity ? (
                                                            toast.error("stock out")
                                                            ) : (
                                                            dispatch(
                                                                cartActions.increaseProductQuantity(
                                                                    product.id,
                                                                    cartServices.getCartQuantity(product.id, props.cart),
                                                                    isUserLoggedIn(props.user.user)
                                                                )
                                                            )
                                                        )
                                                    }}
                                                >
                                                <FontAwesomeIcon icon="plus" />
                                            </span>
                                        </span>
                                        </p>
                                        ) : (
                                        <p
                                        className="cart-button"
                                        onClick={() => {
                                            dispatch(cartActions.addProductToCart(product.id, isUserLoggedIn(props.user.user)));
                                        }}
                                        >
                                            <span className="before-click">
                                                <span className="cart">
                                                    <img
                                                        className="cart-image"
                                                        src={process.env.PUBLIC_URL + "/images/cart-blue.png"}
                                                        alt="shopStick"
                                                    />
                                                </span>
                                                <span className="cart responsive-width">Add to Cart</span>
                                            </span>
                                        </p>
                                    )
                                )}
                                </span>
                            </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </Row>
                {
                    !props.product.productsLoading &&
                    props.product.productsInfo.current_page &&
                    props.product.productsInfo.current_page !== props.product.productsInfo.last_page && (
                    <Row>
                        <Col className="more-product">
                            <Button className="load-more" variant="primary" onClick={() => {
                                dispatch(
                                    productActions.getAllProducts(props.product.productsInfo.current_page + 1)
                                );
                            }}>
                                <span className="btn-text">Load More</span>
                            </Button>
                        </Col>
                    </Row> 
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ product, cart, favorite, user }) => {  return { product, cart: cart.cart, favorite, user }; };
export default connect(mapStateToProps)(RegularOffersAll);
