//required validator for form input
export const required = (value) => (value && value.toString().length > 0 ? undefined : "This field is required");

export const minLength = (min: number) => (value) => {
   return value && value.length < min ? `Must be ${min} characters or more` : undefined;
};

export const maxLength = (max: number) => (value) => {
   return value && value.length > max ? `Must be ${max} characters or less` : undefined;
};
export const email = (value) =>
   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Invalid email address" : undefined;
