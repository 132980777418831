import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import UserDataAccess from "dataAccess/userDataAccess";
import SpinnerLocal from "components/spinner";

const ProfileActivity = () => {
   const [user, setUser] = useState(undefined);

   useEffect(()=>{
      UserDataAccess.getUserDashboard()
      .then((data) => {
         setUser(data);
      })
      .catch((error) => {});
   },[])

   if (user === undefined) {
      return <SpinnerLocal />;
   }
   return (
      <div className="profile-activity-container">
         <Row>
            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
               <Row className="profile-activity-block">
                  <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                     <img src="/images/hartgreen.png" alt="shopstick" />
                  </Col>
                  <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                     <span style={{ fontWeight: "bold", fontSize: "20px" }}> {user.favourite_product_number} </span> <br />
                     <span style={{ color: "#ABADB4" }}> Favorites </span>
                  </Col>
               </Row>
               <br />
               <Row className="profile-activity-block">
                  <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                     <img src="/images/coupon.png" alt="shopstick" />
                  </Col>
                  <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                     <span style={{ fontWeight: "bold", fontSize: "20px" }}> { user.coupon } </span> <br />
                     <span style={{ color: "#ABADB4" }}> Available Coupones </span>
                  </Col>
               </Row>
               <br />
            </Col>
            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
               <Row className="profile-activity-block">
                  <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                     <img src="/images/star-yellow.png" alt="shopstick" />
                  </Col>
                  <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                     <span style={{ fontWeight: "bold", fontSize: "20px" }}> {user.review_number} </span> <br />
                     <span style={{ color: "#ABADB4" }}> Reviews </span>
                  </Col>
               </Row>
               <br />
               <Row className="profile-activity-block">
                  <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                     <img src="/images/car.png" alt="shopstick" />
                  </Col>
                  <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                     <span style={{ fontWeight: "bold", fontSize: "20px" }}> {user.order_number} </span> <br />
                     <span style={{ color: "#ABADB4" }}> Orders (Delivered) </span>
                  </Col>
               </Row>
               <br />
            </Col>
            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
               <Row className="profile-activity-block">
                  <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                     <img src="/images/giftb.png" alt="shopstick" />
                  </Col>
                  <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                     <span style={{ fontWeight: "bold", fontSize: "20px" }}> {user.consumed_product_number} </span> <br />
                     <span style={{ color: "#ABADB4" }}> Product(Consumed) </span>
                  </Col>
               </Row>
               <br />
               <Row className="profile-activity-block">
                  <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                     <img src="/images/dollar-sign.png" alt="shopstick" />
                  </Col>
                  <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                     <span style={{ fontWeight: "bold", fontSize: "20px" }}> {user.transaction} </span> <br />
                     <span style={{ color: "#ABADB4" }}> Money Transaction </span>
                  </Col>
               </Row>
               <br />
            </Col>
         </Row>
      </div>
   );
}
export default ProfileActivity;