import Axios, { AxiosError, AxiosResponse } from "axios";
import { linkSpecialOffer, linkRegularOffer } from "./apiEndpoints";

class OfferDA {

   getSpecialOfferProducts = () => {
    return Axios.get(linkSpecialOffer)
       .then((response: AxiosResponse) => {
          if (response.data.success) {
             return response.data.data;
          } else {
             throw new Error(response.data.message);
          }
       })
       .catch((error: AxiosError) => {
          throw new Error(error.message);
       });
    };

   getRegularOfferProducts = () => {
      return Axios.get(linkRegularOffer)
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

}
export default new OfferDA();
