import Axios, { AxiosError, AxiosResponse } from "axios";
import { getAuthHeader } from "services/userServices";
import { convertObjectToFormData } from "services/util";
import { linkAddFavorite, linkGetFavoriteList, linkRemoveFavorite, linkRemoveAllFavorites } from "./apiEndpoints";

class favoriteDA {
   getUserFavorites = () => {
      return Axios.get(linkGetFavoriteList, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
   addFavorite = (productId: string) => {
      const _data = convertObjectToFormData({ product_id: productId });
      return Axios.post(linkAddFavorite, _data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
      .then((response: AxiosResponse) => {
         if (response.data.success) {
            return response.data.data;
         } else {
            throw new Error(response.data.message);
         }
      })
      .catch((error: AxiosError) => {
         throw new Error(error.message);
      });
   };

   removeFavorite = (productId: string) => {
      const _data = convertObjectToFormData({ product_id: productId });
      return Axios.post(linkRemoveFavorite, _data, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
   removeAllProductFromFavorite = () => {
      return Axios.post(linkRemoveAllFavorites, { }, {
         headers: {
            Authorization: getAuthHeader(),
         },
      })
         .then((response: AxiosResponse) => {
            if (response.data.success) {
               return response.data.data;
            } else {
               throw new Error(response.data.message);
            }
         })
         .catch((error: AxiosError) => {
            throw new Error(error.message);
         });
   };
}
export default new favoriteDA();
