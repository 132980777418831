
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

const OurServices = (props: any) => {

 return (
   <div className="faq-container">
    <Helmet>
      <title> Shopstick | Our Services </title>
      <meta name="description" content="Shopstick Services"/>
    </Helmet>
    <Accordion defaultActiveKey="1">
      <div className="about-shopstick">
        <p className="title"> About Shopstick </p>
      </div>
      <div className="all-content">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0" >
          What we are?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p> Shopstick is a growing supermarket serving in the local area, Khulna, now. It's target is to grow the market share by satisfying
            it’s customers with the best quality product. Customer satisfaction is the main objective of shopstick now. Now we, Shopstick, are 
            trying our best to focus on arranging the a smoother delivery service in this pandemic situation. We, shopstick, are also trying to relieve our
            valuable customers from the pressure of doing the daily shopping outside. We are ensuring our customers the best quality product at the best price 
            sitting at home. 
           </p>
            <p>
              Shopstick is a supermarket that aims to create loyal customers for the growth of It's own in the long run. Our target is to be your daily
              companion who can at least make you free from the pressure of your daily bazar.
            </p>
            <p>
              Another aim of us is to overcome the barriers of farmers who cannot get the real price of their corns. We aim to give them thier desired price 
              and acquire the authentic product so that we can satisfy our customers with the best quality product. Moreover consumers want unadulterated product.
              And we can assure our consumers that they will get fresh and unadulterated product here InshaAllah.
            </p>
            <p>
              Finally it is our target to serve the whole country with best product. We want to contribute to the national growth by creating a good market share.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          Our services
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>Shopstick is a supermarket that aims to create loyal customers for the growth of It's own in the long run. Our target is to be your daily 
              companion who can at least make you free from the pressure of your daily bazar. 
            </p>
            <ul>
              <li> Easy Shopping </li>
              <li> Guarantee of Stock </li>
              <li> All Category Products </li>
              <li> Easy to Find </li>
              <li> Less Harassment </li>
              <li> Authentic Products </li>
              <li> Cash on Delivery </li>
              <li> Online Payment </li>
              <li> Easy Return Process </li>
              <li> Fast Refund </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          Terms of service
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p> This website is operated by a.season. Throughout the site, the terms “we”, “us” and “our” refer to a.season. a.season offers 
            this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance 
            of all terms, conditions, policies and notices stated here.
            </p>
            <p>
              By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and
              conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink.
              These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or 
              contributors of content.
            </p>
            <h5> Online Store Terms </h5>
            <p> By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or
            that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents 
            to use this site.
            </p> 
            <h5> General Conditions </h5>
            <p>
            We reserve the right to refuse service to anyone for any reason at any time.
            You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various 
            networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always 
            encrypted during transfer over networks.
            </p>

            <h5> License </h5>
            <p> You must not: </p>
            <ul>
              <li> 	Republish material from Website Name </li>
              <li> 	Sell, rent or sub-license material from Website Name </li>
              <li> 	Reproduce, duplicate or copy material from Website Name </li>
              <li> 	Redistribute content from Website Name </li>
            </ul>

            <h5> Disclaimer </h5>
            <p>
              To the maximum extent permitted by applicable law, we exclude all representations:
            </p>
            <ul>
              <li> 	limit or exclude our or your liability for death or personal injury; </li>
              <li> 	limit or exclude our or your liability for fraud or fraudulent misrepresentation; </li>
              <li> 	limit any of our or your liabilities in any way that is not permitted under applicable law; or </li>
              <li> 	exclude any of our or your liabilities that may not be excluded under applicable law. </li>
              <li> 	As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss 
                    or damage of any nature. 
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          Help & FAQ
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <h5> Q. How does the site work? </h5>
            <p> <span className="answer">A.</span> You can browse the site or use our search engine to find your desired products. You can then add them to your cart and click on
              check out. If you are a new customer you have to make an account so that You let us know your address, phone number, mail, payment
              method etc. Shopstick sales personnel will then deliver your order right to your desired place. 
            </p>
            <br/>

            <h5> Q. How can I contact you? </h5>
            <p> <span className="answer">A.</span> You can call +880-1923-211-211, +880-1923-188-188, +880-1923-199-199, from 8am-10pm or mail us at shopstick2020@gmail.com
            But you can order at our website 24/7
            </p>
            <br/>

            <h5> Q. How long do the deliveries take inside Khulna? </h5>
            <p> <span className="answer">A.</span> Basically we are serving as sort as possible time as it depends on our delivery pressure. We alawyas 
            try to deliver within 2 hours, which depends on product availability and our working hours. 
            </p>
            <br/>

            <h5> Q. How long do the deliveries take outside Khulna? </h5>
            <p> <span className="answer">A.</span> Outside Khulna we generally deliver in between 3-6 business days. </p>
            <br/>

            <h5> Q. How do I know when my order is here? </h5>
            <p> <span className="answer">A.</span> A shopstick sales personnel will call you as soon as they are ready to deliver the items at you destination, your house/office. </p>
            <br/>

            <h5> Q. How do I pay? </h5>
            <p> <span className="answer">A.</span> You can use online payment get way to pay the amount you purchased. We accept cash on delivery as well. But
            if you are from outside Khulna, you have to pay the delivery charge in advance. </p>
            <br/>

            <h5> Q. Do you serve my area? </h5>
            <p> <span className="answer">A.</span> Yes, we serve almost the whole area of Bangladesh. </p>
            <br/>

            <h5> Q. I can’t find the product I am looking for. What do I do? </h5>
            <p> <span className="answer">A.</span> You can call us and a customer manager will help you find your desired products. So, if there is any problem please 
            call us at +880 1923 211211, +880 1923 188188, +880 1923 199199 </p>
            <br/>

            <h5> Q. My order is wrong. What do I do? </h5>
            <p> <span className="answer">A.</span> Please Immediately call +880-1923-211 211 and let us know the problem. Again there are some products you can return within seven days. </p>
            <br/>

            <h5> Q. What if the item is out of stock? </h5>
            <p> <span className="answer">A.</span> We hold our own inventory in our warehouse, so we rarely run out of stock. However, we will try our best to source what your need. If we cannot find it, 
            we will SMS/call you and let you know what substitutes are available. </p>
            <br/>

            <h5> Q. Why should we buy from you when I have a store nearby? </h5>
            <p> <span className="answer">A.</span> It is a very good question. Basically we want to inform you the difference between us and the store near at your house.
              Actually we are an online super market where you can easily find your desired products with a scrolling of your gadget. Our
              prices are sometimes lower than that of major superstores in the city. Again we assure you the best quality products in our store.
              So, there is really many reasons to buy from us. </p>
              <br/>

            <h5> Q. What about the prices? </h5>
            <p> <span className="answer">A.</span> We are trying our best to offer you below the market prices. Our prices are the same as the local market and we are working hard to get them even lower! If you feel 
            that any product is priced unfairly, please let us know. </p>
            <br/>

            <h5> Q. How do you deliver? </h5>
            <p> <span className="answer">A.</span> We use our own logistics to deliver inside Khulna city. In the case of outside Khulna, we depends on third party like PATHAO, REDX etc. </p>
            <br/>

            <h5> Q. What is your policy on refunds? </h5>
            <p> <span className="answer">A.</span> There will be a seven days refund timeline on unopened products. For perishable products 
            like, milk, fruits, vegetables, fishes etc, you have to let us know as soon as possible. </p>
            <br/>

            <h5> Q. Can I get a refund via cash? </h5>
            <p> <span className="answer">A.</span> No, you will get refund on your bKash or bank account. </p>
            <br/>

            <h5> Q. What is your discounting policy? </h5>
            <p> <span className="answer">A.</span> Actually there are some products for which managing discount is very difficult. Although we are trying to give you up to 50% discount
            to some products. Again most of the items in our site are discounted for example if a product’s MRP is 150, we are selling it 135, there is
            already a 10% discount prevails. Moreover there are some items where you can use code and get discount which has been kept for customers on status basis.
            </p>
            <br/>

            <h5> Q. What about quality? </h5>
            <p> <span className="answer">A.</span> It is always our concern to satisfy you with the best quality products. But if you are dissatisfied, you can easily return it to us. </p>
            <br/>

            <h5> Q. How are you sourcing your products? </h5>
            <p> <span className="answer">A.</span> We sell only authentic and unadulterated products and so we source our products from the manufacturers, farmers, whole sellers and importers. </p>
            <br/>

            <h5> Q. Should I tip the delivery representative? </h5>
            <p> <span className="answer">A.</span> No need to give any tip to our delivery representative. </p>
            <br/>

          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
        Return & Refund
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <p> Shopstick accepts returns within 7 days of receipt of your order. If 7 days have gone by since receipt 
            of your order, unfortunately we can’t offer you a refund or exchange. </p>

            <h5> To be eligible for a return </h5>
            <ul>
              <li> 	If the product is damaged (proof must attach) </li>
              <li> 	If the item is incorrect </li>
              <li> 	If the size of the product is not appropriate </li>
              <li> 	If the color of your product is wrong </li>
              <li> 	If the quality of the product does not meet the expectation as it is advertised </li>
            </ul>

            <h5> Refunds (if applicable) </h5>
            <p> Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will
            also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will
            automatically be applied to your credit card or original method of payment, within a certain amount of days.
            </p>

            <h5> Late or missing refunds (if applicable) </h5>
            <ul>
              <li> 	If you haven’t received a refund yet, first check your bank account again. </li>
              <li> 	Then contact your credit card company, it may take some time before your refund is officially posted. </li>
              <li> 	Next contact your bank. There is often some processing time before a refund is posted. </li>
              <li> 	If you’ve done all of this and you still have not received your refund yet, please contact us. </li>
            </ul>

            <h5> Shipping </h5>
            <p> To return your product, you can request to return the product within 7 days of receipt of your order, and we will email you the return
            label which you can use to ship the product. Once your return is received and inspected, the refund will be processed to 
            your original method of payment. 
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      </div>
    </Accordion>
  </div>
 );
};
export default OurServices;
